/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_qk1j1_1954f_93:not(#\9) {
  /* used for test-utils */
}

.awsui_textarea_qk1j1_1954f_97:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  resize: auto; /* stylelint-disable-line plugin/no-unsupported-browser-features */
  cursor: text;
  white-space: pre-wrap;
  padding: var(--space-scaled-xxs-95dhkm, 4px) var(--space-field-horizontal-89h2yr, 12px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  max-width: 100%;
  width: 100%;
  display: block;
  box-sizing: border-box;
  background-color: var(--color-background-input-default-f4ls0o, #FFFFFF);
  border-radius: var(--border-radius-input-txq8zy, 8px);
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-input-default-e47bkv, #7d8998);
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
}
.awsui_textarea_qk1j1_1954f_97.awsui_textarea-readonly_qk1j1_1954f_123:not(#\9) {
  background-color: var(--color-background-input-default-f4ls0o, #FFFFFF);
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-input-disabled-x2lopj, #e9ebed);
}
.awsui_textarea_qk1j1_1954f_97:not(#\9)::placeholder {
  color: var(--color-text-input-placeholder-j53j3c, #5f6b7a);
  font-style: italic;
  opacity: 1;
}
.awsui_textarea_qk1j1_1954f_97:not(#\9):-ms-input-placeholder {
  color: var(--color-text-input-placeholder-j53j3c, #5f6b7a);
  font-style: italic;
}
.awsui_textarea_qk1j1_1954f_97:not(#\9):focus {
  outline: 2px dotted transparent;
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-item-focused-ap3b6s, #0972d3);
  border-radius: var(--border-radius-input-txq8zy, 8px);
  box-shadow: 0 0 0 var(--border-control-focus-ring-shadow-spread-srzyiu, 0px) var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_textarea_qk1j1_1954f_97:not(#\9):invalid {
  box-shadow: none;
}
.awsui_textarea_qk1j1_1954f_97:not(#\9):disabled {
  background-color: var(--color-background-input-disabled-wu9j6u, #e9ebed);
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-input-disabled-x2lopj, #e9ebed);
  color: var(--color-text-input-disabled-oawyzo, #9ba7b6);
  cursor: auto;
  cursor: default;
}
.awsui_textarea_qk1j1_1954f_97:not(#\9):disabled::placeholder {
  color: var(--color-text-input-placeholder-disabled-6928j8, #9ba7b6);
  opacity: 1;
}
.awsui_textarea_qk1j1_1954f_97:not(#\9):disabled:-ms-input-placeholder {
  color: var(--color-text-input-placeholder-disabled-6928j8, #9ba7b6);
}
.awsui_textarea_qk1j1_1954f_97.awsui_textarea-invalid_qk1j1_1954f_159:not(#\9) {
  color: var(--color-text-status-error-5sesl6, #d91515);
  border-color: var(--color-text-status-error-5sesl6, #d91515);
  padding-left: calc(
  var(--space-field-horizontal-89h2yr, 12px) - (var(--border-invalid-width-5jpdqn, 8px) - var(--border-field-width-idlekx, 2px))
);
  border-left-width: var(--border-invalid-width-5jpdqn, 8px);
}
.awsui_textarea_qk1j1_1954f_97.awsui_textarea-invalid_qk1j1_1954f_159:not(#\9):focus {
  box-shadow: 0 0 0 var(--border-control-invalid-focus-ring-shadow-spread-omt6il, 2px) var(--color-border-item-focused-ap3b6s, #0972d3);
}