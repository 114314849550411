/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_error-icon-shake-wrapper_14mhv_1ii6b_97:not(#\9) {
  animation: awsui_awsui-motion-shake-horizontally_14mhv_1ii6b_1 var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@keyframes awsui_awsui-motion-shake-horizontally_14mhv_1ii6b_1 {
  0% {
    transform: translateX(-5px);
    animation-timing-function: linear;
  }
  50% {
    transform: translateX(5px);
    animation-timing-function: var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
  }
  100% {
    transform: translateX(0px);
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_error-icon-shake-wrapper_14mhv_1ii6b_97:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_error-icon-shake-wrapper_14mhv_1ii6b_97:not(#\9), .awsui-mode-entering .awsui_error-icon-shake-wrapper_14mhv_1ii6b_97:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_error-icon-scale-wrapper_14mhv_1ii6b_124:not(#\9) {
  animation: awsui_awsui-motion-scale-popup_14mhv_1ii6b_1 var(--motion-duration-refresh-only-medium-5flen2, 165ms) var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
}
@keyframes awsui_awsui-motion-scale-popup_14mhv_1ii6b_1 {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_error-icon-scale-wrapper_14mhv_1ii6b_124:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_error-icon-scale-wrapper_14mhv_1ii6b_124:not(#\9), .awsui-mode-entering .awsui_error-icon-scale-wrapper_14mhv_1ii6b_124:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_error_14mhv_1ii6b_97:not(#\9) {
  animation: awsui_awsui-motion-fade-in-0_14mhv_1ii6b_1 var(--motion-duration-refresh-only-medium-5flen2, 165ms) var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
}
@keyframes awsui_awsui-motion-fade-in-0_14mhv_1ii6b_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_error_14mhv_1ii6b_97:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_error_14mhv_1ii6b_97:not(#\9), .awsui-mode-entering .awsui_error_14mhv_1ii6b_97:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_root_14mhv_1ii6b_168:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  box-sizing: border-box;
}

.awsui_label_14mhv_1ii6b_182:not(#\9) {
  box-sizing: border-box;
  color: var(--color-text-form-label-k3j0b4, #000716);
  display: inline;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  font-weight: var(--font-display-label-weight-m18hjh, 700);
  margin-right: var(--space-xs-rsr2qu, 8px);
}
.awsui_label_14mhv_1ii6b_182:not(#\9):only-child {
  margin: 0;
}

.awsui_info_14mhv_1ii6b_195:not(#\9) {
  padding-left: var(--space-xs-rsr2qu, 8px);
  border-left: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}

.awsui_description_14mhv_1ii6b_200:not(#\9),
.awsui_constraint_14mhv_1ii6b_201:not(#\9) {
  color: var(--color-text-form-secondary-ih9x7l, #5f6b7a);
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
}

.awsui_hints_14mhv_1ii6b_208:not(#\9),
.awsui_constraint-has-error_14mhv_1ii6b_209:not(#\9) {
  padding-top: var(--space-xxs-ynfts5, 4px);
}

.awsui_secondary-control_14mhv_1ii6b_213:not(#\9) {
  /* used in test-utils */
}

.awsui_controls_14mhv_1ii6b_217:not(#\9):not(.awsui_label-hidden_14mhv_1ii6b_217) {
  padding-top: var(--space-xxs-ynfts5, 4px);
}

.awsui_control_14mhv_1ii6b_217:not(#\9) {
  min-width: 0;
  -ms-word-break: break-all;
  word-break: break-word;
}

.awsui_error_14mhv_1ii6b_97:not(#\9) {
  color: var(--color-text-status-error-5sesl6, #d91515);
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
  display: flex;
  align-items: flex-start;
}

.awsui_error__message_14mhv_1ii6b_236:not(#\9) {
  margin-left: var(--space-xxs-ynfts5, 4px);
}

.awsui_visually-hidden_14mhv_1ii6b_240:not(#\9) {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}