/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_18wu0_1g1na_93:not(#\9) {
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

/* stylelint-disable no-invalid-position-at-import-rule */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant */
.awsui_box_18wu0_1g1na_207.awsui_p-variant_18wu0_1g1na_207.awsui_color-default_18wu0_1g1na_207:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_b-variant_18wu0_1g1na_207.awsui_color-default_18wu0_1g1na_207:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_strong-variant_18wu0_1g1na_207.awsui_color-default_18wu0_1g1na_207:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_code-variant_18wu0_1g1na_207.awsui_color-default_18wu0_1g1na_207:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_pre-variant_18wu0_1g1na_207.awsui_color-default_18wu0_1g1na_207:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_samp-variant_18wu0_1g1na_207.awsui_color-default_18wu0_1g1na_207:not(#\9) {
  color: var(--color-text-body-default-ajf1h5, #000716);
}

.awsui_box_18wu0_1g1na_207.awsui_h1-variant_18wu0_1g1na_211.awsui_color-default_18wu0_1g1na_207:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_h2-variant_18wu0_1g1na_211.awsui_color-default_18wu0_1g1na_207:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_h3-variant_18wu0_1g1na_211.awsui_color-default_18wu0_1g1na_207:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_h4-variant_18wu0_1g1na_211.awsui_color-default_18wu0_1g1na_207:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_h5-variant_18wu0_1g1na_211.awsui_color-default_18wu0_1g1na_207:not(#\9) {
  color: var(--color-text-heading-default-jopz0f, #000716);
}

.awsui_box_18wu0_1g1na_207.awsui_small-variant_18wu0_1g1na_215.awsui_color-default_18wu0_1g1na_207:not(#\9) {
  color: var(--color-text-small-le1y42, #5f6b7a);
}

.awsui_box_18wu0_1g1na_207.awsui_a-variant_18wu0_1g1na_219.awsui_color-default_18wu0_1g1na_207:not(#\9) {
  color: var(--color-text-link-default-dkndw9, #0972D3);
}

.awsui_box_18wu0_1g1na_207.awsui_small-variant_18wu0_1g1na_215.awsui_font-size-default_18wu0_1g1na_223:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_code-variant_18wu0_1g1na_207.awsui_font-size-default_18wu0_1g1na_223:not(#\9) {
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
}

.awsui_box_18wu0_1g1na_207.awsui_p-variant_18wu0_1g1na_207.awsui_font-size-default_18wu0_1g1na_223:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_b-variant_18wu0_1g1na_207.awsui_font-size-default_18wu0_1g1na_223:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_strong-variant_18wu0_1g1na_207.awsui_font-size-default_18wu0_1g1na_223:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_pre-variant_18wu0_1g1na_207.awsui_font-size-default_18wu0_1g1na_223:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_samp-variant_18wu0_1g1na_207.awsui_font-size-default_18wu0_1g1na_223:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_a-variant_18wu0_1g1na_219.awsui_font-size-default_18wu0_1g1na_223:not(#\9) {
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
}

.awsui_box_18wu0_1g1na_207.awsui_h5-variant_18wu0_1g1na_211.awsui_font-size-default_18wu0_1g1na_223:not(#\9) {
  font-size: var(--font-heading-xs-size-fryxro, 14px);
  line-height: var(--font-heading-xs-line-height-5x131j, 18px);
}

.awsui_box_18wu0_1g1na_207.awsui_h4-variant_18wu0_1g1na_211.awsui_font-size-default_18wu0_1g1na_223:not(#\9) {
  font-size: var(--font-heading-s-size-9clg8y, 16px);
  line-height: var(--font-heading-s-line-height-5b7f0q, 20px);
  letter-spacing: var(--font-heading-s-letter-spacing-4d69jc, -0.005em);
}

.awsui_box_18wu0_1g1na_207.awsui_h3-variant_18wu0_1g1na_211.awsui_font-size-default_18wu0_1g1na_223:not(#\9) {
  font-size: var(--font-heading-m-size-6e3jmg, 20px);
  line-height: var(--font-heading-m-line-height-u3t54f, 24px);
  letter-spacing: var(--font-heading-m-letter-spacing-r00pg3, -0.01em);
}

.awsui_box_18wu0_1g1na_207.awsui_h2-variant_18wu0_1g1na_211.awsui_font-size-default_18wu0_1g1na_223:not(#\9) {
  font-size: var(--font-heading-l-size-otmxys, 24px);
  line-height: var(--font-heading-l-line-height-gev4n1, 30px);
  letter-spacing: var(--font-heading-l-letter-spacing-f6wvtk, -0.015em);
}

.awsui_box_18wu0_1g1na_207.awsui_h1-variant_18wu0_1g1na_211.awsui_font-size-default_18wu0_1g1na_223:not(#\9) {
  font-size: var(--font-heading-xl-size-d0vjbk, 32px);
  line-height: var(--font-heading-xl-line-height-doxfdj, 40px);
  letter-spacing: var(--font-heading-xl-letter-spacing-9tcsmy, -0.02em);
}

.awsui_box_18wu0_1g1na_207.awsui_p-variant_18wu0_1g1na_207.awsui_font-weight-default_18wu0_1g1na_263:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_code-variant_18wu0_1g1na_207.awsui_font-weight-default_18wu0_1g1na_263:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_pre-variant_18wu0_1g1na_207.awsui_font-weight-default_18wu0_1g1na_263:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_samp-variant_18wu0_1g1na_207.awsui_font-weight-default_18wu0_1g1na_263:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_small-variant_18wu0_1g1na_215.awsui_font-weight-default_18wu0_1g1na_263:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_a-variant_18wu0_1g1na_219.awsui_font-weight-default_18wu0_1g1na_263:not(#\9) {
  font-weight: 400;
}

.awsui_box_18wu0_1g1na_207.awsui_strong-variant_18wu0_1g1na_207.awsui_font-weight-default_18wu0_1g1na_263:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_b-variant_18wu0_1g1na_207.awsui_font-weight-default_18wu0_1g1na_263:not(#\9) {
  font-weight: 700;
}

.awsui_box_18wu0_1g1na_207.awsui_h5-variant_18wu0_1g1na_211.awsui_font-weight-default_18wu0_1g1na_263:not(#\9) {
  font-weight: var(--font-heading-xs-weight-wwznty, 800);
}

.awsui_box_18wu0_1g1na_207.awsui_h4-variant_18wu0_1g1na_211.awsui_font-weight-default_18wu0_1g1na_263:not(#\9) {
  font-weight: var(--font-heading-s-weight-k8ys41, 800);
}

.awsui_box_18wu0_1g1na_207.awsui_h3-variant_18wu0_1g1na_211.awsui_font-weight-default_18wu0_1g1na_263:not(#\9) {
  font-weight: var(--font-heading-m-weight-hi5n0j, 800);
}

.awsui_box_18wu0_1g1na_207.awsui_h2-variant_18wu0_1g1na_211.awsui_font-weight-default_18wu0_1g1na_263:not(#\9) {
  font-weight: var(--font-heading-l-weight-xm7ks9, 800);
}

.awsui_box_18wu0_1g1na_207.awsui_h1-variant_18wu0_1g1na_211.awsui_font-weight-default_18wu0_1g1na_263:not(#\9) {
  font-weight: var(--font-heading-xl-weight-772870, 800);
}

.awsui_box_18wu0_1g1na_207.awsui_h1-variant_18wu0_1g1na_211:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_h2-variant_18wu0_1g1na_211:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_h3-variant_18wu0_1g1na_211:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_h4-variant_18wu0_1g1na_211:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_h5-variant_18wu0_1g1na_211:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_p-variant_18wu0_1g1na_207:not(#\9) {
  margin: 0;
  text-decoration: none;
  padding: var(--space-xxs-ynfts5, 4px) 0;
}
.awsui_box_18wu0_1g1na_207.awsui_small-variant_18wu0_1g1na_215:not(#\9) {
  display: inline-block;
}
.awsui_box_18wu0_1g1na_207.awsui_code-variant_18wu0_1g1na_207:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_pre-variant_18wu0_1g1na_207:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_samp-variant_18wu0_1g1na_207:not(#\9) {
  font-family: var(--font-family-monospace-iz223z, Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace);
  background: transparent;
}
.awsui_box_18wu0_1g1na_207.awsui_key-label-variant_18wu0_1g1na_303:not(#\9) {
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  font-weight: var(--font-display-label-weight-m18hjh, 700);
  color: var(--color-text-label-gxskdy, #000716);
  margin-bottom: var(--space-scaled-xxxs-b6dm8t, 2px);
}
.awsui_box_18wu0_1g1na_207.awsui_value-large-variant_18wu0_1g1na_310:not(#\9) {
  font-size: var(--font-display-l-size-03lgcb, 48px);
  line-height: var(--font-display-l-line-height-71tnj3, 54px);
  letter-spacing: var(--font-display-l-letter-spacing-4ff418, -0.03em);
  font-weight: var(--font-box-value-large-weight-dyt1pj, 800);
  color: inherit;
}
.awsui_box_18wu0_1g1na_207.awsui_h1-variant_18wu0_1g1na_211.awsui_font-weight-default_18wu0_1g1na_263:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_h2-variant_18wu0_1g1na_211.awsui_font-weight-default_18wu0_1g1na_263:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_h3-variant_18wu0_1g1na_211.awsui_font-weight-default_18wu0_1g1na_263:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_h4-variant_18wu0_1g1na_211.awsui_font-weight-default_18wu0_1g1na_263:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_h5-variant_18wu0_1g1na_211.awsui_font-weight-default_18wu0_1g1na_263:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_value-large-variant_18wu0_1g1na_310.awsui_font-weight-default_18wu0_1g1na_263:not(#\9), .awsui_box_18wu0_1g1na_207.awsui_font-weight-heavy_18wu0_1g1na_317:not(#\9) {
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
}

.awsui_box_18wu0_1g1na_207.awsui_color-inverted_18wu0_1g1na_322:not(#\9) {
  color: var(--color-text-notification-default-2kpcxy, #fbfbfb);
}
.awsui_box_18wu0_1g1na_207.awsui_color-text-label_18wu0_1g1na_325:not(#\9) {
  color: var(--color-text-label-gxskdy, #000716);
}
.awsui_box_18wu0_1g1na_207.awsui_color-text-body-secondary_18wu0_1g1na_328:not(#\9) {
  color: var(--color-text-body-secondary-fdstdf, #414d5c);
}
.awsui_box_18wu0_1g1na_207.awsui_color-text-status-error_18wu0_1g1na_331:not(#\9) {
  color: var(--color-text-status-error-5sesl6, #d91515);
}
.awsui_box_18wu0_1g1na_207.awsui_color-text-status-success_18wu0_1g1na_334:not(#\9) {
  color: var(--color-text-status-success-0fzhv9, #037f0c);
}
.awsui_box_18wu0_1g1na_207.awsui_color-text-status-info_18wu0_1g1na_337:not(#\9) {
  color: var(--color-text-status-info-utupcz, #0972d3);
}
.awsui_box_18wu0_1g1na_207.awsui_color-text-status-inactive_18wu0_1g1na_340:not(#\9) {
  color: var(--color-text-status-inactive-5ei55p, #5f6b7a);
}
.awsui_box_18wu0_1g1na_207.awsui_color-inherit_18wu0_1g1na_343:not(#\9) {
  color: inherit;
}
.awsui_box_18wu0_1g1na_207.awsui_font-size-body-s_18wu0_1g1na_346:not(#\9) {
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
}
.awsui_box_18wu0_1g1na_207.awsui_font-size-body-m_18wu0_1g1na_351:not(#\9) {
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
}
.awsui_box_18wu0_1g1na_207.awsui_font-size-heading-xs_18wu0_1g1na_355:not(#\9) {
  font-size: var(--font-heading-xs-size-fryxro, 14px);
  line-height: var(--font-heading-xs-line-height-5x131j, 18px);
}
.awsui_box_18wu0_1g1na_207.awsui_font-size-heading-s_18wu0_1g1na_359:not(#\9) {
  font-size: var(--font-heading-s-size-9clg8y, 16px);
  line-height: var(--font-heading-s-line-height-5b7f0q, 20px);
  letter-spacing: var(--font-heading-s-letter-spacing-4d69jc, -0.005em);
}
.awsui_box_18wu0_1g1na_207.awsui_font-size-heading-m_18wu0_1g1na_364:not(#\9) {
  font-size: var(--font-heading-m-size-6e3jmg, 20px);
  line-height: var(--font-heading-m-line-height-u3t54f, 24px);
  letter-spacing: var(--font-heading-m-letter-spacing-r00pg3, -0.01em);
}
.awsui_box_18wu0_1g1na_207.awsui_font-size-heading-l_18wu0_1g1na_369:not(#\9) {
  font-size: var(--font-heading-l-size-otmxys, 24px);
  line-height: var(--font-heading-l-line-height-gev4n1, 30px);
  letter-spacing: var(--font-heading-l-letter-spacing-f6wvtk, -0.015em);
}
.awsui_box_18wu0_1g1na_207.awsui_font-size-heading-xl_18wu0_1g1na_374:not(#\9) {
  font-size: var(--font-heading-xl-size-d0vjbk, 32px);
  line-height: var(--font-heading-xl-line-height-doxfdj, 40px);
  letter-spacing: var(--font-heading-xl-letter-spacing-9tcsmy, -0.02em);
}
.awsui_box_18wu0_1g1na_207.awsui_font-size-display-l_18wu0_1g1na_379:not(#\9) {
  font-size: var(--font-display-l-size-03lgcb, 48px);
  line-height: var(--font-display-l-line-height-71tnj3, 54px);
  letter-spacing: var(--font-display-l-letter-spacing-4ff418, -0.03em);
}
.awsui_box_18wu0_1g1na_207.awsui_font-weight-light_18wu0_1g1na_384:not(#\9) {
  font-weight: 300;
}
.awsui_box_18wu0_1g1na_207.awsui_font-weight-normal_18wu0_1g1na_387:not(#\9) {
  font-weight: 400;
}
.awsui_box_18wu0_1g1na_207.awsui_font-weight-bold_18wu0_1g1na_390:not(#\9) {
  font-weight: 700;
}
.awsui_box_18wu0_1g1na_207.awsui_font-weight-heavy_18wu0_1g1na_317:not(#\9) {
  font-weight: var(--font-weight-heavy-ydka9r, 800);
}

.awsui_t-left_18wu0_1g1na_397:not(#\9) {
  text-align: left;
}

.awsui_t-right_18wu0_1g1na_401:not(#\9) {
  text-align: right;
}

.awsui_t-center_18wu0_1g1na_405:not(#\9) {
  text-align: center;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_box_18wu0_1g1na_207.awsui_p-n_18wu0_1g1na_506:not(#\9) {
  padding: var(--space-scaled-none-cnv7sd, 0) var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_1g1na_207.awsui_p-top-n_18wu0_1g1na_510:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-n_18wu0_1g1na_511:not(#\9) {
  padding-top: var(--space-scaled-none-cnv7sd, 0);
}

.awsui_box_18wu0_1g1na_207.awsui_p-right-n_18wu0_1g1na_515:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-n_18wu0_1g1na_516:not(#\9) {
  padding-right: var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_1g1na_207.awsui_p-bottom-n_18wu0_1g1na_520:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-n_18wu0_1g1na_511:not(#\9) {
  padding-bottom: var(--space-scaled-none-cnv7sd, 0);
}

.awsui_box_18wu0_1g1na_207.awsui_p-left-n_18wu0_1g1na_525:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-n_18wu0_1g1na_516:not(#\9) {
  padding-left: var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_1g1na_207.awsui_p-xxxs_18wu0_1g1na_530:not(#\9) {
  padding: var(--space-scaled-xxxs-b6dm8t, 2px) var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-top-xxxs_18wu0_1g1na_534:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-xxxs_18wu0_1g1na_535:not(#\9) {
  padding-top: var(--space-scaled-xxxs-b6dm8t, 2px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-right-xxxs_18wu0_1g1na_539:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-xxxs_18wu0_1g1na_540:not(#\9) {
  padding-right: var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-bottom-xxxs_18wu0_1g1na_544:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-xxxs_18wu0_1g1na_535:not(#\9) {
  padding-bottom: var(--space-scaled-xxxs-b6dm8t, 2px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-left-xxxs_18wu0_1g1na_549:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-xxxs_18wu0_1g1na_540:not(#\9) {
  padding-left: var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-xxs_18wu0_1g1na_554:not(#\9) {
  padding: var(--space-scaled-xxs-95dhkm, 4px) var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-top-xxs_18wu0_1g1na_558:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-xxs_18wu0_1g1na_559:not(#\9) {
  padding-top: var(--space-scaled-xxs-95dhkm, 4px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-right-xxs_18wu0_1g1na_563:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-xxs_18wu0_1g1na_564:not(#\9) {
  padding-right: var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-bottom-xxs_18wu0_1g1na_568:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-xxs_18wu0_1g1na_559:not(#\9) {
  padding-bottom: var(--space-scaled-xxs-95dhkm, 4px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-left-xxs_18wu0_1g1na_573:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-xxs_18wu0_1g1na_564:not(#\9) {
  padding-left: var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-xs_18wu0_1g1na_578:not(#\9) {
  padding: var(--space-scaled-xs-6859qs, 8px) var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-top-xs_18wu0_1g1na_582:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-xs_18wu0_1g1na_583:not(#\9) {
  padding-top: var(--space-scaled-xs-6859qs, 8px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-right-xs_18wu0_1g1na_587:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-xs_18wu0_1g1na_588:not(#\9) {
  padding-right: var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-bottom-xs_18wu0_1g1na_592:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-xs_18wu0_1g1na_583:not(#\9) {
  padding-bottom: var(--space-scaled-xs-6859qs, 8px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-left-xs_18wu0_1g1na_597:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-xs_18wu0_1g1na_588:not(#\9) {
  padding-left: var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-s_18wu0_1g1na_602:not(#\9) {
  padding: var(--space-scaled-s-cu1hzn, 12px) var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-top-s_18wu0_1g1na_606:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-s_18wu0_1g1na_607:not(#\9) {
  padding-top: var(--space-scaled-s-cu1hzn, 12px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-right-s_18wu0_1g1na_611:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-s_18wu0_1g1na_612:not(#\9) {
  padding-right: var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-bottom-s_18wu0_1g1na_616:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-s_18wu0_1g1na_607:not(#\9) {
  padding-bottom: var(--space-scaled-s-cu1hzn, 12px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-left-s_18wu0_1g1na_621:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-s_18wu0_1g1na_612:not(#\9) {
  padding-left: var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-m_18wu0_1g1na_626:not(#\9) {
  padding: var(--space-scaled-m-pv0fmt, 16px) var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-top-m_18wu0_1g1na_630:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-m_18wu0_1g1na_631:not(#\9) {
  padding-top: var(--space-scaled-m-pv0fmt, 16px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-right-m_18wu0_1g1na_635:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-m_18wu0_1g1na_636:not(#\9) {
  padding-right: var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-bottom-m_18wu0_1g1na_640:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-m_18wu0_1g1na_631:not(#\9) {
  padding-bottom: var(--space-scaled-m-pv0fmt, 16px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-left-m_18wu0_1g1na_645:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-m_18wu0_1g1na_636:not(#\9) {
  padding-left: var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-l_18wu0_1g1na_525:not(#\9) {
  padding: var(--space-scaled-l-t03y3z, 20px) var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-top-l_18wu0_1g1na_654:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-l_18wu0_1g1na_655:not(#\9) {
  padding-top: var(--space-scaled-l-t03y3z, 20px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-right-l_18wu0_1g1na_659:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-l_18wu0_1g1na_660:not(#\9) {
  padding-right: var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-bottom-l_18wu0_1g1na_664:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-l_18wu0_1g1na_655:not(#\9) {
  padding-bottom: var(--space-scaled-l-t03y3z, 20px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-left-l_18wu0_1g1na_669:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-l_18wu0_1g1na_660:not(#\9) {
  padding-left: var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-xl_18wu0_1g1na_674:not(#\9) {
  padding: var(--space-scaled-xl-w2t504, 24px) var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-top-xl_18wu0_1g1na_678:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-xl_18wu0_1g1na_679:not(#\9) {
  padding-top: var(--space-scaled-xl-w2t504, 24px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-right-xl_18wu0_1g1na_683:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-xl_18wu0_1g1na_684:not(#\9) {
  padding-right: var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-bottom-xl_18wu0_1g1na_688:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-xl_18wu0_1g1na_679:not(#\9) {
  padding-bottom: var(--space-scaled-xl-w2t504, 24px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-left-xl_18wu0_1g1na_693:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-xl_18wu0_1g1na_684:not(#\9) {
  padding-left: var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-xxl_18wu0_1g1na_698:not(#\9) {
  padding: var(--space-scaled-xxl-pfgv96, 32px) var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-top-xxl_18wu0_1g1na_702:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-xxl_18wu0_1g1na_703:not(#\9) {
  padding-top: var(--space-scaled-xxl-pfgv96, 32px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-right-xxl_18wu0_1g1na_707:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-xxl_18wu0_1g1na_708:not(#\9) {
  padding-right: var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-bottom-xxl_18wu0_1g1na_712:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-xxl_18wu0_1g1na_703:not(#\9) {
  padding-bottom: var(--space-scaled-xxl-pfgv96, 32px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-left-xxl_18wu0_1g1na_717:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-xxl_18wu0_1g1na_708:not(#\9) {
  padding-left: var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-xxxl_18wu0_1g1na_722:not(#\9) {
  padding: var(--space-scaled-xxxl-ffokcj, 40px) var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-top-xxxl_18wu0_1g1na_726:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-xxxl_18wu0_1g1na_727:not(#\9) {
  padding-top: var(--space-scaled-xxxl-ffokcj, 40px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-right-xxxl_18wu0_1g1na_731:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-xxxl_18wu0_1g1na_732:not(#\9) {
  padding-right: var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-bottom-xxxl_18wu0_1g1na_736:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-vertical-xxxl_18wu0_1g1na_727:not(#\9) {
  padding-bottom: var(--space-scaled-xxxl-ffokcj, 40px);
}

.awsui_box_18wu0_1g1na_207.awsui_p-left-xxxl_18wu0_1g1na_741:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_p-horizontal-xxxl_18wu0_1g1na_732:not(#\9) {
  padding-left: var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-n_18wu0_1g1na_746:not(#\9) {
  margin: var(--space-scaled-none-cnv7sd, 0) var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_1g1na_207.awsui_m-top-n_18wu0_1g1na_750:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-n_18wu0_1g1na_751:not(#\9) {
  margin-top: var(--space-scaled-none-cnv7sd, 0);
}

.awsui_box_18wu0_1g1na_207.awsui_m-right-n_18wu0_1g1na_755:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-n_18wu0_1g1na_756:not(#\9) {
  margin-right: var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_1g1na_207.awsui_m-bottom-n_18wu0_1g1na_760:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-n_18wu0_1g1na_751:not(#\9) {
  margin-bottom: var(--space-scaled-none-cnv7sd, 0);
}

.awsui_box_18wu0_1g1na_207.awsui_m-left-n_18wu0_1g1na_765:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-n_18wu0_1g1na_756:not(#\9) {
  margin-left: var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_1g1na_207.awsui_m-xxxs_18wu0_1g1na_770:not(#\9) {
  margin: var(--space-scaled-xxxs-b6dm8t, 2px) var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-top-xxxs_18wu0_1g1na_774:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-xxxs_18wu0_1g1na_775:not(#\9) {
  margin-top: var(--space-scaled-xxxs-b6dm8t, 2px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-right-xxxs_18wu0_1g1na_779:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-xxxs_18wu0_1g1na_780:not(#\9) {
  margin-right: var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-bottom-xxxs_18wu0_1g1na_784:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-xxxs_18wu0_1g1na_775:not(#\9) {
  margin-bottom: var(--space-scaled-xxxs-b6dm8t, 2px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-left-xxxs_18wu0_1g1na_789:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-xxxs_18wu0_1g1na_780:not(#\9) {
  margin-left: var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-xxs_18wu0_1g1na_794:not(#\9) {
  margin: var(--space-scaled-xxs-95dhkm, 4px) var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-top-xxs_18wu0_1g1na_798:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-xxs_18wu0_1g1na_799:not(#\9) {
  margin-top: var(--space-scaled-xxs-95dhkm, 4px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-right-xxs_18wu0_1g1na_803:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-xxs_18wu0_1g1na_804:not(#\9) {
  margin-right: var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-bottom-xxs_18wu0_1g1na_808:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-xxs_18wu0_1g1na_799:not(#\9) {
  margin-bottom: var(--space-scaled-xxs-95dhkm, 4px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-left-xxs_18wu0_1g1na_813:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-xxs_18wu0_1g1na_804:not(#\9) {
  margin-left: var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-xs_18wu0_1g1na_818:not(#\9) {
  margin: var(--space-scaled-xs-6859qs, 8px) var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-top-xs_18wu0_1g1na_822:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-xs_18wu0_1g1na_823:not(#\9) {
  margin-top: var(--space-scaled-xs-6859qs, 8px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-right-xs_18wu0_1g1na_827:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-xs_18wu0_1g1na_828:not(#\9) {
  margin-right: var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-bottom-xs_18wu0_1g1na_832:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-xs_18wu0_1g1na_823:not(#\9) {
  margin-bottom: var(--space-scaled-xs-6859qs, 8px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-left-xs_18wu0_1g1na_837:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-xs_18wu0_1g1na_828:not(#\9) {
  margin-left: var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-s_18wu0_1g1na_842:not(#\9) {
  margin: var(--space-scaled-s-cu1hzn, 12px) var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-top-s_18wu0_1g1na_846:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-s_18wu0_1g1na_847:not(#\9) {
  margin-top: var(--space-scaled-s-cu1hzn, 12px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-right-s_18wu0_1g1na_851:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-s_18wu0_1g1na_852:not(#\9) {
  margin-right: var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-bottom-s_18wu0_1g1na_856:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-s_18wu0_1g1na_847:not(#\9) {
  margin-bottom: var(--space-scaled-s-cu1hzn, 12px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-left-s_18wu0_1g1na_861:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-s_18wu0_1g1na_852:not(#\9) {
  margin-left: var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-m_18wu0_1g1na_866:not(#\9) {
  margin: var(--space-scaled-m-pv0fmt, 16px) var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-top-m_18wu0_1g1na_870:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-m_18wu0_1g1na_871:not(#\9) {
  margin-top: var(--space-scaled-m-pv0fmt, 16px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-right-m_18wu0_1g1na_875:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-m_18wu0_1g1na_876:not(#\9) {
  margin-right: var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-bottom-m_18wu0_1g1na_880:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-m_18wu0_1g1na_871:not(#\9) {
  margin-bottom: var(--space-scaled-m-pv0fmt, 16px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-left-m_18wu0_1g1na_885:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-m_18wu0_1g1na_876:not(#\9) {
  margin-left: var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-l_18wu0_1g1na_765:not(#\9) {
  margin: var(--space-scaled-l-t03y3z, 20px) var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-top-l_18wu0_1g1na_894:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-l_18wu0_1g1na_895:not(#\9) {
  margin-top: var(--space-scaled-l-t03y3z, 20px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-right-l_18wu0_1g1na_899:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-l_18wu0_1g1na_900:not(#\9) {
  margin-right: var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-bottom-l_18wu0_1g1na_904:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-l_18wu0_1g1na_895:not(#\9) {
  margin-bottom: var(--space-scaled-l-t03y3z, 20px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-left-l_18wu0_1g1na_909:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-l_18wu0_1g1na_900:not(#\9) {
  margin-left: var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-xl_18wu0_1g1na_914:not(#\9) {
  margin: var(--space-scaled-xl-w2t504, 24px) var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-top-xl_18wu0_1g1na_918:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-xl_18wu0_1g1na_919:not(#\9) {
  margin-top: var(--space-scaled-xl-w2t504, 24px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-right-xl_18wu0_1g1na_923:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-xl_18wu0_1g1na_924:not(#\9) {
  margin-right: var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-bottom-xl_18wu0_1g1na_928:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-xl_18wu0_1g1na_919:not(#\9) {
  margin-bottom: var(--space-scaled-xl-w2t504, 24px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-left-xl_18wu0_1g1na_933:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-xl_18wu0_1g1na_924:not(#\9) {
  margin-left: var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-xxl_18wu0_1g1na_938:not(#\9) {
  margin: var(--space-scaled-xxl-pfgv96, 32px) var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-top-xxl_18wu0_1g1na_942:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-xxl_18wu0_1g1na_943:not(#\9) {
  margin-top: var(--space-scaled-xxl-pfgv96, 32px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-right-xxl_18wu0_1g1na_947:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-xxl_18wu0_1g1na_948:not(#\9) {
  margin-right: var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-bottom-xxl_18wu0_1g1na_952:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-xxl_18wu0_1g1na_943:not(#\9) {
  margin-bottom: var(--space-scaled-xxl-pfgv96, 32px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-left-xxl_18wu0_1g1na_957:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-xxl_18wu0_1g1na_948:not(#\9) {
  margin-left: var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-xxxl_18wu0_1g1na_962:not(#\9) {
  margin: var(--space-scaled-xxxl-ffokcj, 40px) var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-top-xxxl_18wu0_1g1na_966:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-xxxl_18wu0_1g1na_967:not(#\9) {
  margin-top: var(--space-scaled-xxxl-ffokcj, 40px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-right-xxxl_18wu0_1g1na_971:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-xxxl_18wu0_1g1na_972:not(#\9) {
  margin-right: var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-bottom-xxxl_18wu0_1g1na_976:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-vertical-xxxl_18wu0_1g1na_967:not(#\9) {
  margin-bottom: var(--space-scaled-xxxl-ffokcj, 40px);
}

.awsui_box_18wu0_1g1na_207.awsui_m-left-xxxl_18wu0_1g1na_981:not(#\9),
.awsui_box_18wu0_1g1na_207.awsui_m-horizontal-xxxl_18wu0_1g1na_972:not(#\9) {
  margin-left: var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_1g1na_207.awsui_d-block_18wu0_1g1na_986:not(#\9) {
  display: block;
}
.awsui_box_18wu0_1g1na_207.awsui_d-inline_18wu0_1g1na_989:not(#\9) {
  display: inline;
}
.awsui_box_18wu0_1g1na_207.awsui_d-inline-block_18wu0_1g1na_992:not(#\9) {
  display: inline-block;
}
.awsui_box_18wu0_1g1na_207.awsui_d-none_18wu0_1g1na_995:not(#\9) {
  display: none;
}

.awsui_f-left_18wu0_1g1na_999:not(#\9) {
  float: left;
}

.awsui_f-right_18wu0_1g1na_1003:not(#\9) {
  float: right;
}

/* stylelint-enable no-invalid-position-at-import-rule */