/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_card-inner_p8a6i_hmm1z_97:not(#\9) {
  transition-property: background-color;
  transition-duration: var(--motion-duration-transition-show-paced-ai4wlf, 180ms);
  transition-timing-function: var(--motion-easing-transition-show-paced-bg1hyg, ease-out);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_card-inner_p8a6i_hmm1z_97:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_card-inner_p8a6i_hmm1z_97:not(#\9), .awsui-mode-entering .awsui_card-inner_p8a6i_hmm1z_97:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_card-inner_p8a6i_hmm1z_97:not(#\9)::before {
  transition-property: border-top-color, border-right-color, border-bottom-color, border-left-color;
  transition-duration: var(--motion-duration-transition-show-paced-ai4wlf, 180ms);
  transition-timing-function: var(--motion-easing-transition-show-paced-bg1hyg, ease-out);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_card-inner_p8a6i_hmm1z_97:not(#\9)::before {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_card-inner_p8a6i_hmm1z_97:not(#\9)::before, .awsui-mode-entering .awsui_card-inner_p8a6i_hmm1z_97:not(#\9)::before {
  animation: none;
  transition: none;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_p8a6i_hmm1z_133:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.awsui_header-variant-full-page_p8a6i_hmm1z_153.awsui_header-refresh_p8a6i_hmm1z_153:not(#\9) {
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  box-sizing: border-box;
  padding-top: var(--space-container-header-vertical-zsjhel, 12px);
  padding-left: 0;
  padding-right: 0;
  padding-bottom: calc(var(--space-container-header-vertical-zsjhel, 12px) + var(--space-scaled-xxs-95dhkm, 4px));
}
.awsui_header-variant-full-page_p8a6i_hmm1z_153.awsui_header-refresh_p8a6i_hmm1z_153:not(#\9)::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  border-color: transparent;
  border-top: var(--border-container-top-width-jk6fhm, 0px) solid var(--color-border-container-top-srcvx4, transparent);
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  z-index: 1;
}
.awsui_header-variant-full-page_p8a6i_hmm1z_153.awsui_header-refresh_p8a6i_hmm1z_153:not(#\9)::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  box-shadow: var(--shadow-container-fx563k, 0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12));
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  mix-blend-mode: multiply;
}
@media screen and (-ms-high-contrast: active) {
  .awsui_header-variant-full-page_p8a6i_hmm1z_153.awsui_header-refresh_p8a6i_hmm1z_153:not(#\9) {
    border: var(--border-field-width-idlekx, 2px) solid var(--color-border-container-top-srcvx4, transparent);
  }
}

.awsui_list_p8a6i_hmm1z_196:not(#\9) {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0;
  list-style: none;
  margin: 0 0 0 calc(var(--space-grid-gutter-0subky, 20px) * -1);
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-1_p8a6i_hmm1z_204 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 100%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-2_p8a6i_hmm1z_207 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 50%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-3_p8a6i_hmm1z_210 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 33.3333333333%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-4_p8a6i_hmm1z_213 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 25%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-5_p8a6i_hmm1z_216 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 20%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-6_p8a6i_hmm1z_219 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 16.6666666667%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-7_p8a6i_hmm1z_222 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 14.2857142857%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-8_p8a6i_hmm1z_225 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 12.5%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-9_p8a6i_hmm1z_228 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 11.1111111111%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-10_p8a6i_hmm1z_231 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 10%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-11_p8a6i_hmm1z_234 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 9.0909090909%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-12_p8a6i_hmm1z_237 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 8.3333333333%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-13_p8a6i_hmm1z_240 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 7.6923076923%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-14_p8a6i_hmm1z_243 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 7.1428571429%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-15_p8a6i_hmm1z_246 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 6.6666666667%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-16_p8a6i_hmm1z_249 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 6.25%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-17_p8a6i_hmm1z_252 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 5.8823529412%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-18_p8a6i_hmm1z_255 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 5.5555555556%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-19_p8a6i_hmm1z_258 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 5.2631578947%;
}
.awsui_list_p8a6i_hmm1z_196.awsui_list-grid-20_p8a6i_hmm1z_261 > .awsui_card_p8a6i_hmm1z_97:not(#\9) {
  width: 5%;
}

.awsui_selection-control_p8a6i_hmm1z_265:not(#\9) {
  position: absolute;
  box-sizing: border-box;
  width: calc(var(--size-control-n9i4yo, 16px) + (2 * var(--space-card-horizontal-di7llb, 20px)));
  top: 0;
  right: 0;
  padding: var(--space-scaled-l-t03y3z, 20px) var(--space-card-horizontal-di7llb, 20px);
}

.awsui_loading_p8a6i_hmm1z_274:not(#\9),
.awsui_empty_p8a6i_hmm1z_275:not(#\9) {
  overflow: hidden;
  text-align: center;
  color: var(--color-text-empty-fjv325, #5f6b7a);
  margin-bottom: var(--space-scaled-l-t03y3z, 20px);
}

.awsui_has-header_p8a6i_hmm1z_282:not(#\9) {
  margin-top: var(--space-grid-gutter-0subky, 20px);
}

.awsui_card_p8a6i_hmm1z_97:not(#\9) {
  display: flex;
  overflow-wrap: break-word;
  word-wrap: break-word;
  margin: 0;
  padding: 0;
  list-style: none;
}
.awsui_card-inner_p8a6i_hmm1z_97:not(#\9) {
  position: relative;
  background-color: var(--color-background-container-content-iw8cer, #FFFFFF);
  margin: 0 0 var(--space-grid-gutter-0subky, 20px) var(--space-grid-gutter-0subky, 20px);
  padding: var(--space-scaled-l-t03y3z, 20px) var(--space-card-horizontal-di7llb, 20px) var(--space-scaled-l-t03y3z, 20px);
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
}
@media screen and (-ms-high-contrast: active) {
  .awsui_card-inner_p8a6i_hmm1z_97:not(#\9) {
    padding: calc(var(--space-scaled-l-t03y3z, 20px) - var(--border-item-width-qbbbsa, 2px)) calc(var(--space-card-horizontal-di7llb, 20px) - var(--border-item-width-qbbbsa, 2px)) calc(var(--space-scaled-l-t03y3z, 20px) - var(--border-item-width-qbbbsa, 2px));
  }
}
.awsui_card-inner_p8a6i_hmm1z_97:not(#\9)::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  border-color: transparent;
  border-top: var(--border-container-top-width-jk6fhm, 0px) solid var(--color-border-container-top-srcvx4, transparent);
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  z-index: 1;
}
.awsui_card-inner_p8a6i_hmm1z_97:not(#\9)::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  box-shadow: var(--shadow-container-fx563k, 0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12));
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  mix-blend-mode: multiply;
}
@media screen and (-ms-high-contrast: active) {
  .awsui_card-inner_p8a6i_hmm1z_97:not(#\9) {
    border: var(--border-field-width-idlekx, 2px) solid var(--color-border-container-top-srcvx4, transparent);
  }
}
.awsui_card-header_p8a6i_hmm1z_343:not(#\9) {
  font-size: var(--font-heading-m-size-6e3jmg, 20px);
  line-height: var(--font-heading-m-line-height-u3t54f, 24px);
  letter-spacing: var(--font-heading-m-letter-spacing-r00pg3, -0.01em);
  font-weight: var(--font-heading-m-weight-hi5n0j, 800);
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
}
.awsui_card-header-inner_p8a6i_hmm1z_351:not(#\9) {
  width: 100%;
  display: inline-block;
}
.awsui_card-selectable_p8a6i_hmm1z_355 > .awsui_card-inner_p8a6i_hmm1z_97 > .awsui_card-header_p8a6i_hmm1z_343:not(#\9) {
  width: 90%;
}
.awsui_card-selected_p8a6i_hmm1z_358 > .awsui_card-inner_p8a6i_hmm1z_97:not(#\9) {
  background-color: var(--color-background-item-selected-ebt4bi, #f2f8fd);
}
.awsui_card-selected_p8a6i_hmm1z_358 > .awsui_card-inner_p8a6i_hmm1z_97:not(#\9)::before {
  border: var(--border-item-width-qbbbsa, 2px) solid var(--color-border-item-selected-ppkssz, #0972d3);
}

.awsui_section_p8a6i_hmm1z_365:not(#\9) {
  display: inline-block;
  box-sizing: border-box;
  padding: var(--space-xs-rsr2qu, 8px) 0 var(--space-scaled-xs-6859qs, 8px) 0;
  vertical-align: top;
}
.awsui_section-header_p8a6i_hmm1z_371:not(#\9) {
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  font-weight: var(--font-display-label-weight-m18hjh, 700);
  color: var(--color-text-label-gxskdy, #000716);
}
.awsui_section-content_p8a6i_hmm1z_377:not(#\9) {
  /* used in test-utils */
}

.awsui_section_p8a6i_hmm1z_365:not(#\9):last-child {
  padding: var(--space-xs-rsr2qu, 8px) 0 0 0;
}