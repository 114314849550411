/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_2qdw9_zmuag_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  cursor: inherit;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.awsui_root-no-actions_2qdw9_zmuag_110.awsui_root-variant-h1_2qdw9_zmuag_110:not(#\9) {
  padding-bottom: 0;
}
.awsui_root-no-actions_2qdw9_zmuag_110.awsui_root-variant-h1-refresh_2qdw9_zmuag_113:not(#\9) {
  padding-bottom: var(--space-xxxs-k2w98v, 2px);
}
.awsui_root-no-actions_2qdw9_zmuag_110.awsui_root-variant-h2_2qdw9_zmuag_116:not(#\9), .awsui_root-has-description_2qdw9_zmuag_116.awsui_root-variant-h2_2qdw9_zmuag_116:not(#\9) {
  padding-bottom: var(--space-scaled-xxs-95dhkm, 4px);
}
.awsui_root-no-actions_2qdw9_zmuag_110.awsui_root-variant-h2-refresh_2qdw9_zmuag_119:not(#\9), .awsui_root-has-description_2qdw9_zmuag_116.awsui_root-variant-h2-refresh_2qdw9_zmuag_119:not(#\9) {
  padding-bottom: var(--space-xxxs-k2w98v, 2px);
}
.awsui_root-no-actions_2qdw9_zmuag_110.awsui_root-variant-h3_2qdw9_zmuag_122:not(#\9), .awsui_root-has-description_2qdw9_zmuag_116.awsui_root-variant-h3_2qdw9_zmuag_122:not(#\9) {
  padding-bottom: var(--space-scaled-xxs-95dhkm, 4px);
}
.awsui_root-no-actions_2qdw9_zmuag_110.awsui_root-variant-h3-refresh_2qdw9_zmuag_125:not(#\9), .awsui_root-has-description_2qdw9_zmuag_116.awsui_root-variant-h3-refresh_2qdw9_zmuag_125:not(#\9) {
  padding-bottom: 0;
}
.awsui_root-no-wrap_2qdw9_zmuag_128:not(#\9) {
  flex-wrap: nowrap;
}

.awsui_main_2qdw9_zmuag_132:not(#\9) {
  word-wrap: break-word;
  max-width: 100%;
  overflow: hidden;
}
:not(#\9):not(.awsui_root-no-actions_2qdw9_zmuag_110) > .awsui_main_2qdw9_zmuag_132 {
  margin-right: var(--space-xs-rsr2qu, 8px);
}
.awsui_main-variant-h1_2qdw9_zmuag_140:not(#\9) {
  padding: var(--space-scaled-2x-xxs-t5qzmf, 4px) 0;
}
.awsui_main-variant-h1-refresh_2qdw9_zmuag_143:not(#\9) {
  padding: var(--space-xxs-ynfts5, 4px) 0;
}
:not(#\9):not(.awsui_root-no-actions_2qdw9_zmuag_110) > .awsui_main-variant-h1-refresh_2qdw9_zmuag_143 {
  padding-top: var(--space-scaled-xxxs-b6dm8t, 2px);
}
.awsui_main-variant-h2_2qdw9_zmuag_149:not(#\9), .awsui_main-variant-h3_2qdw9_zmuag_149:not(#\9) {
  padding-top: var(--space-scaled-xxs-95dhkm, 4px);
}
:not(#\9):not(.awsui_root-has-description_2qdw9_zmuag_116) > .awsui_main-variant-h2_2qdw9_zmuag_149, :not(#\9):not(.awsui_root-has-description_2qdw9_zmuag_116) > .awsui_main-variant-h3_2qdw9_zmuag_149 {
  padding-bottom: var(--space-xxxs-k2w98v, 2px);
}
.awsui_main-variant-h2-refresh_2qdw9_zmuag_155:not(#\9) {
  padding-top: 0;
}
:not(#\9):not(.awsui_root-has-description_2qdw9_zmuag_116) > .awsui_main-variant-h2-refresh_2qdw9_zmuag_155 {
  padding-bottom: 0;
}

.awsui_actions_2qdw9_zmuag_162:not(#\9) {
  display: flex;
  align-items: flex-start;
}
.awsui_actions-variant-h1_2qdw9_zmuag_166:not(#\9) {
  padding: var(--space-scaled-xs-6859qs, 8px) 0;
}
.awsui_actions-variant-h1-refresh_2qdw9_zmuag_169:not(#\9) {
  padding: var(--space-xs-rsr2qu, 8px) 0;
}
.awsui_actions-variant-h2_2qdw9_zmuag_172:not(#\9), .awsui_actions-variant-h3_2qdw9_zmuag_172:not(#\9) {
  padding: var(--space-scaled-xxs-95dhkm, 4px) 0;
}
.awsui_root-has-description_2qdw9_zmuag_116 > .awsui_actions-variant-h2_2qdw9_zmuag_172:not(#\9), .awsui_root-has-description_2qdw9_zmuag_116 > .awsui_actions-variant-h3_2qdw9_zmuag_172:not(#\9) {
  padding-bottom: 0;
}
.awsui_actions-variant-h2-refresh_2qdw9_zmuag_178:not(#\9) {
  padding-bottom: var(--space-xxxs-k2w98v, 2px);
}
.awsui_actions-variant-h3-refresh_2qdw9_zmuag_181:not(#\9) {
  padding-bottom: 0;
}

.awsui_title_2qdw9_zmuag_185:not(#\9) {
  min-width: 0;
  -ms-word-break: break-all;
  word-break: break-word;
  color: var(--color-text-heading-default-jopz0f, #000716);
}
.awsui_title-variant-h1_2qdw9_zmuag_191:not(#\9) {
  font-size: var(--font-heading-xl-size-d0vjbk, 32px);
}
.awsui_title-variant-h2_2qdw9_zmuag_194:not(#\9), .awsui_title-variant-h3_2qdw9_zmuag_194:not(#\9) {
  padding: var(--space-scaled-xxs-95dhkm, 4px) 0;
}
.awsui_title-variant-h2_2qdw9_zmuag_194:not(#\9) {
  font-size: var(--font-heading-l-size-otmxys, 24px);
}
.awsui_title-variant-h2-refresh_2qdw9_zmuag_200:not(#\9) {
  padding-top: var(--space-scaled-2x-xxs-t5qzmf, 4px);
}
.awsui_title-variant-h3_2qdw9_zmuag_194:not(#\9) {
  font-size: var(--font-heading-m-size-6e3jmg, 20px);
}

.awsui_info_2qdw9_zmuag_207:not(#\9) {
  padding-right: var(--space-s-hv8c1d, 12px);
}

.awsui_description_2qdw9_zmuag_211:not(#\9) {
  margin: 0;
  padding: 0;
  color: var(--color-text-heading-secondary-corkaj, #414d5c);
}
.awsui_description-variant-h1_2qdw9_zmuag_216:not(#\9) {
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  padding-top: var(--space-scaled-xxs-95dhkm, 4px);
}
.awsui_description-variant-h1-refresh_2qdw9_zmuag_221:not(#\9) {
  padding-top: var(--space-scaled-xs-6859qs, 8px);
}
.awsui_description-variant-h2_2qdw9_zmuag_224:not(#\9) {
  font-size: var(--font-header-h2-description-size-pqrrui, 14px);
  line-height: var(--font-header-h2-description-line-height-gjrb86, 22px);
  padding-bottom: var(--space-scaled-xxs-95dhkm, 4px);
}
.awsui_description-variant-h3_2qdw9_zmuag_229:not(#\9) {
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
  padding-bottom: var(--space-scaled-xxs-95dhkm, 4px);
}
.awsui_description-variant-h3-refresh_2qdw9_zmuag_235:not(#\9) {
  padding-top: var(--space-xxxs-k2w98v, 2px);
}

.awsui_heading_2qdw9_zmuag_239:not(#\9) {
  margin: 0;
  display: inline;
  font-size: inherit;
  margin-right: var(--space-xs-rsr2qu, 8px);
}
.awsui_heading_2qdw9_zmuag_239:not(#\9):only-child {
  margin: 0;
}
.awsui_heading-variant-h1_2qdw9_zmuag_248:not(#\9) {
  font-size: var(--font-heading-xl-size-d0vjbk, 32px);
  line-height: var(--font-heading-xl-line-height-doxfdj, 40px);
  letter-spacing: var(--font-heading-xl-letter-spacing-9tcsmy, -0.02em);
}
.awsui_heading-variant-h2_2qdw9_zmuag_253:not(#\9) {
  font-size: var(--font-heading-l-size-otmxys, 24px);
  line-height: var(--font-heading-l-line-height-gev4n1, 30px);
  letter-spacing: var(--font-heading-l-letter-spacing-f6wvtk, -0.015em);
}
.awsui_heading-variant-h3_2qdw9_zmuag_258:not(#\9) {
  font-size: var(--font-heading-m-size-6e3jmg, 20px);
  line-height: var(--font-heading-m-line-height-u3t54f, 24px);
  letter-spacing: var(--font-heading-m-letter-spacing-r00pg3, -0.01em);
}

.awsui_heading-text_2qdw9_zmuag_264:not(#\9) {
  /* used in test-utils */
}
.awsui_heading-text-variant-h1_2qdw9_zmuag_267:not(#\9) {
  font-size: var(--font-heading-xl-size-d0vjbk, 32px);
  line-height: var(--font-heading-xl-line-height-doxfdj, 40px);
  letter-spacing: var(--font-heading-xl-letter-spacing-9tcsmy, -0.02em);
  font-weight: var(--font-heading-xl-weight-772870, 800);
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
}
.awsui_heading-text-variant-h2_2qdw9_zmuag_275:not(#\9) {
  font-size: var(--font-heading-l-size-otmxys, 24px);
  line-height: var(--font-heading-l-line-height-gev4n1, 30px);
  letter-spacing: var(--font-heading-l-letter-spacing-f6wvtk, -0.015em);
  font-weight: var(--font-heading-l-weight-xm7ks9, 800);
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
}
.awsui_heading-text-variant-h3_2qdw9_zmuag_283:not(#\9) {
  font-size: var(--font-heading-m-size-6e3jmg, 20px);
  line-height: var(--font-heading-m-line-height-u3t54f, 24px);
  letter-spacing: var(--font-heading-m-letter-spacing-r00pg3, -0.01em);
  font-weight: var(--font-heading-m-weight-hi5n0j, 800);
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
}

.awsui_counter_2qdw9_zmuag_292:not(#\9) {
  color: var(--color-text-counter-jwdegc, #5f6b7a);
  font-weight: 400;
}