/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_wih1l_8pv4k_97:not(#\9) {
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  width: 100%;
}

.awsui_tools_wih1l_8pv4k_108:not(#\9) {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: var(--space-scaled-xs-6859qs, 8px) 0 var(--space-scaled-xxs-95dhkm, 4px);
}
.awsui_tools-filtering_wih1l_8pv4k_114:not(#\9) {
  max-width: 100%;
  margin-right: var(--space-l-4vl6xu, 20px);
}
@supports (flex-basis: fit-content) {
  .awsui_tools-filtering_wih1l_8pv4k_114:not(#\9) {
    flex: 1 1 fit-content;
  }
}
@supports not (flex-basis: fit-content) {
  .awsui_tools-filtering_wih1l_8pv4k_114:not(#\9) {
    flex: 1 1 auto;
  }
}
.awsui_tools-align-right_wih1l_8pv4k_128:not(#\9) {
  display: flex;
  margin-left: auto;
}
.awsui_tools-pagination_wih1l_8pv4k_132 + .awsui_tools-preferences_wih1l_8pv4k_132:not(#\9) {
  border-left: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
  box-sizing: border-box;
  margin-left: var(--space-xs-rsr2qu, 8px);
  padding-left: var(--space-xs-rsr2qu, 8px);
}
.awsui_tools-small_wih1l_8pv4k_138 > .awsui_tools-filtering_wih1l_8pv4k_114:not(#\9) {
  margin-right: 0;
  margin-bottom: var(--space-scaled-xs-6859qs, 8px);
  flex-basis: 100%;
}

.awsui_table_wih1l_8pv4k_144:not(#\9) {
  width: 100%;
  border-spacing: 0;
  position: relative;
  box-sizing: border-box;
}
.awsui_table-layout-fixed_wih1l_8pv4k_150:not(#\9) {
  table-layout: fixed;
}

.awsui_wrapper_wih1l_8pv4k_154:not(#\9) {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding-top: var(--space-table-content-top-t15dd1, 12px);
  padding-bottom: var(--space-table-content-bottom-pg6yvn, 4px);
  overflow-x: auto;
}
.awsui_wrapper_wih1l_8pv4k_154.awsui_variant-stacked_wih1l_8pv4k_162 > .awsui_table_wih1l_8pv4k_144:not(#\9), .awsui_wrapper_wih1l_8pv4k_154.awsui_variant-container_wih1l_8pv4k_162 > .awsui_table_wih1l_8pv4k_144:not(#\9) {
  padding-left: var(--space-table-horizontal-mj7h0h, 20px);
  padding-right: var(--space-table-horizontal-mj7h0h, 20px);
}
.awsui_wrapper_wih1l_8pv4k_154.awsui_variant-embedded_wih1l_8pv4k_166:not(#\9) {
  padding-bottom: var(--space-table-embedded-content-bottom-7vc5vt, 0px);
}
.awsui_wrapper_wih1l_8pv4k_154.awsui_has-header_wih1l_8pv4k_169:not(#\9) {
  padding-top: 0px;
}
.awsui_wrapper_wih1l_8pv4k_154.awsui_has-footer_wih1l_8pv4k_172:not(#\9) {
  padding-bottom: 0px;
}
.awsui_wrapper_wih1l_8pv4k_154:not(#\9):not(.awsui_has-header_wih1l_8pv4k_169) {
  border-top-right-radius: var(--border-radius-container-gh9ysk, 16px);
  border-top-left-radius: var(--border-radius-container-gh9ysk, 16px);
}
.awsui_wrapper_wih1l_8pv4k_154[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: 2px;
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}

.awsui_cell-merged_wih1l_8pv4k_186:not(#\9) {
  text-align: center;
  padding: 0;
}
.awsui_cell-merged_wih1l_8pv4k_186.awsui_has-footer_wih1l_8pv4k_172:not(#\9) {
  /*
  Add a bottom border to the body cell of an empty table as a separator between the 
  table and the footer
  */
  border-bottom: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}
.awsui_cell-merged-content_wih1l_8pv4k_197:not(#\9) {
  box-sizing: border-box;
  width: 100%;
  padding: var(--space-scaled-m-pv0fmt, 16px) var(--space-l-4vl6xu, 20px) var(--space-scaled-l-t03y3z, 20px);
  /* stylelint-disable plugin/no-unsupported-browser-features */
  /* stylelint-enable plugin/no-unsupported-browser-features */
}
@supports (position: sticky) {
  .awsui_cell-merged-content_wih1l_8pv4k_197:not(#\9) {
    position: sticky;
    left: 0;
    margin: 0 calc(-2 * var(--space-table-horizontal-mj7h0h, 20px));
  }
}

.awsui_empty_wih1l_8pv4k_212:not(#\9) {
  color: var(--color-text-empty-fjv325, #5f6b7a);
}

.awsui_loading_wih1l_8pv4k_216:not(#\9) {
  /* used in test-utils */
}

/*
The min/max/width token values in Visual Refresh should align 
the table header and body cells selection control with the table
filter search icon.
*/
.awsui_selection-control_wih1l_8pv4k_225:not(#\9) {
  box-sizing: border-box;
  max-width: var(--size-table-selection-horizontal-o8c8gs, 40px);
  min-width: var(--size-table-selection-horizontal-o8c8gs, 40px);
  position: relative;
  width: var(--size-table-selection-horizontal-o8c8gs, 40px);
}
.awsui_selection-control_wih1l_8pv4k_225.awsui_selection-control-header_wih1l_8pv4k_232:not(#\9) {
  padding: var(--space-scaled-xs-6859qs, 8px) var(--space-scaled-l-t03y3z, 20px);
  border-left: var(--border-item-width-qbbbsa, 2px) solid transparent;
}

.awsui_sticky-scrollbar_wih1l_8pv4k_237:not(#\9) {
  height: 15px;
  position: fixed;
  display: none;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: -15px;
  bottom: 0;
  width: 100%;
}
.awsui_sticky-scrollbar-content_wih1l_8pv4k_247:not(#\9) {
  height: 15px;
}
.awsui_sticky-scrollbar-visible_wih1l_8pv4k_250:not(#\9) {
  display: block;
}

/*
The dynamic height dark header needs a background that will cover
the default white background of the container component.
*/
.awsui_dark-header_wih1l_8pv4k_258:not(#\9) {
  background-color: var(--color-background-layout-main-y57tka, #F8F9FA);
  color: var(--color-text-body-default-ajf1h5, #000716);
}

.awsui_header-secondary_wih1l_8pv4k_263:not(#\9) {
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  box-sizing: border-box;
  width: 100%;
  border-radius: 0;
  background: var(--color-background-table-header-i1pa1g, #ffffff);
}
.awsui_header-secondary_wih1l_8pv4k_263.awsui_variant-stacked_wih1l_8pv4k_162 > .awsui_table_wih1l_8pv4k_144:not(#\9), .awsui_header-secondary_wih1l_8pv4k_263.awsui_variant-container_wih1l_8pv4k_162 > .awsui_table_wih1l_8pv4k_144:not(#\9) {
  padding-left: var(--space-table-horizontal-mj7h0h, 20px);
  padding-right: var(--space-table-horizontal-mj7h0h, 20px);
}
.awsui_header-secondary_wih1l_8pv4k_263:not(#\9)::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.awsui_header-secondary_wih1l_8pv4k_263.awsui_table-has-header_wih1l_8pv4k_279:not(#\9) {
  border-top: var(--border-divider-list-width-hacikr, 1px) solid var(--color-border-container-divider-4ade7z, transparent);
}

.awsui_header-controls_wih1l_8pv4k_283:not(#\9) {
  padding-top: var(--space-container-header-vertical-zsjhel, 12px);
  padding-bottom: var(--space-container-header-vertical-zsjhel, 12px);
}
.awsui_header-controls_wih1l_8pv4k_283.awsui_variant-stacked_wih1l_8pv4k_162:not(#\9), .awsui_header-controls_wih1l_8pv4k_283.awsui_variant-container_wih1l_8pv4k_162:not(#\9) {
  padding-left: calc(var(--space-table-horizontal-mj7h0h, 20px) + var(--space-table-header-horizontal-et2ko8, 0px));
  padding-right: calc(var(--space-table-horizontal-mj7h0h, 20px) + var(--space-table-header-horizontal-et2ko8, 0px));
}
.awsui_header-controls_wih1l_8pv4k_283.awsui_variant-embedded_wih1l_8pv4k_166:not(#\9) {
  padding-left: var(--space-table-header-horizontal-et2ko8, 0px);
  padding-right: var(--space-table-header-horizontal-et2ko8, 0px);
  padding-top: var(--space-table-embedded-header-top-zf07fk, 0px);
}

.awsui_footer-wrapper_wih1l_8pv4k_297.awsui_variant-stacked_wih1l_8pv4k_162:not(#\9), .awsui_footer-wrapper_wih1l_8pv4k_297.awsui_variant-container_wih1l_8pv4k_162:not(#\9) {
  padding-left: var(--space-table-horizontal-mj7h0h, 20px);
  padding-right: var(--space-table-horizontal-mj7h0h, 20px);
}

.awsui_footer_wih1l_8pv4k_297:not(#\9) {
  padding: var(--space-scaled-s-cu1hzn, 12px) var(--space-table-footer-horizontal-8o6sk8, 0px);
}

.awsui_thead-active_wih1l_8pv4k_306:not(#\9),
.awsui_row_wih1l_8pv4k_307:not(#\9),
.awsui_row-selected_wih1l_8pv4k_308:not(#\9) {
  /* used in test-utils */
}