.sign-in {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-top: -400px;
  background: url('../public/logo.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}