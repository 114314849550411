/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_grid_14yj0_1u9p5_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--space-grid-gutter-0subky, 20px) / -2);
  pointer-events: none;
}
.awsui_grid_14yj0_1u9p5_93.awsui_no-gutters_14yj0_1u9p5_109:not(#\9) {
  margin: 0;
}

.awsui_grid-column_14yj0_1u9p5_113:not(#\9) {
  box-sizing: border-box;
  position: relative;
  padding: calc(var(--space-grid-gutter-0subky, 20px) / 2);
  display: flex;
}
.awsui_grid_14yj0_1u9p5_93.awsui_no-gutters_14yj0_1u9p5_109 > .awsui_grid-column_14yj0_1u9p5_113:not(#\9) {
  padding: 0;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_colspan-1_14yj0_1u9p5_122:not(#\9) {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_push-1_14yj0_1u9p5_126:not(#\9) {
  left: 8.3333333333%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_pull-1_14yj0_1u9p5_129:not(#\9) {
  right: 8.3333333333%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_colspan-2_14yj0_1u9p5_132:not(#\9) {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_push-2_14yj0_1u9p5_136:not(#\9) {
  left: 16.6666666667%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_pull-2_14yj0_1u9p5_139:not(#\9) {
  right: 16.6666666667%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_colspan-3_14yj0_1u9p5_142:not(#\9) {
  flex: 0 0 25%;
  max-width: 25%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_push-3_14yj0_1u9p5_146:not(#\9) {
  left: 25%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_pull-3_14yj0_1u9p5_149:not(#\9) {
  right: 25%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_colspan-4_14yj0_1u9p5_152:not(#\9) {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_push-4_14yj0_1u9p5_156:not(#\9) {
  left: 33.3333333333%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_pull-4_14yj0_1u9p5_159:not(#\9) {
  right: 33.3333333333%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_colspan-5_14yj0_1u9p5_162:not(#\9) {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_push-5_14yj0_1u9p5_166:not(#\9) {
  left: 41.6666666667%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_pull-5_14yj0_1u9p5_169:not(#\9) {
  right: 41.6666666667%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_colspan-6_14yj0_1u9p5_172:not(#\9) {
  flex: 0 0 50%;
  max-width: 50%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_push-6_14yj0_1u9p5_176:not(#\9) {
  left: 50%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_pull-6_14yj0_1u9p5_179:not(#\9) {
  right: 50%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_colspan-7_14yj0_1u9p5_182:not(#\9) {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_push-7_14yj0_1u9p5_186:not(#\9) {
  left: 58.3333333333%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_pull-7_14yj0_1u9p5_189:not(#\9) {
  right: 58.3333333333%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_colspan-8_14yj0_1u9p5_192:not(#\9) {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_push-8_14yj0_1u9p5_196:not(#\9) {
  left: 66.6666666667%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_pull-8_14yj0_1u9p5_199:not(#\9) {
  right: 66.6666666667%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_colspan-9_14yj0_1u9p5_202:not(#\9) {
  flex: 0 0 75%;
  max-width: 75%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_push-9_14yj0_1u9p5_206:not(#\9) {
  left: 75%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_pull-9_14yj0_1u9p5_209:not(#\9) {
  right: 75%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_colspan-10_14yj0_1u9p5_212:not(#\9) {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_push-10_14yj0_1u9p5_216:not(#\9) {
  left: 83.3333333333%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_pull-10_14yj0_1u9p5_219:not(#\9) {
  right: 83.3333333333%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_colspan-11_14yj0_1u9p5_222:not(#\9) {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_push-11_14yj0_1u9p5_226:not(#\9) {
  left: 91.6666666667%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_pull-11_14yj0_1u9p5_229:not(#\9) {
  right: 91.6666666667%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_colspan-12_14yj0_1u9p5_232:not(#\9) {
  flex: 0 0 100%;
  max-width: 100%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_push-12_14yj0_1u9p5_236:not(#\9) {
  left: 100%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_pull-12_14yj0_1u9p5_239:not(#\9) {
  right: 100%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_push-0_14yj0_1u9p5_242:not(#\9) {
  left: auto;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_pull-0_14yj0_1u9p5_245:not(#\9) {
  right: auto;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_offset-1_14yj0_1u9p5_248:not(#\9) {
  margin-left: 8.3333333333%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_offset-2_14yj0_1u9p5_251:not(#\9) {
  margin-left: 16.6666666667%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_offset-3_14yj0_1u9p5_254:not(#\9) {
  margin-left: 25%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_offset-4_14yj0_1u9p5_257:not(#\9) {
  margin-left: 33.3333333333%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_offset-5_14yj0_1u9p5_260:not(#\9) {
  margin-left: 41.6666666667%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_offset-6_14yj0_1u9p5_263:not(#\9) {
  margin-left: 50%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_offset-7_14yj0_1u9p5_266:not(#\9) {
  margin-left: 58.3333333333%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_offset-8_14yj0_1u9p5_269:not(#\9) {
  margin-left: 66.6666666667%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_offset-9_14yj0_1u9p5_272:not(#\9) {
  margin-left: 75%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_offset-10_14yj0_1u9p5_275:not(#\9) {
  margin-left: 83.3333333333%;
}
.awsui_grid-column_14yj0_1u9p5_113.awsui_offset-11_14yj0_1u9p5_278:not(#\9) {
  margin-left: 91.6666666667%;
}

.awsui_restore-pointer-events_14yj0_1u9p5_282:not(#\9) {
  pointer-events: auto;
  width: 100%;
}