/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_arrow_xjuzf_10ufd_185:not(#\9) {
  width: 20px;
  height: 10px;
}
.awsui_arrow-outer_xjuzf_10ufd_189:not(#\9), .awsui_arrow-inner_xjuzf_10ufd_189:not(#\9) {
  position: absolute;
  overflow: hidden;
  width: 20px;
  height: 10px;
  top: 0;
  left: 0;
}
.awsui_arrow-outer_xjuzf_10ufd_189:not(#\9)::after, .awsui_arrow-inner_xjuzf_10ufd_189:not(#\9)::after {
  content: "";
  box-sizing: border-box;
  display: inline-block;
  position: absolute;
  border-radius: 2px 0 0 0;
  bottom: 0;
  left: 0;
  width: 14px;
  height: 14px;
  transform: rotate(45deg);
  transform-origin: 0 100%;
}
.awsui_arrow-outer_xjuzf_10ufd_189:not(#\9)::after {
  background-color: var(--color-border-popover-5pygk7, #9ba7b6);
}
.awsui_arrow-inner_xjuzf_10ufd_189:not(#\9) {
  top: 2px;
}
.awsui_arrow-inner_xjuzf_10ufd_189:not(#\9)::after {
  border-radius: 1px 0 0 0;
  background-color: var(--color-background-popover-mn5o9k, #ffffff);
}
.awsui_arrow-position-right-top_xjuzf_10ufd_220 > .awsui_arrow-outer_xjuzf_10ufd_189:not(#\9)::after, .awsui_arrow-position-right-bottom_xjuzf_10ufd_220 > .awsui_arrow-outer_xjuzf_10ufd_189:not(#\9)::after {
  box-shadow: -0.71px 0.71px 4px -2px var(--color-shadow-default-3se70f, rgba(0, 7, 22, 0.12));
}
.awsui_arrow-position-left-top_xjuzf_10ufd_223 > .awsui_arrow-outer_xjuzf_10ufd_189:not(#\9)::after, .awsui_arrow-position-left-bottom_xjuzf_10ufd_223 > .awsui_arrow-outer_xjuzf_10ufd_189:not(#\9)::after {
  box-shadow: 0.71px -0.71px 4px -2px var(--color-shadow-default-3se70f, rgba(0, 7, 22, 0.12));
}
.awsui_arrow-position-top-center_xjuzf_10ufd_226 > .awsui_arrow-outer_xjuzf_10ufd_189:not(#\9)::after, .awsui_arrow-position-top-right_xjuzf_10ufd_226 > .awsui_arrow-outer_xjuzf_10ufd_189:not(#\9)::after, .awsui_arrow-position-top-left_xjuzf_10ufd_226 > .awsui_arrow-outer_xjuzf_10ufd_189:not(#\9)::after, .awsui_arrow-position-top-responsive_xjuzf_10ufd_226 > .awsui_arrow-outer_xjuzf_10ufd_189:not(#\9)::after {
  box-shadow: -0.71px -0.71px 4px -2px var(--color-shadow-default-3se70f, rgba(0, 7, 22, 0.12));
}
.awsui_arrow-position-bottom-center_xjuzf_10ufd_229 > .awsui_arrow-outer_xjuzf_10ufd_189:not(#\9)::after, .awsui_arrow-position-bottom-right_xjuzf_10ufd_229 > .awsui_arrow-outer_xjuzf_10ufd_189:not(#\9)::after, .awsui_arrow-position-bottom-left_xjuzf_10ufd_229 > .awsui_arrow-outer_xjuzf_10ufd_189:not(#\9)::after, .awsui_arrow-position-bottom-responsive_xjuzf_10ufd_229 > .awsui_arrow-outer_xjuzf_10ufd_189:not(#\9)::after {
  box-shadow: 0.71px 0.71px 4px -2px var(--color-shadow-default-3se70f, rgba(0, 7, 22, 0.12));
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_body_xjuzf_10ufd_325:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  word-wrap: break-word;
  max-width: 100%;
  overflow: hidden;
  padding: var(--space-s-hv8c1d, 12px) var(--space-m-17eucw, 16px);
}
.awsui_body-overflow-visible_xjuzf_10ufd_341:not(#\9) {
  overflow: visible;
}

.awsui_has-dismiss_xjuzf_10ufd_345:not(#\9) {
  display: flex;
  align-items: baseline;
}

.awsui_dismiss_xjuzf_10ufd_350:not(#\9) {
  margin: calc(-1 * (var(--space-scaled-xxs-95dhkm, 4px) + var(--border-field-width-idlekx, 2px))) calc(-1 * (var(--space-xxs-ynfts5, 4px) + var(--border-field-width-idlekx, 2px)));
  margin-left: 0;
  flex: 0 0 auto;
  order: 1;
}

.awsui_dismiss-control_xjuzf_10ufd_357:not(#\9) {
  /* used in test-utils */
}

.awsui_header-row_xjuzf_10ufd_361:not(#\9) {
  margin-bottom: var(--space-xs-rsr2qu, 8px);
}

.awsui_header_xjuzf_10ufd_361:not(#\9) {
  word-wrap: break-word;
  max-width: 100%;
  overflow: hidden;
  font-size: var(--font-heading-xs-size-fryxro, 14px);
  line-height: var(--font-heading-xs-line-height-5x131j, 18px);
  font-weight: var(--font-heading-xs-weight-wwznty, 800);
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
  flex: 1 1 auto;
  /* stylelint-disable selector-max-type */
  /* stylelint-enable selector-max-type */
}
.awsui_header_xjuzf_10ufd_361 > h2:not(#\9) {
  font-size: var(--font-heading-xs-size-fryxro, 14px);
  line-height: var(--font-heading-xs-line-height-5x131j, 18px);
  font-weight: var(--font-heading-xs-weight-wwznty, 800);
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
  display: inline;
}

.awsui_content_xjuzf_10ufd_387:not(#\9) {
  word-wrap: break-word;
  max-width: 100%;
  overflow: hidden;
  color: var(--color-text-body-secondary-fdstdf, #414d5c);
  flex: 1 1 auto;
  min-width: 0;
}
.awsui_content-overflow-visible_xjuzf_10ufd_395:not(#\9) {
  overflow: visible;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_container_xjuzf_10ufd_491:not(#\9) {
  display: inline-block;
  position: fixed;
  top: -9999px;
  left: -9999px;
  z-index: 2000;
}

.awsui_container-body_xjuzf_10ufd_499:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  border-radius: var(--border-radius-popover-8g1bwn, 8px);
  background-color: var(--color-background-popover-mn5o9k, #ffffff);
  box-shadow: var(--shadow-popover-kzy6rd, 0px 4px 20px 1px rgba(0, 7, 22, 0.1));
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-popover-5pygk7, #9ba7b6);
}

.awsui_container-body-variant-annotation_xjuzf_10ufd_516:not(#\9) {
  background-color: var(--color-background-status-info-usb30e, #f2f8fd);
  border-color: var(--color-border-status-info-546i7i, #0972d3);
}

.awsui_container-body-size-small_xjuzf_10ufd_521:not(#\9) {
  max-width: 210px;
}
.awsui_container-body-size-small_xjuzf_10ufd_521.awsui_fixed-width_xjuzf_10ufd_524:not(#\9) {
  width: 210px;
}

.awsui_container-body-size-medium_xjuzf_10ufd_528:not(#\9) {
  max-width: 310px;
}
.awsui_container-body-size-medium_xjuzf_10ufd_528.awsui_fixed-width_xjuzf_10ufd_524:not(#\9) {
  width: 310px;
}

.awsui_container-body-size-large_xjuzf_10ufd_535:not(#\9) {
  max-width: 480px;
}
@media (max-width: 480px) {
  .awsui_container-body-size-large_xjuzf_10ufd_535:not(#\9) {
    max-width: 310px;
  }
}
.awsui_container-body-size-large_xjuzf_10ufd_535.awsui_fixed-width_xjuzf_10ufd_524:not(#\9) {
  width: 480px;
}

.awsui_container-arrow_xjuzf_10ufd_547:not(#\9) {
  position: absolute;
  display: inline-block;
}
.awsui_container-arrow-position-right-top_xjuzf_10ufd_551:not(#\9), .awsui_container-arrow-position-right-bottom_xjuzf_10ufd_551:not(#\9) {
  transform: rotate(-90deg);
  transform-origin: 0 100%;
}
.awsui_container-arrow-position-right-top_xjuzf_10ufd_551:not(#\9) {
  top: calc(12px + 10px);
  left: 0;
}
.awsui_container-arrow-position-right-bottom_xjuzf_10ufd_551:not(#\9) {
  bottom: 12px;
  left: 0;
}
.awsui_container-arrow-position-left-top_xjuzf_10ufd_563:not(#\9), .awsui_container-arrow-position-left-bottom_xjuzf_10ufd_563:not(#\9) {
  transform: rotate(90deg);
  transform-origin: 100% 100%;
}
.awsui_container-arrow-position-left-top_xjuzf_10ufd_563:not(#\9) {
  top: calc(12px + 10px);
  right: 0;
}
.awsui_container-arrow-position-left-bottom_xjuzf_10ufd_563:not(#\9) {
  bottom: 12px;
  right: 0;
}
.awsui_container-arrow-position-top-center_xjuzf_10ufd_575:not(#\9), .awsui_container-arrow-position-top-right_xjuzf_10ufd_575:not(#\9), .awsui_container-arrow-position-top-left_xjuzf_10ufd_575:not(#\9), .awsui_container-arrow-position-top-responsive_xjuzf_10ufd_575:not(#\9) {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}
.awsui_container-arrow-position-top-center_xjuzf_10ufd_575:not(#\9) {
  bottom: -10px;
  left: calc(50% - 10px);
}
.awsui_container-arrow-position-top-right_xjuzf_10ufd_575:not(#\9) {
  bottom: -10px;
  left: 12px;
}
.awsui_container-arrow-position-top-left_xjuzf_10ufd_575:not(#\9) {
  bottom: -10px;
  left: calc(100% - 20px - 12px);
}
.awsui_container-arrow-position-bottom-center_xjuzf_10ufd_591:not(#\9) {
  top: -10px;
  left: calc(50% - 10px);
}
.awsui_container-arrow-position-bottom-right_xjuzf_10ufd_595:not(#\9) {
  top: -10px;
  left: 12px;
}
.awsui_container-arrow-position-bottom-left_xjuzf_10ufd_599:not(#\9) {
  top: -10px;
  left: calc(100% - 20px - 12px);
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_container_xjuzf_10ufd_491:not(#\9) {
  animation: awsui_awsui-motion-fade-in_xjuzf_10ufd_1 var(--motion-duration-show-paced-7wspqf, 180ms) var(--motion-easing-show-paced-wj4ybn, ease-out);
}
@keyframes awsui_awsui-motion-fade-in_xjuzf_10ufd_1 {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_container_xjuzf_10ufd_491:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_container_xjuzf_10ufd_491:not(#\9), .awsui-mode-entering .awsui_container_xjuzf_10ufd_491:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_container_xjuzf_10ufd_491.awsui_refresh_xjuzf_10ufd_717:not(#\9) {
  animation: awsui_awsui-motion-fade-in_xjuzf_10ufd_1 var(--motion-duration-refresh-only-fast-addxjd, 115ms) var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
}
@keyframes awsui_awsui-motion-fade-in_xjuzf_10ufd_1 {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_container_xjuzf_10ufd_491.awsui_refresh_xjuzf_10ufd_717:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_container_xjuzf_10ufd_491.awsui_refresh_xjuzf_10ufd_717:not(#\9), .awsui-mode-entering .awsui_container_xjuzf_10ufd_491.awsui_refresh_xjuzf_10ufd_717:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_root_xjuzf_10ufd_739:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  color: inherit;
  display: inline;
}

.awsui_trigger_xjuzf_10ufd_754:not(#\9) {
  display: inline-block;
  max-width: 100%;
  color: inherit;
  text-align: inherit;
}

.awsui_trigger-type-text_xjuzf_10ufd_761:not(#\9) {
  border: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  border-bottom: var(--border-divider-list-width-hacikr, 1px) dashed currentColor;
}
.awsui_trigger-type-text_xjuzf_10ufd_761:not(#\9):focus {
  outline: none;
}
.awsui_trigger-type-text_xjuzf_10ufd_761[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_trigger-type-text_xjuzf_10ufd_761[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(1px - 1px);
}
.awsui_trigger-type-text_xjuzf_10ufd_761[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 1px);
  top: calc(-1 * 1px);
  width: calc(100% + 2 * 1px);
  height: calc(100% + 2 * 1px);
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_trigger-type-text_xjuzf_10ufd_761 > .awsui_trigger-inner-text_xjuzf_10ufd_790:not(#\9) {
  position: relative;
}

.awsui_popover-content_xjuzf_10ufd_794:not(#\9) {
  display: inline;
}