/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
div.awsui_column-layout_vvxn7_1n7gs_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  word-wrap: break-word;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106:not(#\9) {
  margin: calc(var(--space-grid-gutter-0subky, 20px) / -2);
  /* stylelint-disable-next-line selector-max-universal */
  /* stylelint-disable-next-line selector-max-universal */
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-no-gutters_vvxn7_1n7gs_111:not(#\9) {
  margin: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-variant-text-grid_vvxn7_1n7gs_114:not(#\9) {
  /* stylelint-disable-next-line selector-max-universal */
  margin: calc(-1 * (var(--space-text-grid-vertical-tj8m1c, 16px))) calc(-1 * var(--space-grid-gutter-0subky, 20px));
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-variant-text-grid_vvxn7_1n7gs_114 > *:not(#\9) {
  border-left: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-variant-text-grid_vvxn7_1n7gs_114.awsui_grid-breakpoint-default_vvxn7_1n7gs_121 > *:not(#\9):nth-child(1n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-variant-text-grid_vvxn7_1n7gs_114.awsui_grid-columns-1_vvxn7_1n7gs_124.awsui_grid-breakpoint-xxs_vvxn7_1n7gs_124 > *:not(#\9):nth-child(1n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-variant-text-grid_vvxn7_1n7gs_114.awsui_grid-columns-1_vvxn7_1n7gs_124.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-child(1n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-variant-text-grid_vvxn7_1n7gs_114.awsui_grid-columns-2_vvxn7_1n7gs_130.awsui_grid-breakpoint-xxs_vvxn7_1n7gs_124 > *:not(#\9):nth-child(2n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-variant-text-grid_vvxn7_1n7gs_114.awsui_grid-columns-2_vvxn7_1n7gs_130.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-child(2n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-variant-text-grid_vvxn7_1n7gs_114.awsui_grid-columns-3_vvxn7_1n7gs_136.awsui_grid-breakpoint-xxs_vvxn7_1n7gs_124 > *:not(#\9):nth-child(2n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-variant-text-grid_vvxn7_1n7gs_114.awsui_grid-columns-3_vvxn7_1n7gs_136.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-child(3n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-variant-text-grid_vvxn7_1n7gs_114.awsui_grid-columns-4_vvxn7_1n7gs_142.awsui_grid-breakpoint-xxs_vvxn7_1n7gs_124 > *:not(#\9):nth-child(2n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-variant-text-grid_vvxn7_1n7gs_114.awsui_grid-columns-4_vvxn7_1n7gs_142.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-child(4n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106:not(#\9):not(.awsui_grid-no-gutters_vvxn7_1n7gs_111) > * {
  padding: calc(var(--space-grid-gutter-0subky, 20px) / 2);
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106:not(#\9):not(.awsui_grid-no-gutters_vvxn7_1n7gs_111).awsui_grid-variant-text-grid_vvxn7_1n7gs_114 > * {
  padding: 0 var(--space-grid-gutter-0subky, 20px);
  margin: var(--space-text-grid-vertical-tj8m1c, 16px) 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-vertical-borders_vvxn7_1n7gs_155:not(#\9) {
  /* stylelint-disable-next-line selector-max-universal */
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-vertical-borders_vvxn7_1n7gs_155 > *:not(#\9) {
  border-right: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-vertical-borders_vvxn7_1n7gs_155.awsui_grid-breakpoint-default_vvxn7_1n7gs_121 > *:not(#\9):nth-child(1n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-vertical-borders_vvxn7_1n7gs_155.awsui_grid-columns-1_vvxn7_1n7gs_124.awsui_grid-breakpoint-xxs_vvxn7_1n7gs_124 > *:not(#\9):nth-child(1n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-vertical-borders_vvxn7_1n7gs_155.awsui_grid-columns-1_vvxn7_1n7gs_124.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-child(1n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-vertical-borders_vvxn7_1n7gs_155.awsui_grid-columns-2_vvxn7_1n7gs_130.awsui_grid-breakpoint-xxs_vvxn7_1n7gs_124 > *:not(#\9):nth-child(2n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-vertical-borders_vvxn7_1n7gs_155.awsui_grid-columns-2_vvxn7_1n7gs_130.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-child(2n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-vertical-borders_vvxn7_1n7gs_155.awsui_grid-columns-3_vvxn7_1n7gs_136.awsui_grid-breakpoint-xxs_vvxn7_1n7gs_124 > *:not(#\9):nth-child(2n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-vertical-borders_vvxn7_1n7gs_155.awsui_grid-columns-3_vvxn7_1n7gs_136.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-child(3n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-vertical-borders_vvxn7_1n7gs_155.awsui_grid-columns-4_vvxn7_1n7gs_142.awsui_grid-breakpoint-xxs_vvxn7_1n7gs_124 > *:not(#\9):nth-child(2n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-vertical-borders_vvxn7_1n7gs_155.awsui_grid-columns-4_vvxn7_1n7gs_142.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-child(4n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-horizontal-borders_vvxn7_1n7gs_188:not(#\9) {
  /* stylelint-disable selector-max-universal */
  /* stylelint-enable selector-max-universal */
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-horizontal-borders_vvxn7_1n7gs_188 > *:not(#\9) {
  border-bottom: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-horizontal-borders_vvxn7_1n7gs_188 > *:not(#\9):last-child {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-horizontal-borders_vvxn7_1n7gs_188.awsui_grid-columns-2_vvxn7_1n7gs_130.awsui_grid-breakpoint-xxs_vvxn7_1n7gs_124 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-horizontal-borders_vvxn7_1n7gs_188.awsui_grid-columns-2_vvxn7_1n7gs_130.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-horizontal-borders_vvxn7_1n7gs_188.awsui_grid-columns-3_vvxn7_1n7gs_136.awsui_grid-breakpoint-xxs_vvxn7_1n7gs_124 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-horizontal-borders_vvxn7_1n7gs_188.awsui_grid-columns-3_vvxn7_1n7gs_136.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-last-child(2):nth-child(3n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-horizontal-borders_vvxn7_1n7gs_188.awsui_grid-columns-3_vvxn7_1n7gs_136.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-last-child(2):nth-child(3n+2) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-horizontal-borders_vvxn7_1n7gs_188.awsui_grid-columns-3_vvxn7_1n7gs_136.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-last-child(3):nth-child(3n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-horizontal-borders_vvxn7_1n7gs_188.awsui_grid-columns-4_vvxn7_1n7gs_142.awsui_grid-breakpoint-xxs_vvxn7_1n7gs_124 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-horizontal-borders_vvxn7_1n7gs_188.awsui_grid-columns-4_vvxn7_1n7gs_142.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-last-child(2):nth-child(4n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-horizontal-borders_vvxn7_1n7gs_188.awsui_grid-columns-4_vvxn7_1n7gs_142.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-last-child(2):nth-child(4n+2) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-horizontal-borders_vvxn7_1n7gs_188.awsui_grid-columns-4_vvxn7_1n7gs_142.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-last-child(2):nth-child(4n+3) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-horizontal-borders_vvxn7_1n7gs_188.awsui_grid-columns-4_vvxn7_1n7gs_142.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-last-child(3):nth-child(4n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-horizontal-borders_vvxn7_1n7gs_188.awsui_grid-columns-4_vvxn7_1n7gs_142.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-last-child(3):nth-child(4n+2) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1n7gs_93 > .awsui_grid_vvxn7_1n7gs_106.awsui_grid-horizontal-borders_vvxn7_1n7gs_188.awsui_grid-columns-4_vvxn7_1n7gs_142.awsui_grid-breakpoint-xs_vvxn7_1n7gs_127 > *:not(#\9):nth-last-child(4):nth-child(4n+1) {
  border-bottom-width: 0;
}