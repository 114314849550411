/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_item-element_93a1u_7y9ov_93:not(#\9) {
  position: relative;
  z-index: 1;
  border: var(--border-item-width-qbbbsa, 2px) solid transparent;
  list-style: none;
  padding: 0;
  color: var(--color-text-dropdown-item-default-7tg9p2, #000716);
  margin-top: calc(-1 * var(--border-field-width-idlekx, 2px));
  cursor: pointer;
}
.awsui_item-element_93a1u_7y9ov_93.awsui_disabled_93a1u_7y9ov_103:not(#\9) {
  cursor: default;
  color: var(--color-text-dropdown-item-disabled-varol7, #9ba7b6);
}
.awsui_item-element_93a1u_7y9ov_93:not(#\9):first-child {
  margin-top: 0;
}
.awsui_item-element_93a1u_7y9ov_93.awsui_last_93a1u_7y9ov_110:not(#\9) {
  border-bottom: var(--border-item-width-qbbbsa, 2px) solid var(--color-border-dropdown-group-0utpsr, #e9ebed);
}
.awsui_item-element_93a1u_7y9ov_93.awsui_highlighted_93a1u_7y9ov_113:not(#\9) {
  color: var(--color-text-dropdown-item-highlighted-oaabyk, #000716);
  z-index: 2;
}
.awsui_item-element_93a1u_7y9ov_93.awsui_highlighted_93a1u_7y9ov_113.awsui_variant-icon_93a1u_7y9ov_117:not(#\9), .awsui_item-element_93a1u_7y9ov_93.awsui_highlighted_93a1u_7y9ov_113.awsui_variant-normal_93a1u_7y9ov_117:not(#\9), .awsui_item-element_93a1u_7y9ov_93.awsui_highlighted_93a1u_7y9ov_113.awsui_variant-primary_93a1u_7y9ov_117:not(#\9) {
  background-color: var(--color-background-dropdown-item-hover-mqsxkv, #f4f4f4);
  border-color: var(--color-border-dropdown-item-hover-tyzq9m, #7d8998);
  border-radius: var(--border-radius-item-u2ibpi, 8px);
}
.awsui_item-element_93a1u_7y9ov_93.awsui_highlighted_93a1u_7y9ov_113.awsui_variant-icon_93a1u_7y9ov_117.awsui_disabled_93a1u_7y9ov_103:not(#\9), .awsui_item-element_93a1u_7y9ov_93.awsui_highlighted_93a1u_7y9ov_113.awsui_variant-normal_93a1u_7y9ov_117.awsui_disabled_93a1u_7y9ov_103:not(#\9), .awsui_item-element_93a1u_7y9ov_93.awsui_highlighted_93a1u_7y9ov_113.awsui_variant-primary_93a1u_7y9ov_117.awsui_disabled_93a1u_7y9ov_103:not(#\9) {
  color: var(--color-text-dropdown-item-dimmed-jyqdrs, #9ba7b6);
  border-color: var(--color-border-dropdown-item-dimmed-hover-6dhy15, #7d8998);
  background-color: var(--color-background-dropdown-item-dimmed-qw48ma, transparent);
}
.awsui_item-element_93a1u_7y9ov_93.awsui_highlighted_93a1u_7y9ov_113.awsui_variant-icon_93a1u_7y9ov_117.awsui_is-focused_93a1u_7y9ov_127:not(#\9), .awsui_item-element_93a1u_7y9ov_93.awsui_highlighted_93a1u_7y9ov_113.awsui_variant-normal_93a1u_7y9ov_117.awsui_is-focused_93a1u_7y9ov_127:not(#\9), .awsui_item-element_93a1u_7y9ov_93.awsui_highlighted_93a1u_7y9ov_113.awsui_variant-primary_93a1u_7y9ov_117.awsui_is-focused_93a1u_7y9ov_127:not(#\9) {
  border-color: var(--color-border-item-focused-ap3b6s, #0972d3);
  box-shadow: inset 0 0 0 var(--border-control-focus-ring-shadow-spread-srzyiu, 0px) var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_item-element_93a1u_7y9ov_93.awsui_variant-navigation_93a1u_7y9ov_131.awsui_highlighted_93a1u_7y9ov_113:not(#\9) {
  color: var(--color-text-accent-6sf3a3, #0972D3);
}
.awsui_item-element_93a1u_7y9ov_93.awsui_variant-navigation_93a1u_7y9ov_131.awsui_first_93a1u_7y9ov_134:not(#\9):not(.awsui_has-category-header_93a1u_7y9ov_134) {
  padding-top: var(--space-xxs-ynfts5, 4px);
}
.awsui_item-element_93a1u_7y9ov_93.awsui_variant-navigation_93a1u_7y9ov_131.awsui_last_93a1u_7y9ov_110:not(#\9) {
  padding-bottom: var(--space-xxs-ynfts5, 4px);
}
.awsui_item-element_93a1u_7y9ov_93.awsui_variant-navigation_93a1u_7y9ov_131.awsui_first_93a1u_7y9ov_134.awsui_last_93a1u_7y9ov_110:not(#\9) {
  padding-bottom: var(--space-xxs-ynfts5, 4px);
  padding-top: var(--space-xxs-ynfts5, 4px);
}
.awsui_item-element_93a1u_7y9ov_93.awsui_variant-navigation_93a1u_7y9ov_131.awsui_last_93a1u_7y9ov_110:not(#\9):last-child {
  padding-bottom: var(--space-xxs-ynfts5, 4px);
}

.awsui_menu-item_93a1u_7y9ov_148:not(#\9) {
  min-width: 0;
  -ms-word-break: break-all;
  word-break: break-word;
  display: flex;
  align-items: flex-start;
  padding: var(--space-xxs-ynfts5, 4px) var(--space-l-4vl6xu, 20px);
  color: inherit;
  text-decoration: none;
  /* stylelint-disable-next-line selector-max-type */
}
.awsui_menu-item_93a1u_7y9ov_148:not(#\9):focus {
  outline: none;
}
.awsui_has-category-header_93a1u_7y9ov_134 > .awsui_menu-item_93a1u_7y9ov_148:not(#\9), .awsui_has-category-header_93a1u_7y9ov_134 > span > .awsui_menu-item_93a1u_7y9ov_148:not(#\9) {
  padding-left: var(--space-xxl-2nvmf1, 32px);
}

.awsui_icon_93a1u_7y9ov_166:not(#\9) {
  padding-right: var(--space-xs-rsr2qu, 8px);
  flex-shrink: 0;
}

.awsui_external-icon_93a1u_7y9ov_171:not(#\9) {
  margin-left: var(--space-xxs-ynfts5, 4px);
}