/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_alert_mx3cw_1ccxv_93:not(#\9) {
  animation: awsui_awsui-motion-fade-in_mx3cw_1ccxv_1 var(--motion-duration-show-paced-7wspqf, 180ms) var(--motion-easing-show-paced-wj4ybn, ease-out);
}
@keyframes awsui_awsui-motion-fade-in_mx3cw_1ccxv_1 {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_alert_mx3cw_1ccxv_93:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_alert_mx3cw_1ccxv_93:not(#\9), .awsui-mode-entering .awsui_alert_mx3cw_1ccxv_93:not(#\9) {
  animation: none;
  transition: none;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_mx3cw_1ccxv_119:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  word-wrap: break-word;
  max-width: 100%;
  overflow: hidden;
  display: block;
}
.awsui_root_mx3cw_1ccxv_119.awsui_hidden_mx3cw_1ccxv_135:not(#\9) {
  display: none;
}

.awsui_alert_mx3cw_1ccxv_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: var(--border-radius-alert-n0skwy, 12px);
  border: var(--border-field-width-idlekx, 2px) solid;
  padding: var(--space-alert-vertical-jbva6z, 8px) var(--space-alert-horizontal-ulbnu9, 16px);
  background-color: var(--color-background-container-content-iw8cer, #FFFFFF);
}

.awsui_body_mx3cw_1ccxv_160:not(#\9) {
  display: flex;
  flex: 1 1 0%;
  min-width: 0;
}

.awsui_header_mx3cw_1ccxv_166:not(#\9) {
  font-weight: 700;
}

.awsui_action_mx3cw_1ccxv_170:not(#\9) {
  white-space: nowrap;
  margin-left: var(--space-s-hv8c1d, 12px);
}

.awsui_action-button_mx3cw_1ccxv_175:not(#\9) {
  /* used in test-utils */
}

.awsui_text_mx3cw_1ccxv_179:not(#\9) {
  padding: var(--border-field-width-idlekx, 2px) 0;
  margin: var(--space-scaled-xxs-95dhkm, 4px) var(--space-xxs-ynfts5, 4px);
}
.awsui_text_mx3cw_1ccxv_179.awsui_icon_mx3cw_1ccxv_183:not(#\9) {
  margin-left: 0;
}
.awsui_text_mx3cw_1ccxv_179.awsui_message_mx3cw_1ccxv_186:not(#\9) {
  margin-right: var(--space-alert-message-right-qop1p1, 4px);
}
.awsui_text_mx3cw_1ccxv_179.awsui_message_mx3cw_1ccxv_186 + .awsui_action_mx3cw_1ccxv_170:not(#\9) {
  margin-left: var(--space-alert-action-left-hi50rw, 12px);
}

/* stylelint-disable selector-max-type */
.awsui_root_mx3cw_1ccxv_119.awsui_breakpoint-default_mx3cw_1ccxv_194 > div > .awsui_alert_mx3cw_1ccxv_93 > .awsui_body_mx3cw_1ccxv_160:not(#\9) {
  display: block;
}
.awsui_root_mx3cw_1ccxv_119.awsui_breakpoint-default_mx3cw_1ccxv_194 > div > .awsui_alert_mx3cw_1ccxv_93 > .awsui_body_mx3cw_1ccxv_160 > .awsui_action_mx3cw_1ccxv_170:not(#\9) {
  margin-left: var(--space-xxs-ynfts5, 4px);
  margin-bottom: var(--space-xxs-ynfts5, 4px);
}

/* stylelint-enable selector-max-type */
.awsui_message_mx3cw_1ccxv_186:not(#\9) {
  flex: 1 1 0%;
  min-width: 0;
}

.awsui_content_mx3cw_1ccxv_208:not(#\9) {
  /* used in test-utils */
}

.awsui_dismiss_mx3cw_1ccxv_212:not(#\9) {
  flex: 0 0 auto;
  margin-right: calc(-1 * var(--space-xxs-ynfts5, 4px));
  margin-left: var(--space-s-hv8c1d, 12px);
}

.awsui_dismiss-button_mx3cw_1ccxv_218:not(#\9) {
  /* used in test-utils */
}

.awsui_icon_mx3cw_1ccxv_183:not(#\9) {
  flex: 0 0 auto;
}

.awsui_type-error_mx3cw_1ccxv_226:not(#\9) {
  border-color: var(--color-border-status-error-mfrddc, #d91515);
  background-color: var(--color-background-status-error-pe1hux, #fff7f7);
}
.awsui_type-error_mx3cw_1ccxv_226 > .awsui_icon_mx3cw_1ccxv_183:not(#\9) {
  color: var(--color-text-status-error-5sesl6, #d91515);
}

.awsui_type-warning_mx3cw_1ccxv_234:not(#\9) {
  border-color: var(--color-border-status-warning-hg85ut, #7d8998);
  background-color: var(--color-background-status-warning-zz4oi6, #ffffff);
}
.awsui_type-warning_mx3cw_1ccxv_234 > .awsui_icon_mx3cw_1ccxv_183:not(#\9) {
  color: var(--color-text-status-warning-27nlyh, #d91515);
}

.awsui_type-success_mx3cw_1ccxv_242:not(#\9) {
  border-color: var(--color-border-status-success-cqi45f, #037f0c);
  background-color: var(--color-background-status-success-xnxxn4, #f2fcf3);
}
.awsui_type-success_mx3cw_1ccxv_242 > .awsui_icon_mx3cw_1ccxv_183:not(#\9) {
  color: var(--color-text-status-success-0fzhv9, #037f0c);
}

.awsui_type-info_mx3cw_1ccxv_250:not(#\9) {
  border-color: var(--color-border-status-info-546i7i, #0972d3);
  background-color: var(--color-background-status-info-usb30e, #f2f8fd);
}
.awsui_type-info_mx3cw_1ccxv_250 > .awsui_icon_mx3cw_1ccxv_183:not(#\9) {
  color: var(--color-text-status-info-utupcz, #0972d3);
}