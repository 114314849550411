/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_1mabk_tdicb_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: block;
}

.awsui_radio_1mabk_tdicb_107:not(#\9) {
  /*used in test-utils*/
}

.awsui_radio_1mabk_tdicb_107 + .awsui_radio_1mabk_tdicb_107:not(#\9) {
  margin-top: var(--space-scaled-xxs-95dhkm, 4px);
}

.awsui_radio--has-description_1mabk_tdicb_115 + .awsui_radio_1mabk_tdicb_107:not(#\9) {
  margin-top: var(--space-scaled-xs-6859qs, 8px);
}

.awsui_radio-control_1mabk_tdicb_119:not(#\9) {
  margin-top: calc((var(--font-body-m-line-height-i7xxvv, 22px) - var(--size-control-n9i4yo, 16px)) / 2);
  min-height: var(--size-control-n9i4yo, 16px);
  min-width: var(--size-control-n9i4yo, 16px);
  height: var(--size-control-n9i4yo, 16px);
  width: var(--size-control-n9i4yo, 16px);
}

.awsui_outline_1mabk_tdicb_127:not(#\9) {
  position: relative;
}
.awsui_outline_1mabk_tdicb_127:not(#\9) {
  outline: 2px dotted transparent;
  outline-offset: calc(2px - 1px);
}
.awsui_outline_1mabk_tdicb_127:not(#\9)::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 2px);
  top: calc(-1 * 2px);
  width: calc(100% + 2 * 2px);
  height: calc(100% + 2 * 2px);
  border-radius: var(--border-radius-control-circular-focus-ring-qawzn7, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}

.awsui_styled-circle-border_1mabk_tdicb_146:not(#\9) {
  stroke: var(--color-border-control-default-m3lmsh, #7d8998);
  fill: var(--color-background-control-default-4pa05r, #ffffff);
}
.awsui_styled-circle-border_1mabk_tdicb_146.awsui_styled-circle-disabled_1mabk_tdicb_150:not(#\9) {
  fill: var(--color-background-control-disabled-zplipy, #d1d5db);
  stroke: var(--color-background-control-disabled-zplipy, #d1d5db);
}

.awsui_styled-circle-fill_1mabk_tdicb_155:not(#\9) {
  stroke: var(--color-background-control-checked-9admlu, #0972d3);
  fill: var(--color-foreground-control-default-7ajdem, #ffffff);
  opacity: 0;
  transition: opacity var(--motion-duration-transition-quick-x85tae, 90ms) var(--motion-easing-transition-quick-lukbd8, linear);
}
.awsui_styled-circle-fill_1mabk_tdicb_155.awsui_styled-circle-checked_1mabk_tdicb_161:not(#\9) {
  opacity: 1;
}
.awsui_styled-circle-fill_1mabk_tdicb_155.awsui_styled-circle-disabled_1mabk_tdicb_150:not(#\9) {
  fill: var(--color-foreground-control-disabled-v6a97u, #ffffff);
  stroke: var(--color-background-control-disabled-zplipy, #d1d5db);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_styled-circle-fill_1mabk_tdicb_155:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_styled-circle-fill_1mabk_tdicb_155:not(#\9), .awsui-mode-entering .awsui_styled-circle-fill_1mabk_tdicb_155:not(#\9) {
  animation: none;
  transition: none;
}