:root {
  --color-transparent-b76lky:transparent;
  --color-charts-red-300-taex3d:#ea7158;
  --color-charts-red-400-xo1w45:#dc5032;
  --color-charts-red-500-8et8sv:#d13313;
  --color-charts-red-600-ej8iox:#ba2e0f;
  --color-charts-red-700-pe7xt7:#a82a0c;
  --color-charts-red-800-9wqz57:#972709;
  --color-charts-red-900-fkiox3:#892407;
  --color-charts-red-1000-xhb2ma:#7d2105;
  --color-charts-red-1100-zkhjl8:#721e03;
  --color-charts-red-1200-t42yl7:#671c00;
  --color-charts-orange-300-uh1x0z:#e07941;
  --color-charts-orange-400-rg9ft0:#cc5f21;
  --color-charts-orange-500-6dcz8x:#bc4d01;
  --color-charts-orange-600-psg5i7:#a84401;
  --color-charts-orange-700-6rlkwo:#983c02;
  --color-charts-orange-800-aqh9u5:#8a3603;
  --color-charts-orange-900-71d364:#7e3103;
  --color-charts-orange-1000-p3xy9d:#732c02;
  --color-charts-orange-1100-plp0ix:#692801;
  --color-charts-orange-1200-msf9v8:#602400;
  --color-charts-yellow-300-zgwohc:#b2911c;
  --color-charts-yellow-400-z6s56e:#9c7b0b;
  --color-charts-yellow-500-f52bpo:#8a6b05;
  --color-charts-yellow-600-gzedvz:#7b5f04;
  --color-charts-yellow-700-uq1jq9:#6f5504;
  --color-charts-yellow-800-esbvam:#654d03;
  --color-charts-yellow-900-e2ugyo:#5d4503;
  --color-charts-yellow-1000-myf1mq:#553f03;
  --color-charts-yellow-1100-fi5c3g:#4d3901;
  --color-charts-yellow-1200-faeu24:#483300;
  --color-charts-green-300-8q7o65:#67a353;
  --color-charts-green-400-b5efwo:#41902c;
  --color-charts-green-500-o7urau:#1f8104;
  --color-charts-green-600-8e3las:#1a7302;
  --color-charts-green-700-h4agmh:#176702;
  --color-charts-green-800-jp1xwf:#145d02;
  --color-charts-green-900-czmqk3:#125502;
  --color-charts-green-1000-wrs7fm:#104d01;
  --color-charts-green-1100-d8mgjl:#0f4601;
  --color-charts-green-1200-jyfc23:#0d4000;
  --color-charts-teal-300-egfaot:#2ea597;
  --color-charts-teal-400-ikctdb:#1c8e81;
  --color-charts-teal-500-v952sw:#0d7d70;
  --color-charts-teal-600-9ffc3q:#096f64;
  --color-charts-teal-700-nn7a5u:#06645a;
  --color-charts-teal-800-97l4n7:#045b52;
  --color-charts-teal-900-zq1u0w:#03524a;
  --color-charts-teal-1000-u4e4vv:#014b44;
  --color-charts-teal-1100-cdt1d8:#01443e;
  --color-charts-teal-1200-3ozaok:#003e38;
  --color-charts-blue-1-300-4x8rnx:#529ccb;
  --color-charts-blue-1-400-owg3dt:#3184c2;
  --color-charts-blue-1-500-sl8chl:#0273bb;
  --color-charts-blue-1-600-3824a7:#0166ab;
  --color-charts-blue-1-700-e3d0uw:#015b9d;
  --color-charts-blue-1-800-vnypf9:#015292;
  --color-charts-blue-1-900-rs4k5z:#014a87;
  --color-charts-blue-1-1000-wjr7hn:#01437d;
  --color-charts-blue-1-1100-nmn22x:#003c75;
  --color-charts-blue-1-1200-od4mlj:#00366d;
  --color-charts-blue-2-300-6yvfip:#688ae8;
  --color-charts-blue-2-400-iba8cu:#5978e3;
  --color-charts-blue-2-500-vtdvkt:#4066df;
  --color-charts-blue-2-600-tpo4rj:#3759ce;
  --color-charts-blue-2-700-p6tu56:#314fbf;
  --color-charts-blue-2-800-08f3pp:#2c46b1;
  --color-charts-blue-2-900-3aq93e:#273ea5;
  --color-charts-blue-2-1000-6b05hm:#23379b;
  --color-charts-blue-2-1100-0014sa:#1f3191;
  --color-charts-blue-2-1200-tzr398:#1b2b88;
  --color-charts-purple-300-yatxj8:#a783e1;
  --color-charts-purple-400-d384pt:#9469d6;
  --color-charts-purple-500-skntta:#8456ce;
  --color-charts-purple-600-8iqvzu:#7749bf;
  --color-charts-purple-700-3qzy9s:#6b40b2;
  --color-charts-purple-800-ucyxda:#6237a7;
  --color-charts-purple-900-tlepdb:#59309d;
  --color-charts-purple-1000-3pjp6l:#512994;
  --color-charts-purple-1100-t0m9qa:#4a238b;
  --color-charts-purple-1200-003tqq:#431d84;
  --color-charts-pink-300-0gug9u:#da7596;
  --color-charts-pink-400-jh84a9:#ce567c;
  --color-charts-pink-500-c97yed:#c33d69;
  --color-charts-pink-600-rpo3pi:#b1325c;
  --color-charts-pink-700-mshe2z:#a32952;
  --color-charts-pink-800-bph464:#962249;
  --color-charts-pink-900-mvfm0y:#8b1b42;
  --color-charts-pink-1000-kq8pu0:#81143b;
  --color-charts-pink-1100-pi0m3u:#780d35;
  --color-charts-pink-1200-hukz4p:#6f062f;
  --color-charts-status-critical-268let:#7d2105;
  --color-charts-status-high-vsd25n:#ba2e0f;
  --color-charts-status-medium-371m8g:#cc5f21;
  --color-charts-status-low-lfwc29:#b2911c;
  --color-charts-status-positive-2ozsqj:#67a353;
  --color-charts-status-info-l8g84h:#3184c2;
  --color-charts-status-neutral-b4b0p2:#7d8998;
  --color-charts-threshold-negative-y7lrhf:#d91515;
  --color-charts-threshold-positive-42ybvt:#037f0c;
  --color-charts-threshold-info-kcjduw:#0972d3;
  --color-charts-threshold-neutral-k14cuc:#5f6b7a;
  --color-charts-line-grid-6wil4m:#d1d5db;
  --color-charts-line-tick-yidpvu:#d1d5db;
  --color-charts-line-axis-yo865k:#d1d5db;
  --color-charts-palette-categorical-1-bonb16:#688ae8;
  --color-charts-palette-categorical-2-emtquh:#c33d69;
  --color-charts-palette-categorical-3-imrqvq:#2ea597;
  --color-charts-palette-categorical-4-g31h69:#8456ce;
  --color-charts-palette-categorical-5-64f3p2:#e07941;
  --color-charts-palette-categorical-6-msr86m:#3759ce;
  --color-charts-palette-categorical-7-kdjms7:#962249;
  --color-charts-palette-categorical-8-79776a:#096f64;
  --color-charts-palette-categorical-9-s4w3dd:#6237a7;
  --color-charts-palette-categorical-10-cuicpx:#a84401;
  --color-charts-palette-categorical-11-no2uu5:#273ea5;
  --color-charts-palette-categorical-12-jq9rcw:#780d35;
  --color-charts-palette-categorical-13-4cawsz:#03524a;
  --color-charts-palette-categorical-14-i1n5cl:#4a238b;
  --color-charts-palette-categorical-15-9pgabq:#7e3103;
  --color-charts-palette-categorical-16-wnvn1q:#1b2b88;
  --color-charts-palette-categorical-17-y2duhb:#ce567c;
  --color-charts-palette-categorical-18-xehfp2:#003e38;
  --color-charts-palette-categorical-19-v1x5n3:#9469d6;
  --color-charts-palette-categorical-20-q5g6qv:#602400;
  --color-charts-palette-categorical-21-z2fito:#4066df;
  --color-charts-palette-categorical-22-mdbc68:#a32952;
  --color-charts-palette-categorical-23-9iyat0:#0d7d70;
  --color-charts-palette-categorical-24-17kvjc:#6b40b2;
  --color-charts-palette-categorical-25-c2vakt:#bc4d01;
  --color-charts-palette-categorical-26-g98tv5:#2c46b1;
  --color-charts-palette-categorical-27-lo8vzl:#81143b;
  --color-charts-palette-categorical-28-2ppjnc:#045b52;
  --color-charts-palette-categorical-29-68gzom:#512994;
  --color-charts-palette-categorical-30-9pbejo:#8a3603;
  --color-charts-palette-categorical-31-8odgjs:#1f3191;
  --color-charts-palette-categorical-32-xug28a:#da7596;
  --color-charts-palette-categorical-33-cr6l8a:#01443e;
  --color-charts-palette-categorical-34-209022:#a783e1;
  --color-charts-palette-categorical-35-twgdku:#692801;
  --color-charts-palette-categorical-36-g2mar5:#5978e3;
  --color-charts-palette-categorical-37-9deaq2:#b1325c;
  --color-charts-palette-categorical-38-5fg2jv:#1c8e81;
  --color-charts-palette-categorical-39-c8yeil:#7749bf;
  --color-charts-palette-categorical-40-vlagy9:#cc5f21;
  --color-charts-palette-categorical-41-o49tuv:#314fbf;
  --color-charts-palette-categorical-42-5irquw:#8b1b42;
  --color-charts-palette-categorical-43-8pxo9j:#06645a;
  --color-charts-palette-categorical-44-w3yd1t:#59309d;
  --color-charts-palette-categorical-45-32a8x6:#983c02;
  --color-charts-palette-categorical-46-4ikr9l:#23379b;
  --color-charts-palette-categorical-47-wg3s7g:#6f062f;
  --color-charts-palette-categorical-48-7no3ly:#014b44;
  --color-charts-palette-categorical-49-qy0tr5:#431d84;
  --color-charts-palette-categorical-50-gkixcb:#732c02;
  --color-background-button-link-active-t13dka:#d3e7f9;
  --color-background-button-link-hover-69sbpz:#f2f8fd;
  --color-background-button-normal-active-9lyks0:#d3e7f9;
  --color-background-button-normal-default-syw2p1:#ffffff;
  --color-background-button-normal-disabled-z5vd4i:#ffffff;
  --color-background-button-normal-hover-c3qbgm:#f2f8fd;
  --color-background-button-primary-active-lgpa1m:#007D7B;
  --color-background-button-primary-default-izs2ol:#0972D3;
  --color-background-button-primary-disabled-eon7km:#e9ebed;
  --color-background-button-primary-hover-crhizd:#033160;
  --color-background-calendar-today-762czq:#f4f4f4;
  --color-background-cell-shaded-26ubfj:#f8f8f8;
  --color-background-code-editor-gutter-active-line-default-p2p8kl:#5f6b7a;
  --color-background-code-editor-gutter-active-line-error-lfh0u4:#d91515;
  --color-background-code-editor-gutter-default-l5k65m:#f4f4f4;
  --color-background-code-editor-loading-4cqamf:#fbfbfb;
  --color-background-code-editor-pane-item-hover-lrigi6:#e9ebed;
  --color-background-code-editor-status-bar-qgcwj5:#f4f4f4;
  --color-background-container-content-iw8cer:#FFFFFF;
  --color-background-container-header-4flbq5:#ffffff;
  --color-background-control-checked-9admlu:#0972d3;
  --color-background-control-default-4pa05r:#ffffff;
  --color-background-control-disabled-zplipy:#d1d5db;
  --color-background-dropdown-item-default-fzdqsg:#ffffff;
  --color-background-dropdown-item-dimmed-qw48ma:transparent;
  --color-background-dropdown-item-filter-match-6ac2mw:#f2f8fd;
  --color-background-dropdown-item-hover-mqsxkv:#f4f4f4;
  --color-background-dropdown-item-selected-8l4mpk:#f2f8fd;
  --color-background-home-header-mpq08f:#000716;
  --color-background-input-default-f4ls0o:#FFFFFF;
  --color-background-input-disabled-wu9j6u:#e9ebed;
  --color-background-item-selected-ebt4bi:#f2f8fd;
  --color-background-layout-main-y57tka:#F8F9FA;
  --color-background-layout-mobile-panel-9vweqk:#000716;
  --color-background-layout-panel-content-libpfx:#FFFFFF;
  --color-background-layout-panel-hover-0bg3gq:#e9ebed;
  --color-background-layout-toggle-active-d15dki:#354150;
  --color-background-layout-toggle-default-f9hnkv:#192534;
  --color-background-layout-toggle-hover-gi0e0b:#354150;
  --color-background-layout-toggle-selected-active-9w7pk3:#0972d3;
  --color-background-layout-toggle-selected-default-e1giv3:#0972d3;
  --color-background-layout-toggle-selected-hover-mes33s:#065299;
  --color-background-modal-overlay-17dapf:rgba(35, 47, 62, 0.7);
  --color-background-notification-blue-vqb59v:#0972d3;
  --color-background-notification-green-0d9ow8:#037f0c;
  --color-background-notification-grey-ua4ep5:#414d5c;
  --color-background-notification-red-v7js1f:#d91515;
  --color-background-popover-mn5o9k:#ffffff;
  --color-background-progress-bar-content-default-r4tyyh:#0972d3;
  --color-background-progress-bar-content-in-flash-37g8fy:#ffffff;
  --color-background-progress-bar-layout-default-9xbpym:#e9ebed;
  --color-background-progress-bar-layout-in-flash-phmfak:rgba(255, 255, 255, 0.25);
  --color-background-segment-active-bl0u6r:#0972d3;
  --color-background-segment-default-5k37bj:#ffffff;
  --color-background-segment-disabled-hgka10:#ffffff;
  --color-background-segment-hover-s3f27a:#ffffff;
  --color-background-status-error-pe1hux:#fff7f7;
  --color-background-status-info-usb30e:#f2f8fd;
  --color-background-status-success-xnxxn4:#f2fcf3;
  --color-background-status-warning-zz4oi6:#ffffff;
  --color-background-table-header-i1pa1g:#ffffff;
  --color-background-tiles-disabled-byt5lx:#e9ebed;
  --color-background-toggle-checked-disabled-07lf06:#b5d6f4;
  --color-background-toggle-default-w4ftus:#414d5c;
  --color-border-button-normal-active-zbmaft:#033160;
  --color-border-button-normal-default-o2n9zb:#0972D3;
  --color-border-button-normal-disabled-262a0x:#9ba7b6;
  --color-border-button-normal-hover-hdfhkp:#033160;
  --color-border-button-primary-disabled-6gwabt:#e9ebed;
  --color-border-calendar-grid-sshunw:transparent;
  --color-border-calendar-grid-selected-focus-ring-cxqa0s:#fbfbfb;
  --color-border-code-editor-ace-active-line-light-theme-3l07nr:#d1d5db;
  --color-border-code-editor-ace-active-line-dark-theme-74siyt:#5f6b7a;
  --color-border-code-editor-default-l9vmjf:#d1d5db;
  --color-border-code-editor-pane-item-hover-xephrt:#7d8998;
  --color-border-container-divider-4ade7z:transparent;
  --color-border-container-top-srcvx4:transparent;
  --color-border-control-checked-4xvndc:#0972d3;
  --color-border-control-default-m3lmsh:#7d8998;
  --color-border-control-disabled-vx8bco:#d1d5db;
  --color-border-divider-active-nnnfx9:#7d8998;
  --color-border-divider-default-7s2wjw:#e9ebed;
  --color-border-divider-panel-bottom-zrjpck:#e9ebed;
  --color-border-divider-panel-side-yjkfcr:#e9ebed;
  --color-border-dropdown-container-tyn0js:#9ba7b6;
  --color-border-dropdown-group-0utpsr:#e9ebed;
  --color-border-dropdown-item-default-faosd8:#e9ebed;
  --color-border-dropdown-item-hover-tyzq9m:#7d8998;
  --color-border-dropdown-item-dimmed-hover-6dhy15:#7d8998;
  --color-border-dropdown-item-top-xnzq48:transparent;
  --color-border-input-default-e47bkv:#7d8998;
  --color-border-input-disabled-x2lopj:#e9ebed;
  --color-border-item-focused-ap3b6s:#0972d3;
  --color-border-item-placeholder-jgzhnb:#0972d3;
  --color-border-item-selected-ppkssz:#0972d3;
  --color-border-layout-wf5alz:#d1d5db;
  --color-border-popover-5pygk7:#9ba7b6;
  --color-border-segment-active-kdy5tm:#414d5c;
  --color-border-segment-default-091088:#414d5c;
  --color-border-segment-disabled-6v5zlt:#414d5c;
  --color-border-segment-hover-9notuw:#414d5c;
  --color-border-status-error-mfrddc:#d91515;
  --color-border-status-info-546i7i:#0972d3;
  --color-border-status-success-cqi45f:#037f0c;
  --color-border-status-warning-hg85ut:#7d8998;
  --color-border-divider-interactive-default-rcasvw:#e9ebed;
  --color-border-tabs-divider-69fx5i:#e9ebed;
  --color-border-tabs-shadow-4lmh2z:rgba(0, 7, 22, 0.12);
  --color-border-tabs-underline-688p25:#0972D3;
  --color-border-tiles-disabled-dp4h7y:#e9ebed;
  --color-border-tutorial-4ioksw:#d1d5db;
  --color-foreground-control-default-7ajdem:#ffffff;
  --color-foreground-control-disabled-v6a97u:#ffffff;
  --color-shadow-default-3se70f:rgba(0, 7, 22, 0.12);
  --color-text-accent-6sf3a3:#0972D3;
  --color-text-body-default-ajf1h5:#000716;
  --color-text-body-secondary-fdstdf:#414d5c;
  --color-text-breadcrumb-current-mum16s:#5f6b7a;
  --color-text-breadcrumb-icon-mlugp6:#7d8998;
  --color-text-button-inline-icon-default-0qb3op:#0972D3;
  --color-text-button-inline-icon-disabled-gfhvz7:#9ba7b6;
  --color-text-button-inline-icon-hover-0p4305:#033160;
  --color-text-button-normal-active-87sxdf:#007D7B;
  --color-text-button-normal-default-4k5svn:#0972D3;
  --color-text-button-normal-hover-7it1vu:#033160;
  --color-text-link-button-normal-default-2k67s3:#0972D3;
  --color-text-link-button-normal-hover-at835b:#033160;
  --color-text-link-button-normal-active-b25vvz:#007D7B;
  --color-text-button-primary-active-vems2x:#ffffff;
  --color-text-button-primary-default-340wh6:#ffffff;
  --color-text-button-primary-hover-vzqjpi:#ffffff;
  --color-text-calendar-day-hover-graajh:#000716;
  --color-text-calendar-month-elf7e9:#5f6b7a;
  --color-text-code-editor-gutter-active-line-3r48bk:#ffffff;
  --color-text-code-editor-gutter-default-q46j79:#000716;
  --color-text-code-editor-status-bar-disabled-6bhuub:#7d8998;
  --color-text-code-editor-tab-button-error-vez6ll:#ffffff;
  --color-text-column-header-u472sv:#414d5c;
  --color-text-column-sorting-icon-q5sr09:#414d5c;
  --color-text-control-disabled-3z3eul:#9ba7b6;
  --color-text-counter-jwdegc:#5f6b7a;
  --color-text-disabled-a2nkh4:#9ba7b6;
  --color-text-dropdown-footer-rj6jvf:#5f6b7a;
  --color-text-dropdown-group-label-eaqlcl:#414d5c;
  --color-text-dropdown-header-6e05nm:#000716;
  --color-text-dropdown-item-default-7tg9p2:#000716;
  --color-text-dropdown-item-dimmed-jyqdrs:#9ba7b6;
  --color-text-dropdown-item-disabled-varol7:#9ba7b6;
  --color-text-dropdown-item-filter-match-x302if:#0972d3;
  --color-text-dropdown-item-highlighted-oaabyk:#000716;
  --color-text-dropdown-item-secondary-0me7js:#5f6b7a;
  --color-text-dropdown-item-secondary-hover-7ii1ru:#5f6b7a;
  --color-text-empty-fjv325:#5f6b7a;
  --color-text-expandable-section-default-k03p1p:#000716;
  --color-text-expandable-section-hover-oo7tqa:#0972D3;
  --color-text-expandable-section-navigation-icon-default-lb9rm0:#414d5c;
  --color-text-form-default-yd29ox:#000716;
  --color-text-form-label-k3j0b4:#000716;
  --color-text-form-secondary-ih9x7l:#5f6b7a;
  --color-text-group-label-w9to2i:#414d5c;
  --color-text-heading-default-jopz0f:#000716;
  --color-text-heading-secondary-corkaj:#414d5c;
  --color-text-home-header-default-1d71y3:#ffffff;
  --color-text-home-header-secondary-qfyemi:#d1d5db;
  --color-text-icon-subtle-bz5b7h:#5f6b7a;
  --color-text-input-disabled-oawyzo:#9ba7b6;
  --color-text-input-placeholder-j53j3c:#5f6b7a;
  --color-text-input-placeholder-disabled-6928j8:#9ba7b6;
  --color-text-interactive-active-vol84d:#000716;
  --color-text-interactive-default-eg5fsa:#414d5c;
  --color-text-interactive-disabled-3pbb07:#9ba7b6;
  --color-text-interactive-hover-v3lasm:#000716;
  --color-text-interactive-inverted-default-phki5u:#d1d5db;
  --color-text-interactive-inverted-hover-g2obc6:#fbfbfb;
  --color-text-inverted-821sko:#ffffff;
  --color-text-label-gxskdy:#000716;
  --color-text-layout-toggle-20sb9r:#ffffff;
  --color-text-layout-toggle-selected-tzwifo:#ffffff;
  --color-text-link-default-dkndw9:#0972D3;
  --color-text-link-hover-arwnci:#033160;
  --color-text-link-inverted-hover-5tnsdi:#ffffff;
  --color-text-link-button-underline-8mp92t:transparent;
  --color-text-link-button-underline-hover-ei09le:transparent;
  --color-text-link-primary-underline-3sp2rc:#0972D3;
  --color-text-notification-default-2kpcxy:#fbfbfb;
  --color-text-pagination-page-number-active-disabled-sjs1t5:#9ba7b6;
  --color-text-pagination-page-number-default-z6gnaa:#414d5c;
  --color-text-segment-active-gc2jjl:#ffffff;
  --color-text-segment-default-bf7rka:#414d5c;
  --color-text-segment-hover-5pccvg:#0972d3;
  --color-text-small-le1y42:#5f6b7a;
  --color-text-status-error-5sesl6:#d91515;
  --color-text-status-inactive-5ei55p:#5f6b7a;
  --color-text-status-info-utupcz:#0972d3;
  --color-text-status-success-0fzhv9:#037f0c;
  --color-text-status-warning-27nlyh:#d91515;
  --color-text-top-navigation-title-oypxe3:#000716;
  --color-board-placeholder-active-vaxzdf:#e9ebed;
  --color-board-placeholder-hover-v1s7kq:#d3e7f9;
  --font-body-m-line-height-i7xxvv:22px;
  --font-body-m-size-sregvd:14px;
  --font-body-s-letter-spacing-cy0oxj:0.005em;
  --font-body-s-line-height-kdsbrl:16px;
  --font-body-s-size-ukw2p9:12px;
  --font-button-letter-spacing-8y5poz:0.005em;
  --font-button-weight-7rj5fx:800;
  --font-chart-detail-size-pm0amk:12px;
  --font-display-l-letter-spacing-4ff418:-0.03em;
  --font-display-l-line-height-71tnj3:54px;
  --font-display-l-size-03lgcb:48px;
  --font-display-label-weight-m18hjh:700;
  --font-expandable-heading-size-m2wptt:16px;
  --font-family-base-kl18mf:Open Sans;
  --font-family-monospace-iz223z:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  --font-header-h2-description-line-height-gjrb86:22px;
  --font-header-h2-description-size-pqrrui:14px;
  --font-heading-l-letter-spacing-f6wvtk:-0.015em;
  --font-heading-l-line-height-gev4n1:30px;
  --font-heading-l-size-otmxys:24px;
  --font-heading-l-weight-xm7ks9:800;
  --font-heading-m-letter-spacing-r00pg3:-0.010em;
  --font-heading-m-line-height-u3t54f:24px;
  --font-heading-m-size-6e3jmg:20px;
  --font-heading-m-weight-hi5n0j:800;
  --font-heading-s-letter-spacing-4d69jc:-0.005em;
  --font-heading-s-line-height-5b7f0q:20px;
  --font-heading-s-size-9clg8y:16px;
  --font-heading-s-weight-k8ys41:800;
  --font-heading-xl-letter-spacing-9tcsmy:-0.02em;
  --font-heading-xl-line-height-doxfdj:40px;
  --font-heading-xl-size-d0vjbk:32px;
  --font-heading-xl-weight-772870:800;
  --font-heading-xs-line-height-5x131j:18px;
  --font-heading-xs-size-fryxro:14px;
  --font-heading-xs-weight-wwznty:800;
  --font-box-value-large-weight-dyt1pj:800;
  --font-link-button-letter-spacing-58wset:0.005em;
  --font-link-button-weight-4f1hjr:800;
  --font-link-primary-decoration-wfgjh2:underline;
  --font-link-primary-letter-spacing-vmsgcx:"inherit";
  --font-link-primary-weight-91pybi:"inherit";
  --font-panel-header-line-height-x2226j:24px;
  --font-panel-header-size-0vdgjc:20px;
  --font-smoothing-webkit-vu3hx4:antialiased;
  --font-smoothing-moz-osx-nkh1jb:grayscale;
  --font-tabs-disabled-weight-imwq16:800;
  --font-tabs-line-height-qo8cr6:20px;
  --font-tabs-size-01zslx:16px;
  --font-wayfinding-link-active-weight-l3fid7:800;
  --font-weight-heavy-ydka9r:800;
  --border-active-width-ce5ls7:4px;
  --border-code-editor-status-divider-width-cf5jzt:2px;
  --border-container-sticky-width-i7t7xe:0px;
  --border-container-top-width-jk6fhm:0px;
  --border-control-focus-ring-shadow-spread-srzyiu:0px;
  --border-control-invalid-focus-ring-shadow-spread-omt6il:2px;
  --border-divider-list-width-hacikr:1px;
  --border-divider-section-width-4wm2it:2px;
  --border-dropdown-virtual-offset-width-fzhepk:2px;
  --border-field-width-idlekx:2px;
  --border-invalid-width-5jpdqn:8px;
  --border-item-width-qbbbsa:2px;
  --border-line-chart-dash-array-4wa4ab:3 5;
  --border-line-chart-line-join-nan7pn:round;
  --border-line-chart-width-nj0hc5:2px;
  --border-panel-header-width-6fmoo4:2px;
  --border-panel-top-width-00mwcl:1px;
  --border-radius-alert-n0skwy:12px;
  --border-radius-badge-x5oehc:4px;
  --border-radius-button-axewzv:20px;
  --border-radius-calendar-day-focus-ring-a8mlqd:3px;
  --border-radius-code-editor-fuck6b:8px;
  --border-radius-container-gh9ysk:16px;
  --border-radius-control-circular-focus-ring-qawzn7:4px;
  --border-radius-control-default-focus-ring-7661kz:4px;
  --border-radius-dropdown-ujknaf:8px;
  --border-radius-flashbar-i7jm55:12px;
  --border-radius-item-u2ibpi:8px;
  --border-radius-input-txq8zy:8px;
  --border-radius-popover-8g1bwn:8px;
  --border-radius-tabs-focus-ring-j968on:20px;
  --border-radius-tiles-kl8mtb:8px;
  --border-radius-token-wohc9e:8px;
  --border-radius-tutorial-panel-item-5wuccf:8px;
  --border-table-sticky-width-h1tlxy:2px;
  --border-link-focus-ring-outline-k2wccv:0;
  --border-link-focus-ring-shadow-spread-nv4ahb:2px;
  --motion-duration-extra-slow-8ks5rs:270ms;
  --motion-duration-fast-nk2ap0:90ms;
  --motion-duration-moderate-vgx1te:135ms;
  --motion-duration-refresh-only-fast-addxjd:115ms;
  --motion-duration-refresh-only-medium-5flen2:165ms;
  --motion-duration-refresh-only-slow-6gdedh:250ms;
  --motion-duration-rotate-180-ofa1ir:135ms;
  --motion-duration-rotate-90-ux18sr:135ms;
  --motion-duration-show-paced-7wspqf:180ms;
  --motion-duration-show-quick-tyaalm:135ms;
  --motion-duration-slow-0273ow:180ms;
  --motion-duration-transition-quick-x85tae:90ms;
  --motion-duration-transition-show-paced-ai4wlf:180ms;
  --motion-easing-ease-out-quart-z687ml:cubic-bezier(0.165, 0.84, 0.44, 1);
  --motion-easing-refresh-only-a-sy0g8m:cubic-bezier(0, 0, 0, 1);
  --motion-easing-refresh-only-b-nspng9:cubic-bezier(1, 0, 0.83, 1);
  --motion-easing-refresh-only-c-ic6jr0:cubic-bezier(0.84, 0, 0.16, 1);
  --motion-easing-rotate-180-3rbbga:cubic-bezier(0.165, 0.84, 0.44, 1);
  --motion-easing-rotate-90-eoew89:cubic-bezier(0.165, 0.84, 0.44, 1);
  --motion-easing-show-paced-wj4ybn:ease-out;
  --motion-easing-show-quick-1fcgbv:ease-out;
  --motion-easing-transition-quick-lukbd8:linear;
  --motion-easing-transition-show-paced-bg1hyg:ease-out;
  --motion-easing-responsive-5yj747:cubic-bezier(0, 0, 0, 1);
  --motion-easing-sticky-yt83ho:cubic-bezier(1, 0, 0.83, 1);
  --motion-easing-expressive-zns6oe:cubic-bezier(0.84, 0, 0.16, 1);
  --motion-duration-responsive-dvittz:115ms;
  --motion-duration-expressive-96gzp7:165ms;
  --motion-duration-complex-8zbvzy:250ms;
  --motion-keyframes-fade-in-9ud20b:awsui-fade-in-35003c;
  --motion-keyframes-fade-out-1ayoyt:awsui-fade-out-35003c;
  --motion-keyframes-status-icon-error-ti988k:awsui-status-icon-error-35003c;
  --motion-keyframes-scale-popup-3oea16:awsui-scale-popup-35003c;
  --size-calendar-grid-width-lxsx0p:238px;
  --size-control-n9i4yo:16px;
  --size-icon-big-cu55wt:32px;
  --size-icon-large-9vh494:48px;
  --size-icon-medium-fvz0oi:20px;
  --size-icon-normal-j1z5fn:16px;
  --size-table-selection-horizontal-o8c8gs:40px;
  --size-vertical-input-js5hfh:34px;
  --size-vertical-panel-icon-offset-ti40n4:15px;
  --space-alert-action-left-hi50rw:12px;
  --space-alert-horizontal-ulbnu9:16px;
  --space-alert-message-right-qop1p1:4px;
  --space-alert-vertical-jbva6z:8px;
  --space-button-focus-outline-gutter-4vaedu:4px;
  --space-button-icon-focus-outline-gutter-vertical-ysj0na:0px;
  --space-button-icon-only-horizontal-nidslr:7px;
  --space-button-inline-icon-focus-outline-gutter-f9qjev:0px;
  --space-button-modal-dismiss-vertical-r4lx2k:0px;
  --space-calendar-grid-focus-outline-gutter-v3dwvl:-5px;
  --space-calendar-grid-selected-focus-outline-gutter-gqgjqz:-5px;
  --space-card-horizontal-di7llb:20px;
  --space-code-editor-status-focus-outline-gutter-eivtla:-7px;
  --space-container-content-top-fsd8nr:0px;
  --space-container-header-vertical-zsjhel:12px;
  --space-container-horizontal-tlw03i:20px;
  --space-content-header-padding-bottom-nvqytg:24px;
  --space-dark-header-overlap-distance-hfejul:36px;
  --space-expandable-section-icon-offset-top-svbirl:4px;
  --space-field-horizontal-89h2yr:12px;
  --space-field-icon-offset-25xih0:36px;
  --space-filtering-token-dismiss-button-focus-outline-gutter-itb5fd:-5px;
  --space-filtering-token-operation-select-focus-outline-gutter-awvnzl:-5px;
  --space-flashbar-action-left-y2z8zt:12px;
  --space-flashbar-dismiss-right-cbaywa:0px;
  --space-flashbar-horizontal-ouyk4r:16px;
  --space-grid-gutter-0subky:20px;
  --space-layout-content-bottom-kg9l42:40px;
  --space-layout-toggle-diameter-hzvqtj:40px;
  --space-layout-toggle-padding-axl9jr:20px;
  --space-modal-content-bottom-cecclv:16px;
  --space-modal-horizontal-ah0xld:20px;
  --space-panel-nav-left-o5fvw3:28px;
  --space-panel-side-left-8ozqve:28px;
  --space-panel-side-right-kev4k9:24px;
  --space-panel-split-top-0y4u4f:20px;
  --space-segmented-control-focus-outline-gutter-99x5b9:4px;
  --space-table-header-focus-outline-gutter-rbx7m7:0px;
  --space-tabs-content-top-mn4c9x:12px;
  --space-table-horizontal-mj7h0h:20px;
  --space-table-header-horizontal-et2ko8:0px;
  --space-table-content-bottom-pg6yvn:4px;
  --space-table-content-top-t15dd1:12px;
  --space-table-embedded-content-bottom-7vc5vt:0px;
  --space-table-embedded-header-top-zf07fk:0px;
  --space-table-footer-horizontal-8o6sk8:0px;
  --space-tabs-focus-outline-gutter-64zu5e:-8px;
  --space-text-grid-vertical-tj8m1c:16px;
  --space-scaled-2x-xxs-t5qzmf:4px;
  --space-scaled-2x-s-9svxjy:12px;
  --space-scaled-2x-l-yejodm:20px;
  --space-scaled-2x-xl-x30c9w:24px;
  --space-scaled-2x-xxxl-lbp35j:40px;
  --space-scaled-none-cnv7sd:0;
  --space-scaled-xxxs-b6dm8t:2px;
  --space-scaled-xxs-95dhkm:4px;
  --space-scaled-xs-6859qs:8px;
  --space-scaled-s-cu1hzn:12px;
  --space-scaled-m-pv0fmt:16px;
  --space-scaled-l-t03y3z:20px;
  --space-scaled-xl-w2t504:24px;
  --space-scaled-xxl-pfgv96:32px;
  --space-scaled-xxxl-ffokcj:40px;
  --space-static-xxxs-k3qmdh:2px;
  --space-static-xxs-jy4jgd:4px;
  --space-static-xs-9adq92:8px;
  --space-static-s-phfs7n:12px;
  --space-static-m-pc0uz7:16px;
  --space-static-l-t7yjdj:20px;
  --space-static-xl-g2lpmk:24px;
  --space-static-xxl-m6qwv3:32px;
  --space-static-xxxl-66ty0h:40px;
  --space-none-esmv0r:0;
  --space-xxxs-k2w98v:2px;
  --space-xxs-ynfts5:4px;
  --space-xs-rsr2qu:8px;
  --space-s-hv8c1d:12px;
  --space-m-17eucw:16px;
  --space-l-4vl6xu:20px;
  --space-xl-a39hup:24px;
  --space-xxl-2nvmf1:32px;
  --space-xxxl-bo9z7f:40px;
  --shadow-container-fx563k:0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12);
  --shadow-container-stacked-jfo2nn:-1px 1px 1px 0px #e9ebed, 1px 1px 1px 0px #e9ebed, 0px 9px 8px -7px rgb(0 7 22 / 12%), 8px 0px 8px -7px rgb(0 7 22 / 12%), -8px 0px 8px -7px rgb(0 7 22 / 12%);
  --shadow-container-active-l4kuxc:0px 1px 1px 1px #e9ebed, 0px 6px 36px #0007161a;
  --shadow-dropdown-99h0gz:0px 4px 20px 1px rgba(0, 7, 22, 0.10);
  --shadow-dropup-11esug:0px 4px 20px 1px rgba(0, 7, 22, 0.10);
  --shadow-flash-collapsed-d4s4kz:0px 4px 4px rgba(0, 0, 0, 0.25);
  --shadow-flash-sticky-xplpiu:0px 6px 36px rgba(0, 7, 22, 0.10);
  --shadow-modal-3qhlqt:0px 4px 20px 1px rgba(0, 7, 22, 0.10);
  --shadow-panel-gp3bf2:0px 1px 1px 1px #e9ebed, 0px 6px 36px rgba(0, 7, 22, 0.10);
  --shadow-panel-toggle-3fk0ch:0px 6px 12px 1px rgba(0, 7, 22, 0.12);
  --shadow-popover-kzy6rd:0px 4px 20px 1px rgba(0, 7, 22, 0.10);
  --shadow-split-bottom-otfetg:0px -36px 36px -36px rgba(0, 7, 22, 0.10);
  --shadow-split-side-qv353u:-1px 0px 1px 0px #e9ebed, -36px 6px 36px -36px rgba(0, 7, 22, 0.10);
  --shadow-sticky-aijlo6:0px 4px 20px 1px rgba(0, 7, 22, 0.10);
  --shadow-sticky-embedded-qit8ba:0px 2px 0px 0px #e9ebed, 0px 16px 16px -12px rgba(0, 7, 22, 0.10);
}

.awsui-dark-mode:not(#\9) {
  --color-charts-red-300-taex3d:#d63f38;
  --color-charts-red-400-xo1w45:#ed5958;
  --color-charts-red-500-8et8sv:#fe6e73;
  --color-charts-red-600-ej8iox:#ff8a8a;
  --color-charts-red-700-pe7xt7:#ffa09e;
  --color-charts-red-800-9wqz57:#ffb3b0;
  --color-charts-red-900-fkiox3:#ffc4c0;
  --color-charts-red-1000-xhb2ma:#ffd2cf;
  --color-charts-red-1100-zkhjl8:#ffe0dd;
  --color-charts-red-1200-t42yl7:#ffecea;
  --color-charts-orange-300-uh1x0z:#c55305;
  --color-charts-orange-400-rg9ft0:#de6923;
  --color-charts-orange-500-6dcz8x:#f27c36;
  --color-charts-orange-600-psg5i7:#f89256;
  --color-charts-orange-700-6rlkwo:#fca572;
  --color-charts-orange-800-aqh9u5:#ffb68b;
  --color-charts-orange-900-71d364:#ffc6a4;
  --color-charts-orange-1000-p3xy9d:#ffd4bb;
  --color-charts-orange-1100-plp0ix:#ffe1cf;
  --color-charts-orange-1200-msf9v8:#ffede2;
  --color-charts-yellow-300-zgwohc:#977001;
  --color-charts-yellow-400-z6s56e:#b08400;
  --color-charts-yellow-500-f52bpo:#c59600;
  --color-charts-yellow-600-gzedvz:#d3a61c;
  --color-charts-yellow-700-uq1jq9:#dfb52c;
  --color-charts-yellow-800-esbvam:#eac33a;
  --color-charts-yellow-900-e2ugyo:#f1cf65;
  --color-charts-yellow-1000-myf1mq:#f7db8a;
  --color-charts-yellow-1100-fi5c3g:#fce5a8;
  --color-charts-yellow-1200-faeu24:#ffefc9;
  --color-charts-green-300-8q7o65:#48851a;
  --color-charts-green-400-b5efwo:#5a9b29;
  --color-charts-green-500-o7urau:#69ae34;
  --color-charts-green-600-8e3las:#7dbd4c;
  --color-charts-green-700-h4agmh:#8fca61;
  --color-charts-green-800-jp1xwf:#9fd673;
  --color-charts-green-900-czmqk3:#b2df8d;
  --color-charts-green-1000-wrs7fm:#c5e7a8;
  --color-charts-green-1100-d8mgjl:#d5efbe;
  --color-charts-green-1200-jyfc23:#e4f7d5;
  --color-charts-teal-300-egfaot:#018977;
  --color-charts-teal-400-ikctdb:#009d89;
  --color-charts-teal-500-v952sw:#00b09b;
  --color-charts-teal-600-9ffc3q:#40bfa9;
  --color-charts-teal-700-nn7a5u:#5fccb7;
  --color-charts-teal-800-97l4n7:#77d7c3;
  --color-charts-teal-900-zq1u0w:#94e0d0;
  --color-charts-teal-1000-u4e4vv:#ace9db;
  --color-charts-teal-1100-cdt1d8:#c2f0e6;
  --color-charts-teal-1200-3ozaok:#d7f7f0;
  --color-charts-blue-1-300-4x8rnx:#00819c;
  --color-charts-blue-1-400-owg3dt:#0497ba;
  --color-charts-blue-1-500-sl8chl:#08aad2;
  --color-charts-blue-1-600-3824a7:#44b9dd;
  --color-charts-blue-1-700-e3d0uw:#63c6e7;
  --color-charts-blue-1-800-vnypf9:#79d2f0;
  --color-charts-blue-1-900-rs4k5z:#98dcf5;
  --color-charts-blue-1-1000-wjr7hn:#b3e4f8;
  --color-charts-blue-1-1100-nmn22x:#caedfc;
  --color-charts-blue-1-1200-od4mlj:#ddf4ff;
  --color-charts-blue-2-300-6yvfip:#486de8;
  --color-charts-blue-2-400-iba8cu:#6384f5;
  --color-charts-blue-2-500-vtdvkt:#7698fe;
  --color-charts-blue-2-600-tpo4rj:#8ea9ff;
  --color-charts-blue-2-700-p6tu56:#a2b8ff;
  --color-charts-blue-2-800-08f3pp:#b1c5ff;
  --color-charts-blue-2-900-3aq93e:#c3d1ff;
  --color-charts-blue-2-1000-6b05hm:#d2dcff;
  --color-charts-blue-2-1100-0014sa:#dfe6ff;
  --color-charts-blue-2-1200-tzr398:#ecf0ff;
  --color-charts-purple-300-yatxj8:#8d59de;
  --color-charts-purple-400-d384pt:#a173ea;
  --color-charts-purple-500-skntta:#b088f5;
  --color-charts-purple-600-8iqvzu:#bf9bf9;
  --color-charts-purple-700-3qzy9s:#cbabfc;
  --color-charts-purple-800-ucyxda:#d6baff;
  --color-charts-purple-900-tlepdb:#dfc8ff;
  --color-charts-purple-1000-3pjp6l:#e8d5ff;
  --color-charts-purple-1100-t0m9qa:#efe2ff;
  --color-charts-purple-1200-003tqq:#f5edff;
  --color-charts-pink-300-0gug9u:#c64a70;
  --color-charts-pink-400-jh84a9:#d56889;
  --color-charts-pink-500-c97yed:#e07f9d;
  --color-charts-pink-600-rpo3pi:#eb92ad;
  --color-charts-pink-700-mshe2z:#f5a2bb;
  --color-charts-pink-800-bph464:#ffb0c8;
  --color-charts-pink-900-mvfm0y:#ffc1d4;
  --color-charts-pink-1000-kq8pu0:#ffd1de;
  --color-charts-pink-1100-pi0m3u:#ffdfe8;
  --color-charts-pink-1200-hukz4p:#ffecf1;
  --color-charts-status-critical-268let:#d63f38;
  --color-charts-status-high-vsd25n:#fe6e73;
  --color-charts-status-medium-371m8g:#f89256;
  --color-charts-status-low-lfwc29:#dfb52c;
  --color-charts-status-positive-2ozsqj:#69ae34;
  --color-charts-status-info-l8g84h:#08aad2;
  --color-charts-threshold-negative-y7lrhf:#eb6f6f;
  --color-charts-threshold-positive-42ybvt:#29ad32;
  --color-charts-threshold-info-kcjduw:#89bdee;
  --color-charts-threshold-neutral-k14cuc:#8d99a8;
  --color-charts-line-grid-6wil4m:#414d5c;
  --color-charts-line-tick-yidpvu:#414d5c;
  --color-charts-line-axis-yo865k:#414d5c;
  --color-charts-palette-categorical-1-bonb16:#486de8;
  --color-charts-palette-categorical-2-emtquh:#e07f9d;
  --color-charts-palette-categorical-3-imrqvq:#018977;
  --color-charts-palette-categorical-4-g31h69:#b088f5;
  --color-charts-palette-categorical-5-64f3p2:#c55305;
  --color-charts-palette-categorical-6-msr86m:#8ea9ff;
  --color-charts-palette-categorical-7-kdjms7:#ffb0c8;
  --color-charts-palette-categorical-8-79776a:#40bfa9;
  --color-charts-palette-categorical-9-s4w3dd:#d6baff;
  --color-charts-palette-categorical-10-cuicpx:#f89256;
  --color-charts-palette-categorical-11-no2uu5:#c3d1ff;
  --color-charts-palette-categorical-12-jq9rcw:#ffdfe8;
  --color-charts-palette-categorical-13-4cawsz:#94e0d0;
  --color-charts-palette-categorical-14-i1n5cl:#efe2ff;
  --color-charts-palette-categorical-15-9pgabq:#ffc6a4;
  --color-charts-palette-categorical-16-wnvn1q:#ecf0ff;
  --color-charts-palette-categorical-17-y2duhb:#d56889;
  --color-charts-palette-categorical-18-xehfp2:#d7f7f0;
  --color-charts-palette-categorical-19-v1x5n3:#a173ea;
  --color-charts-palette-categorical-20-q5g6qv:#ffede2;
  --color-charts-palette-categorical-21-z2fito:#7698fe;
  --color-charts-palette-categorical-22-mdbc68:#f5a2bb;
  --color-charts-palette-categorical-23-9iyat0:#00b09b;
  --color-charts-palette-categorical-24-17kvjc:#cbabfc;
  --color-charts-palette-categorical-25-c2vakt:#f27c36;
  --color-charts-palette-categorical-26-g98tv5:#b1c5ff;
  --color-charts-palette-categorical-27-lo8vzl:#ffd1de;
  --color-charts-palette-categorical-28-2ppjnc:#77d7c3;
  --color-charts-palette-categorical-29-68gzom:#e8d5ff;
  --color-charts-palette-categorical-30-9pbejo:#ffb68b;
  --color-charts-palette-categorical-31-8odgjs:#dfe6ff;
  --color-charts-palette-categorical-32-xug28a:#c64a70;
  --color-charts-palette-categorical-33-cr6l8a:#c2f0e6;
  --color-charts-palette-categorical-34-209022:#8d59de;
  --color-charts-palette-categorical-35-twgdku:#ffe1cf;
  --color-charts-palette-categorical-36-g2mar5:#6384f5;
  --color-charts-palette-categorical-37-9deaq2:#eb92ad;
  --color-charts-palette-categorical-38-5fg2jv:#009d89;
  --color-charts-palette-categorical-39-c8yeil:#bf9bf9;
  --color-charts-palette-categorical-40-vlagy9:#de6923;
  --color-charts-palette-categorical-41-o49tuv:#a2b8ff;
  --color-charts-palette-categorical-42-5irquw:#ffc1d4;
  --color-charts-palette-categorical-43-8pxo9j:#5fccb7;
  --color-charts-palette-categorical-44-w3yd1t:#dfc8ff;
  --color-charts-palette-categorical-45-32a8x6:#fca572;
  --color-charts-palette-categorical-46-4ikr9l:#d2dcff;
  --color-charts-palette-categorical-47-wg3s7g:#ffecf1;
  --color-charts-palette-categorical-48-7no3ly:#ace9db;
  --color-charts-palette-categorical-49-qy0tr5:#f5edff;
  --color-charts-palette-categorical-50-gkixcb:#ffd4bb;
  --color-background-button-link-active-t13dka:#354150;
  --color-background-button-link-hover-69sbpz:#192534;
  --color-background-button-normal-active-9lyks0:#354150;
  --color-background-button-normal-default-syw2p1:#0f1b2a;
  --color-background-button-normal-disabled-z5vd4i:#0f1b2a;
  --color-background-button-normal-hover-c3qbgm:#192534;
  --color-background-button-primary-disabled-eon7km:#354150;
  --color-background-calendar-today-762czq:#354150;
  --color-background-cell-shaded-26ubfj:#232f3e;
  --color-background-code-editor-gutter-active-line-default-p2p8kl:#7d8998;
  --color-background-code-editor-gutter-active-line-error-lfh0u4:#eb6f6f;
  --color-background-code-editor-gutter-default-l5k65m:#192534;
  --color-background-code-editor-loading-4cqamf:#192534;
  --color-background-code-editor-pane-item-hover-lrigi6:#354150;
  --color-background-code-editor-status-bar-qgcwj5:#192534;
  --color-background-container-header-4flbq5:#0f1b2a;
  --color-background-control-checked-9admlu:#539fe5;
  --color-background-control-default-4pa05r:#0f1b2a;
  --color-background-control-disabled-zplipy:#354150;
  --color-background-dropdown-item-default-fzdqsg:#192534;
  --color-background-dropdown-item-filter-match-6ac2mw:#354150;
  --color-background-dropdown-item-hover-mqsxkv:#354150;
  --color-background-dropdown-item-selected-8l4mpk:#192534;
  --color-background-input-disabled-wu9j6u:#192534;
  --color-background-item-selected-ebt4bi:#192534;
  --color-background-layout-panel-hover-0bg3gq:#354150;
  --color-background-layout-toggle-selected-active-9w7pk3:#539fe5;
  --color-background-layout-toggle-selected-default-e1giv3:#539fe5;
  --color-background-layout-toggle-selected-hover-mes33s:#89bdee;
  --color-background-modal-overlay-17dapf:rgba(0, 7, 22, 0.7);
  --color-background-notification-grey-ua4ep5:#5f6b7a;
  --color-background-popover-mn5o9k:#192534;
  --color-background-progress-bar-content-default-r4tyyh:#539fe5;
  --color-background-progress-bar-layout-default-9xbpym:#354150;
  --color-background-segment-active-bl0u6r:#539fe5;
  --color-background-segment-default-5k37bj:#0f1b2a;
  --color-background-segment-disabled-hgka10:#0f1b2a;
  --color-background-segment-hover-s3f27a:#0f1b2a;
  --color-background-status-error-pe1hux:#1a0000;
  --color-background-status-info-usb30e:#00142b;
  --color-background-status-success-xnxxn4:#001a02;
  --color-background-status-warning-zz4oi6:#0f1b2a;
  --color-background-table-header-i1pa1g:#0f1b2a;
  --color-background-tiles-disabled-byt5lx:#192534;
  --color-background-toggle-checked-disabled-07lf06:#033160;
  --color-background-toggle-default-w4ftus:#7d8998;
  --color-border-button-normal-active-zbmaft:#89bdee;
  --color-border-button-normal-disabled-262a0x:#5f6b7a;
  --color-border-button-primary-disabled-6gwabt:#354150;
  --color-border-calendar-grid-selected-focus-ring-cxqa0s:#0f1b2a;
  --color-border-code-editor-default-l9vmjf:#5f6b7a;
  --color-border-code-editor-pane-item-hover-xephrt:#5f6b7a;
  --color-border-control-checked-4xvndc:#539fe5;
  --color-border-control-disabled-vx8bco:#354150;
  --color-border-divider-default-7s2wjw:#414d5c;
  --color-border-divider-panel-bottom-zrjpck:rgba(0, 7, 22, 1);
  --color-border-divider-panel-side-yjkfcr:#414d5c;
  --color-border-dropdown-container-tyn0js:#5f6b7a;
  --color-border-dropdown-group-0utpsr:#414d5c;
  --color-border-dropdown-item-default-faosd8:#414d5c;
  --color-border-dropdown-item-hover-tyzq9m:#5f6b7a;
  --color-border-input-default-e47bkv:#5f6b7a;
  --color-border-input-disabled-x2lopj:#192534;
  --color-border-item-focused-ap3b6s:#539fe5;
  --color-border-item-placeholder-jgzhnb:#539fe5;
  --color-border-item-selected-ppkssz:#539fe5;
  --color-border-layout-wf5alz:#354150;
  --color-border-popover-5pygk7:#5f6b7a;
  --color-border-segment-active-kdy5tm:#d1d5db;
  --color-border-segment-default-091088:#d1d5db;
  --color-border-segment-disabled-6v5zlt:#d1d5db;
  --color-border-segment-hover-9notuw:#d1d5db;
  --color-border-status-error-mfrddc:#eb6f6f;
  --color-border-status-info-546i7i:#539fe5;
  --color-border-status-success-cqi45f:#29ad32;
  --color-border-status-warning-hg85ut:#8d99a8;
  --color-border-divider-interactive-default-rcasvw:#414d5c;
  --color-border-tabs-divider-69fx5i:#414d5c;
  --color-border-tabs-shadow-4lmh2z:rgba(0, 7, 22, 1);
  --color-border-tiles-disabled-dp4h7y:#192534;
  --color-border-tutorial-4ioksw:#414d5c;
  --color-foreground-control-default-7ajdem:#000716;
  --color-foreground-control-disabled-v6a97u:#0f1b2a;
  --color-shadow-default-3se70f:rgba(0, 7, 22, 1);
  --color-text-body-default-ajf1h5:#d1d5db;
  --color-text-body-secondary-fdstdf:#d1d5db;
  --color-text-breadcrumb-current-mum16s:#7d8998;
  --color-text-breadcrumb-icon-mlugp6:#5f6b7a;
  --color-text-button-inline-icon-disabled-gfhvz7:#5f6b7a;
  --color-text-button-primary-active-vems2x:#000716;
  --color-text-button-primary-default-340wh6:#000716;
  --color-text-button-primary-hover-vzqjpi:#000716;
  --color-text-calendar-day-hover-graajh:#d1d5db;
  --color-text-calendar-month-elf7e9:#8d99a8;
  --color-text-code-editor-gutter-active-line-3r48bk:#000716;
  --color-text-code-editor-gutter-default-q46j79:#d1d5db;
  --color-text-code-editor-status-bar-disabled-6bhuub:#5f6b7a;
  --color-text-code-editor-tab-button-error-vez6ll:#000716;
  --color-text-column-header-u472sv:#9ba7b6;
  --color-text-column-sorting-icon-q5sr09:#9ba7b6;
  --color-text-control-disabled-3z3eul:#5f6b7a;
  --color-text-counter-jwdegc:#8d99a8;
  --color-text-disabled-a2nkh4:#5f6b7a;
  --color-text-dropdown-footer-rj6jvf:#8d99a8;
  --color-text-dropdown-group-label-eaqlcl:#8d99a8;
  --color-text-dropdown-header-6e05nm:#fbfbfb;
  --color-text-dropdown-item-default-7tg9p2:#d1d5db;
  --color-text-dropdown-item-dimmed-jyqdrs:#5f6b7a;
  --color-text-dropdown-item-disabled-varol7:#5f6b7a;
  --color-text-dropdown-item-filter-match-x302if:#89bdee;
  --color-text-dropdown-item-highlighted-oaabyk:#e9ebed;
  --color-text-dropdown-item-secondary-0me7js:#8d99a8;
  --color-text-dropdown-item-secondary-hover-7ii1ru:colorGrey300;
  --color-text-empty-fjv325:#d1d5db;
  --color-text-expandable-section-default-k03p1p:#fbfbfb;
  --color-text-expandable-section-navigation-icon-default-lb9rm0:#d1d5db;
  --color-text-form-default-yd29ox:#d1d5db;
  --color-text-form-label-k3j0b4:#d1d5db;
  --color-text-form-secondary-ih9x7l:#8d99a8;
  --color-text-group-label-w9to2i:#8d99a8;
  --color-text-heading-default-jopz0f:#e9ebed;
  --color-text-heading-secondary-corkaj:#8d99a8;
  --color-text-icon-subtle-bz5b7h:#9ba7b6;
  --color-text-input-disabled-oawyzo:#5f6b7a;
  --color-text-input-placeholder-j53j3c:#7d8998;
  --color-text-input-placeholder-disabled-6928j8:#5f6b7a;
  --color-text-interactive-active-vol84d:#fbfbfb;
  --color-text-interactive-default-eg5fsa:#d1d5db;
  --color-text-interactive-disabled-3pbb07:#5f6b7a;
  --color-text-interactive-hover-v3lasm:#fbfbfb;
  --color-text-inverted-821sko:#000716;
  --color-text-label-gxskdy:#d1d5db;
  --color-text-layout-toggle-selected-tzwifo:#000716;
  --color-text-pagination-page-number-active-disabled-sjs1t5:#5f6b7a;
  --color-text-pagination-page-number-default-z6gnaa:#9ba7b6;
  --color-text-segment-active-gc2jjl:#000716;
  --color-text-segment-default-bf7rka:#d1d5db;
  --color-text-segment-hover-5pccvg:#539fe5;
  --color-text-small-le1y42:#8d99a8;
  --color-text-status-error-5sesl6:#eb6f6f;
  --color-text-status-inactive-5ei55p:#8d99a8;
  --color-text-status-info-utupcz:#539fe5;
  --color-text-status-success-0fzhv9:#29ad32;
  --color-text-status-warning-27nlyh:#eb6f6f;
  --color-text-top-navigation-title-oypxe3:#fbfbfb;
  --color-board-placeholder-active-vaxzdf:#5f6b7a;
  --color-board-placeholder-hover-v1s7kq:#0972d3;
  --shadow-container-fx563k:0px 1px 8px 2px rgba(0, 7, 22, 0.6);
  --shadow-container-stacked-jfo2nn:0px 9px 8px -7px rgb(0 7 22 / 60%), 8px 0px 8px -7px rgb(0 7 22 / 60%), -8px 0px 8px -7px rgb(0 7 22 / 60%);
  --shadow-container-active-l4kuxc:0px 1px 1px 1px #192534, 0px 6px 36px #000716;
  --shadow-dropdown-99h0gz:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-dropup-11esug:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-flash-sticky-xplpiu:0px 6px 36px rgba(0, 7, 22, 1);
  --shadow-modal-3qhlqt:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-panel-gp3bf2:0px 1px 1px 1px #192534, 0px 6px 36px rgba(0, 7, 22, 1);
  --shadow-panel-toggle-3fk0ch:0px 6px 12px 1px rgba(0, 7, 22, 1);
  --shadow-popover-kzy6rd:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-split-bottom-otfetg:0px -36px 36px -36px rgba(0, 7, 22, 1);
  --shadow-split-side-qv353u:-1px 0px 1px 0px #192534, -36px 6px 36px -36px rgba(0, 7, 22, 1);
  --shadow-sticky-aijlo6:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-sticky-embedded-qit8ba:0px 2px 0px 0px #414d5c, 0px 16px 16px -12px rgba(0, 7, 22, 1);
}

.awsui-compact-mode:not(#\9) {
  --size-vertical-input-js5hfh:30px;
  --size-vertical-panel-icon-offset-ti40n4:13px;
  --space-alert-vertical-jbva6z:4px;
  --space-button-icon-only-horizontal-nidslr:5px;
  --space-content-header-padding-bottom-nvqytg:12px;
  --space-dark-header-overlap-distance-hfejul:32px;
  --space-expandable-section-icon-offset-top-svbirl:0;
  --space-grid-gutter-0subky:16px;
  --space-layout-content-bottom-kg9l42:24px;
  --space-layout-toggle-padding-axl9jr:16px;
  --space-modal-content-bottom-cecclv:8px;
  --space-panel-side-right-kev4k9:20px;
  --space-panel-split-top-0y4u4f:16px;
  --space-table-header-focus-outline-gutter-rbx7m7:-1px;
  --space-tabs-content-top-mn4c9x:8px;
  --space-table-content-top-t15dd1:8px;
  --space-text-grid-vertical-tj8m1c:12px;
  --space-scaled-2x-xxs-t5qzmf:0;
  --space-scaled-2x-s-9svxjy:4px;
  --space-scaled-2x-l-yejodm:12px;
  --space-scaled-2x-xl-x30c9w:16px;
  --space-scaled-2x-xxxl-lbp35j:24px;
  --space-scaled-xxxs-b6dm8t:0;
  --space-scaled-xxs-95dhkm:2px;
  --space-scaled-xs-6859qs:4px;
  --space-scaled-s-cu1hzn:8px;
  --space-scaled-m-pv0fmt:12px;
  --space-scaled-l-t03y3z:16px;
  --space-scaled-xl-w2t504:20px;
  --space-scaled-xxl-pfgv96:24px;
  --space-scaled-xxxl-ffokcj:32px;
}

.awsui-motion-disabled:not(#\9) {
  --motion-duration-extra-slow-8ks5rs:0ms;
  --motion-duration-fast-nk2ap0:0ms;
  --motion-duration-moderate-vgx1te:0ms;
  --motion-duration-refresh-only-fast-addxjd:0ms;
  --motion-duration-refresh-only-medium-5flen2:0ms;
  --motion-duration-refresh-only-slow-6gdedh:0ms;
  --motion-duration-rotate-180-ofa1ir:0ms;
  --motion-duration-rotate-90-ux18sr:0ms;
  --motion-duration-show-paced-7wspqf:0ms;
  --motion-duration-show-quick-tyaalm:0ms;
  --motion-duration-slow-0273ow:0ms;
  --motion-duration-transition-quick-x85tae:0ms;
  --motion-duration-transition-show-paced-ai4wlf:0ms;
  --motion-duration-responsive-dvittz:0ms;
  --motion-duration-expressive-96gzp7:0ms;
  --motion-duration-complex-8zbvzy:0ms;
}

.awsui-context-compact-table:not(#\9) {
  --size-vertical-input-js5hfh:30px;
  --space-alert-vertical-jbva6z:4px;
  --space-layout-toggle-padding-axl9jr:16px;
  --space-panel-side-right-kev4k9:20px;
  --space-panel-split-top-0y4u4f:16px;
  --space-tabs-content-top-mn4c9x:8px;
  --space-table-content-top-t15dd1:8px;
  --space-text-grid-vertical-tj8m1c:12px;
  --space-scaled-xxxs-b6dm8t:0;
  --space-scaled-xxs-95dhkm:2px;
  --space-scaled-xs-6859qs:4px;
  --space-scaled-s-cu1hzn:8px;
  --space-scaled-m-pv0fmt:12px;
  --space-scaled-l-t03y3z:16px;
  --space-scaled-xl-w2t504:20px;
  --space-scaled-xxl-pfgv96:24px;
  --space-scaled-xxxl-ffokcj:32px;
}

.awsui-context-top-navigation:not(#\9) {
  --color-background-button-link-active-t13dka:#354150;
  --color-background-button-link-hover-69sbpz:#192534;
  --color-background-button-normal-active-9lyks0:#354150;
  --color-background-button-normal-default-syw2p1:#0f1b2a;
  --color-background-button-normal-disabled-z5vd4i:#0f1b2a;
  --color-background-button-normal-hover-c3qbgm:#192534;
  --color-background-button-primary-active-lgpa1m:#539fe5;
  --color-background-button-primary-default-izs2ol:#539fe5;
  --color-background-button-primary-disabled-eon7km:#354150;
  --color-background-button-primary-hover-crhizd:#89bdee;
  --color-background-calendar-today-762czq:#354150;
  --color-background-cell-shaded-26ubfj:#232f3e;
  --color-background-code-editor-gutter-active-line-default-p2p8kl:#7d8998;
  --color-background-code-editor-gutter-active-line-error-lfh0u4:#eb6f6f;
  --color-background-code-editor-gutter-default-l5k65m:#192534;
  --color-background-code-editor-loading-4cqamf:#192534;
  --color-background-code-editor-pane-item-hover-lrigi6:#354150;
  --color-background-code-editor-status-bar-qgcwj5:#192534;
  --color-background-container-content-iw8cer:#576075;
  --color-background-container-header-4flbq5:#0f1b2a;
  --color-background-control-checked-9admlu:#539fe5;
  --color-background-control-default-4pa05r:#0f1b2a;
  --color-background-control-disabled-zplipy:#354150;
  --color-background-dropdown-item-default-fzdqsg:#0f1b2a;
  --color-background-dropdown-item-filter-match-6ac2mw:#354150;
  --color-background-dropdown-item-hover-mqsxkv:#354150;
  --color-background-dropdown-item-selected-8l4mpk:#192534;
  --color-background-input-default-f4ls0o:#0f1b2a;
  --color-background-input-disabled-wu9j6u:#192534;
  --color-background-item-selected-ebt4bi:#192534;
  --color-background-layout-main-y57tka:#0f1b2a;
  --color-background-layout-panel-content-libpfx:#576075;
  --color-background-layout-panel-hover-0bg3gq:#354150;
  --color-background-layout-toggle-selected-active-9w7pk3:#539fe5;
  --color-background-layout-toggle-selected-default-e1giv3:#539fe5;
  --color-background-layout-toggle-selected-hover-mes33s:#89bdee;
  --color-background-modal-overlay-17dapf:rgba(0, 7, 22, 0.7);
  --color-background-notification-grey-ua4ep5:#5f6b7a;
  --color-background-popover-mn5o9k:#192534;
  --color-background-progress-bar-content-default-r4tyyh:#539fe5;
  --color-background-progress-bar-layout-default-9xbpym:#354150;
  --color-background-segment-active-bl0u6r:#539fe5;
  --color-background-segment-default-5k37bj:#0f1b2a;
  --color-background-segment-disabled-hgka10:#0f1b2a;
  --color-background-segment-hover-s3f27a:#0f1b2a;
  --color-background-status-error-pe1hux:#1a0000;
  --color-background-status-info-usb30e:#00142b;
  --color-background-status-success-xnxxn4:#001a02;
  --color-background-status-warning-zz4oi6:#0f1b2a;
  --color-background-table-header-i1pa1g:#0f1b2a;
  --color-background-tiles-disabled-byt5lx:#192534;
  --color-background-toggle-checked-disabled-07lf06:#033160;
  --color-background-toggle-default-w4ftus:#7d8998;
  --color-border-button-normal-active-zbmaft:#89bdee;
  --color-border-button-normal-default-o2n9zb:#539fe5;
  --color-border-button-normal-disabled-262a0x:#5f6b7a;
  --color-border-button-normal-hover-hdfhkp:#89bdee;
  --color-border-button-primary-disabled-6gwabt:#354150;
  --color-border-calendar-grid-selected-focus-ring-cxqa0s:#0f1b2a;
  --color-border-code-editor-default-l9vmjf:#5f6b7a;
  --color-border-code-editor-pane-item-hover-xephrt:#5f6b7a;
  --color-border-control-checked-4xvndc:#539fe5;
  --color-border-control-disabled-vx8bco:#354150;
  --color-border-divider-default-7s2wjw:#414d5c;
  --color-border-divider-panel-bottom-zrjpck:rgba(0, 7, 22, 1);
  --color-border-divider-panel-side-yjkfcr:#414d5c;
  --color-border-dropdown-container-tyn0js:#5f6b7a;
  --color-border-dropdown-group-0utpsr:#414d5c;
  --color-border-dropdown-item-default-faosd8:#414d5c;
  --color-border-dropdown-item-hover-tyzq9m:#5f6b7a;
  --color-border-input-default-e47bkv:#5f6b7a;
  --color-border-input-disabled-x2lopj:#192534;
  --color-border-item-focused-ap3b6s:#539fe5;
  --color-border-item-placeholder-jgzhnb:#539fe5;
  --color-border-item-selected-ppkssz:#539fe5;
  --color-border-layout-wf5alz:#354150;
  --color-border-popover-5pygk7:#5f6b7a;
  --color-border-segment-active-kdy5tm:#d1d5db;
  --color-border-segment-default-091088:#d1d5db;
  --color-border-segment-disabled-6v5zlt:#d1d5db;
  --color-border-segment-hover-9notuw:#d1d5db;
  --color-border-status-error-mfrddc:#eb6f6f;
  --color-border-status-info-546i7i:#539fe5;
  --color-border-status-success-cqi45f:#29ad32;
  --color-border-status-warning-hg85ut:#8d99a8;
  --color-border-divider-interactive-default-rcasvw:#414d5c;
  --color-border-tabs-divider-69fx5i:#414d5c;
  --color-border-tabs-shadow-4lmh2z:rgba(0, 7, 22, 1);
  --color-border-tabs-underline-688p25:#033160;
  --color-border-tiles-disabled-dp4h7y:#192534;
  --color-border-tutorial-4ioksw:#414d5c;
  --color-foreground-control-default-7ajdem:#000716;
  --color-foreground-control-disabled-v6a97u:#0f1b2a;
  --color-shadow-default-3se70f:rgba(0, 7, 22, 1);
  --color-text-accent-6sf3a3:#033160;
  --color-text-body-default-ajf1h5:#d1d5db;
  --color-text-body-secondary-fdstdf:#d1d5db;
  --color-text-breadcrumb-current-mum16s:#7d8998;
  --color-text-breadcrumb-icon-mlugp6:#5f6b7a;
  --color-text-button-inline-icon-default-0qb3op:#539fe5;
  --color-text-button-inline-icon-disabled-gfhvz7:#5f6b7a;
  --color-text-button-inline-icon-hover-0p4305:#89bdee;
  --color-text-button-normal-active-87sxdf:#89bdee;
  --color-text-button-normal-default-4k5svn:#539fe5;
  --color-text-button-normal-hover-7it1vu:#89bdee;
  --color-text-link-button-normal-default-2k67s3:#539fe5;
  --color-text-link-button-normal-hover-at835b:#89bdee;
  --color-text-link-button-normal-active-b25vvz:#89bdee;
  --color-text-button-primary-active-vems2x:#000716;
  --color-text-button-primary-default-340wh6:#000716;
  --color-text-button-primary-hover-vzqjpi:#000716;
  --color-text-calendar-day-hover-graajh:#d1d5db;
  --color-text-calendar-month-elf7e9:#8d99a8;
  --color-text-code-editor-gutter-active-line-3r48bk:#000716;
  --color-text-code-editor-gutter-default-q46j79:#d1d5db;
  --color-text-code-editor-status-bar-disabled-6bhuub:#5f6b7a;
  --color-text-code-editor-tab-button-error-vez6ll:#000716;
  --color-text-column-header-u472sv:#9ba7b6;
  --color-text-column-sorting-icon-q5sr09:#9ba7b6;
  --color-text-control-disabled-3z3eul:#5f6b7a;
  --color-text-counter-jwdegc:#8d99a8;
  --color-text-disabled-a2nkh4:#5f6b7a;
  --color-text-dropdown-footer-rj6jvf:#8d99a8;
  --color-text-dropdown-group-label-eaqlcl:#8d99a8;
  --color-text-dropdown-header-6e05nm:#fbfbfb;
  --color-text-dropdown-item-default-7tg9p2:#d1d5db;
  --color-text-dropdown-item-dimmed-jyqdrs:#5f6b7a;
  --color-text-dropdown-item-disabled-varol7:#5f6b7a;
  --color-text-dropdown-item-filter-match-x302if:#89bdee;
  --color-text-dropdown-item-highlighted-oaabyk:#e9ebed;
  --color-text-dropdown-item-secondary-0me7js:#8d99a8;
  --color-text-dropdown-item-secondary-hover-7ii1ru:colorGrey300;
  --color-text-empty-fjv325:#d1d5db;
  --color-text-expandable-section-default-k03p1p:#fbfbfb;
  --color-text-expandable-section-hover-oo7tqa:#033160;
  --color-text-expandable-section-navigation-icon-default-lb9rm0:#d1d5db;
  --color-text-form-default-yd29ox:#d1d5db;
  --color-text-form-label-k3j0b4:#d1d5db;
  --color-text-form-secondary-ih9x7l:#8d99a8;
  --color-text-group-label-w9to2i:#8d99a8;
  --color-text-heading-default-jopz0f:#e9ebed;
  --color-text-heading-secondary-corkaj:#8d99a8;
  --color-text-icon-subtle-bz5b7h:#9ba7b6;
  --color-text-input-disabled-oawyzo:#5f6b7a;
  --color-text-input-placeholder-j53j3c:#7d8998;
  --color-text-input-placeholder-disabled-6928j8:#5f6b7a;
  --color-text-interactive-active-vol84d:#fbfbfb;
  --color-text-interactive-default-eg5fsa:#d1d5db;
  --color-text-interactive-disabled-3pbb07:#5f6b7a;
  --color-text-interactive-hover-v3lasm:#fbfbfb;
  --color-text-inverted-821sko:#000716;
  --color-text-label-gxskdy:#d1d5db;
  --color-text-layout-toggle-selected-tzwifo:#000716;
  --color-text-link-default-dkndw9:#539fe5;
  --color-text-link-hover-arwnci:#89bdee;
  --color-text-link-primary-underline-3sp2rc:#539fe5;
  --color-text-pagination-page-number-active-disabled-sjs1t5:#5f6b7a;
  --color-text-pagination-page-number-default-z6gnaa:#9ba7b6;
  --color-text-segment-active-gc2jjl:#000716;
  --color-text-segment-default-bf7rka:#d1d5db;
  --color-text-segment-hover-5pccvg:#539fe5;
  --color-text-small-le1y42:#8d99a8;
  --color-text-status-error-5sesl6:#eb6f6f;
  --color-text-status-inactive-5ei55p:#8d99a8;
  --color-text-status-info-utupcz:#539fe5;
  --color-text-status-success-0fzhv9:#29ad32;
  --color-text-status-warning-27nlyh:#eb6f6f;
  --color-text-top-navigation-title-oypxe3:#fbfbfb;
  --color-board-placeholder-active-vaxzdf:#5f6b7a;
  --color-board-placeholder-hover-v1s7kq:#0972d3;
}

.awsui-context-content-header:not(#\9) {
  --color-background-button-link-active-t13dka:#354150;
  --color-background-button-link-hover-69sbpz:#192534;
  --color-background-button-normal-active-9lyks0:#354150;
  --color-background-button-normal-default-syw2p1:#000716;
  --color-background-button-normal-disabled-z5vd4i:#000716;
  --color-background-button-normal-hover-c3qbgm:#192534;
  --color-background-button-primary-active-lgpa1m:#539fe5;
  --color-background-button-primary-default-izs2ol:#DAA66F;
  --color-background-button-primary-disabled-eon7km:#354150;
  --color-background-button-primary-hover-crhizd:#DAA66F;
  --color-background-calendar-today-762czq:#354150;
  --color-background-cell-shaded-26ubfj:#232f3e;
  --color-background-code-editor-gutter-active-line-default-p2p8kl:#7d8998;
  --color-background-code-editor-gutter-active-line-error-lfh0u4:#eb6f6f;
  --color-background-code-editor-gutter-default-l5k65m:#192534;
  --color-background-code-editor-loading-4cqamf:#192534;
  --color-background-code-editor-pane-item-hover-lrigi6:#354150;
  --color-background-code-editor-status-bar-qgcwj5:#192534;
  --color-background-container-content-iw8cer:#0f1b2a;
  --color-background-container-header-4flbq5:#0f1b2a;
  --color-background-control-checked-9admlu:#DAA66F;
  --color-background-control-default-4pa05r:#000716;
  --color-background-control-disabled-zplipy:#354150;
  --color-background-dropdown-item-default-fzdqsg:#192534;
  --color-background-dropdown-item-filter-match-6ac2mw:#354150;
  --color-background-dropdown-item-hover-mqsxkv:#354150;
  --color-background-dropdown-item-selected-8l4mpk:#192534;
  --color-background-input-default-f4ls0o:#2D3748;
  --color-background-input-disabled-wu9j6u:#192534;
  --color-background-item-selected-ebt4bi:#192534;
  --color-background-layout-main-y57tka:#2D3748;
  --color-background-layout-panel-content-libpfx:#0f1b2a;
  --color-background-layout-panel-hover-0bg3gq:#354150;
  --color-background-layout-toggle-selected-active-9w7pk3:#539fe5;
  --color-background-layout-toggle-selected-default-e1giv3:#539fe5;
  --color-background-layout-toggle-selected-hover-mes33s:#89bdee;
  --color-background-modal-overlay-17dapf:rgba(0, 7, 22, 0.7);
  --color-background-notification-grey-ua4ep5:#5f6b7a;
  --color-background-popover-mn5o9k:#192534;
  --color-background-progress-bar-content-default-r4tyyh:#539fe5;
  --color-background-progress-bar-layout-default-9xbpym:#354150;
  --color-background-segment-active-bl0u6r:#539fe5;
  --color-background-segment-default-5k37bj:#000716;
  --color-background-segment-disabled-hgka10:#000716;
  --color-background-segment-hover-s3f27a:#000716;
  --color-background-status-error-pe1hux:#1a0000;
  --color-background-status-info-usb30e:#00142b;
  --color-background-status-success-xnxxn4:#001a02;
  --color-background-status-warning-zz4oi6:#0f1b2a;
  --color-background-table-header-i1pa1g:#000716;
  --color-background-tiles-disabled-byt5lx:#192534;
  --color-background-toggle-checked-disabled-07lf06:#033160;
  --color-background-toggle-default-w4ftus:#7d8998;
  --color-border-button-normal-active-zbmaft:#89bdee;
  --color-border-button-normal-default-o2n9zb:#DAA66F;
  --color-border-button-normal-disabled-262a0x:#5f6b7a;
  --color-border-button-normal-hover-hdfhkp:#DAA66F;
  --color-border-button-primary-disabled-6gwabt:#354150;
  --color-border-calendar-grid-selected-focus-ring-cxqa0s:#0f1b2a;
  --color-border-code-editor-default-l9vmjf:#5f6b7a;
  --color-border-code-editor-pane-item-hover-xephrt:#5f6b7a;
  --color-border-control-checked-4xvndc:#DAA66F;
  --color-border-control-disabled-vx8bco:#354150;
  --color-border-divider-default-7s2wjw:#414d5c;
  --color-border-divider-panel-bottom-zrjpck:rgba(0, 7, 22, 1);
  --color-border-divider-panel-side-yjkfcr:#414d5c;
  --color-border-dropdown-container-tyn0js:#5f6b7a;
  --color-border-dropdown-group-0utpsr:#414d5c;
  --color-border-dropdown-item-default-faosd8:#414d5c;
  --color-border-dropdown-item-hover-tyzq9m:#5f6b7a;
  --color-border-input-default-e47bkv:#5f6b7a;
  --color-border-input-disabled-x2lopj:#192534;
  --color-border-item-focused-ap3b6s:#539fe5;
  --color-border-item-placeholder-jgzhnb:#539fe5;
  --color-border-item-selected-ppkssz:#539fe5;
  --color-border-layout-wf5alz:#354150;
  --color-border-popover-5pygk7:#5f6b7a;
  --color-border-segment-active-kdy5tm:#d1d5db;
  --color-border-segment-default-091088:#d1d5db;
  --color-border-segment-disabled-6v5zlt:#d1d5db;
  --color-border-segment-hover-9notuw:#d1d5db;
  --color-border-status-error-mfrddc:#eb6f6f;
  --color-border-status-info-546i7i:#539fe5;
  --color-border-status-success-cqi45f:#29ad32;
  --color-border-status-warning-hg85ut:#8d99a8;
  --color-border-divider-interactive-default-rcasvw:#414d5c;
  --color-border-tabs-divider-69fx5i:#414d5c;
  --color-border-tabs-shadow-4lmh2z:rgba(0, 7, 22, 1);
  --color-border-tabs-underline-688p25:#539fe5;
  --color-border-tiles-disabled-dp4h7y:#192534;
  --color-border-tutorial-4ioksw:#414d5c;
  --color-foreground-control-default-7ajdem:#000716;
  --color-foreground-control-disabled-v6a97u:#0f1b2a;
  --color-shadow-default-3se70f:rgba(0, 7, 22, 1);
  --color-text-accent-6sf3a3:#539fe5;
  --color-text-body-default-ajf1h5:#d1d5db;
  --color-text-body-secondary-fdstdf:#d1d5db;
  --color-text-breadcrumb-current-mum16s:#7d8998;
  --color-text-breadcrumb-icon-mlugp6:#5f6b7a;
  --color-text-button-inline-icon-default-0qb3op:#DAA66F;
  --color-text-button-inline-icon-disabled-gfhvz7:#5f6b7a;
  --color-text-button-inline-icon-hover-0p4305:#DAA66F;
  --color-text-button-normal-active-87sxdf:#89bdee;
  --color-text-button-normal-default-4k5svn:#DAA66F;
  --color-text-button-normal-hover-7it1vu:#DAA66F;
  --color-text-link-button-normal-default-2k67s3:#DAA66F;
  --color-text-link-button-normal-hover-at835b:#DAA66F;
  --color-text-link-button-normal-active-b25vvz:#89bdee;
  --color-text-button-primary-active-vems2x:#000716;
  --color-text-button-primary-default-340wh6:#000716;
  --color-text-button-primary-hover-vzqjpi:#000716;
  --color-text-calendar-day-hover-graajh:#d1d5db;
  --color-text-calendar-month-elf7e9:#8d99a8;
  --color-text-code-editor-gutter-active-line-3r48bk:#000716;
  --color-text-code-editor-gutter-default-q46j79:#d1d5db;
  --color-text-code-editor-status-bar-disabled-6bhuub:#5f6b7a;
  --color-text-code-editor-tab-button-error-vez6ll:#000716;
  --color-text-column-header-u472sv:#9ba7b6;
  --color-text-column-sorting-icon-q5sr09:#9ba7b6;
  --color-text-control-disabled-3z3eul:#5f6b7a;
  --color-text-counter-jwdegc:#8d99a8;
  --color-text-disabled-a2nkh4:#5f6b7a;
  --color-text-dropdown-footer-rj6jvf:#8d99a8;
  --color-text-dropdown-group-label-eaqlcl:#8d99a8;
  --color-text-dropdown-header-6e05nm:#fbfbfb;
  --color-text-dropdown-item-default-7tg9p2:#d1d5db;
  --color-text-dropdown-item-dimmed-jyqdrs:#5f6b7a;
  --color-text-dropdown-item-disabled-varol7:#5f6b7a;
  --color-text-dropdown-item-filter-match-x302if:#89bdee;
  --color-text-dropdown-item-highlighted-oaabyk:#e9ebed;
  --color-text-dropdown-item-secondary-0me7js:#8d99a8;
  --color-text-dropdown-item-secondary-hover-7ii1ru:colorGrey300;
  --color-text-empty-fjv325:#d1d5db;
  --color-text-expandable-section-default-k03p1p:#fbfbfb;
  --color-text-expandable-section-hover-oo7tqa:#539fe5;
  --color-text-expandable-section-navigation-icon-default-lb9rm0:#d1d5db;
  --color-text-form-default-yd29ox:#d1d5db;
  --color-text-form-label-k3j0b4:#d1d5db;
  --color-text-form-secondary-ih9x7l:#8d99a8;
  --color-text-group-label-w9to2i:#8d99a8;
  --color-text-heading-default-jopz0f:#e9ebed;
  --color-text-heading-secondary-corkaj:#8d99a8;
  --color-text-icon-subtle-bz5b7h:#9ba7b6;
  --color-text-input-disabled-oawyzo:#5f6b7a;
  --color-text-input-placeholder-j53j3c:#7d8998;
  --color-text-input-placeholder-disabled-6928j8:#5f6b7a;
  --color-text-interactive-active-vol84d:#fbfbfb;
  --color-text-interactive-default-eg5fsa:#d1d5db;
  --color-text-interactive-disabled-3pbb07:#5f6b7a;
  --color-text-interactive-hover-v3lasm:#fbfbfb;
  --color-text-inverted-821sko:#000716;
  --color-text-label-gxskdy:#d1d5db;
  --color-text-layout-toggle-selected-tzwifo:#000716;
  --color-text-link-default-dkndw9:#DAA66F;
  --color-text-link-hover-arwnci:#DAA66F;
  --color-text-link-primary-underline-3sp2rc:#DAA66F;
  --color-text-pagination-page-number-active-disabled-sjs1t5:#5f6b7a;
  --color-text-pagination-page-number-default-z6gnaa:#9ba7b6;
  --color-text-segment-active-gc2jjl:#000716;
  --color-text-segment-default-bf7rka:#d1d5db;
  --color-text-segment-hover-5pccvg:#539fe5;
  --color-text-small-le1y42:#8d99a8;
  --color-text-status-error-5sesl6:#eb6f6f;
  --color-text-status-inactive-5ei55p:#8d99a8;
  --color-text-status-info-utupcz:#539fe5;
  --color-text-status-success-0fzhv9:#29ad32;
  --color-text-status-warning-27nlyh:#eb6f6f;
  --color-text-top-navigation-title-oypxe3:#fbfbfb;
  --color-board-placeholder-active-vaxzdf:#5f6b7a;
  --color-board-placeholder-hover-v1s7kq:#0972d3;
  --shadow-container-fx563k:none;
  --shadow-container-stacked-jfo2nn:0px 9px 8px -7px rgb(0 7 22 / 60%), 8px 0px 8px -7px rgb(0 7 22 / 60%), -8px 0px 8px -7px rgb(0 7 22 / 60%);
  --shadow-container-active-l4kuxc:0px 1px 1px 1px #192534, 0px 6px 36px #000716;
  --shadow-dropdown-99h0gz:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-dropup-11esug:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-modal-3qhlqt:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-popover-kzy6rd:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-split-bottom-otfetg:0px -36px 36px -36px rgba(0, 7, 22, 1);
  --shadow-split-side-qv353u:-1px 0px 1px 0px #192534, -36px 6px 36px -36px rgba(0, 7, 22, 1);
  --shadow-sticky-aijlo6:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-sticky-embedded-qit8ba:0px 2px 0px 0px #414d5c, 0px 16px 16px -12px rgba(0, 7, 22, 1);
}

.awsui-context-flashbar:not(#\9) {
  --color-background-button-normal-active-9lyks0:rgba(0, 7, 22, 0.2);
  --color-background-button-normal-default-syw2p1:transparent;
  --color-background-button-normal-hover-c3qbgm:rgba(0, 7, 22, 0.15);
  --color-background-button-primary-active-lgpa1m:#033160;
  --color-background-button-primary-default-izs2ol:#fbfbfb;
  --color-background-button-primary-hover-crhizd:#ffffff;
  --color-background-container-content-iw8cer:#ffffff;
  --color-background-input-default-f4ls0o:#ffffff;
  --color-background-layout-main-y57tka:#ffffff;
  --color-background-layout-panel-content-libpfx:#ffffff;
  --color-background-notification-blue-vqb59v:#007D7B;
  --color-background-segment-default-5k37bj:transparent;
  --color-background-segment-hover-s3f27a:transparent;
  --color-border-button-normal-active-zbmaft:#ffffff;
  --color-border-button-normal-default-o2n9zb:#fbfbfb;
  --color-border-button-normal-hover-hdfhkp:#ffffff;
  --color-border-item-focused-ap3b6s:#fbfbfb;
  --color-border-tabs-underline-688p25:#0972d3;
  --color-text-accent-6sf3a3:#0972d3;
  --color-text-button-inline-icon-default-0qb3op:#0972d3;
  --color-text-button-normal-active-87sxdf:#ffffff;
  --color-text-button-normal-default-4k5svn:#fbfbfb;
  --color-text-button-normal-hover-7it1vu:#ffffff;
  --color-text-link-button-normal-default-2k67s3:#fbfbfb;
  --color-text-link-button-normal-hover-at835b:#ffffff;
  --color-text-link-button-normal-active-b25vvz:#ffffff;
  --color-text-expandable-section-hover-oo7tqa:#0972d3;
  --color-text-link-default-dkndw9:#0972d3;
  --color-text-link-primary-underline-3sp2rc:#0972d3;
}

.awsui-context-alert:not(#\9) {
  --color-background-button-normal-active-9lyks0:rgba(0, 7, 22, 0.1);
  --color-background-button-normal-default-syw2p1:transparent;
  --color-background-button-normal-hover-c3qbgm:rgba(0, 7, 22, 0.05);
  --color-background-button-primary-active-lgpa1m:#033160;
  --color-background-button-primary-default-izs2ol:#414d5c;
  --color-background-button-primary-hover-crhizd:#000716;
  --color-background-container-content-iw8cer:#ffffff;
  --color-background-input-default-f4ls0o:#ffffff;
  --color-background-layout-main-y57tka:#ffffff;
  --color-background-layout-panel-content-libpfx:#ffffff;
  --color-background-segment-default-5k37bj:transparent;
  --color-background-segment-hover-s3f27a:transparent;
  --color-border-button-normal-active-zbmaft:#000716;
  --color-border-button-normal-default-o2n9zb:#414d5c;
  --color-border-button-normal-hover-hdfhkp:#000716;
  --color-border-tabs-underline-688p25:#0972d3;
  --color-text-accent-6sf3a3:#0972d3;
  --color-text-button-inline-icon-default-0qb3op:#0972d3;
  --color-text-button-normal-active-87sxdf:#000716;
  --color-text-button-normal-default-4k5svn:#414d5c;
  --color-text-button-normal-hover-7it1vu:#000716;
  --color-text-link-button-normal-default-2k67s3:#0972d3;
  --color-text-link-button-normal-active-b25vvz:#000716;
  --color-text-expandable-section-hover-oo7tqa:#0972d3;
  --color-text-link-default-dkndw9:#0972d3;
  --color-text-link-primary-underline-3sp2rc:#0972d3;
}

.awsui-dark-mode .awsui-context-flashbar:not(#\9) {
  --color-background-button-primary-active-lgpa1m:#539fe5;
  --color-background-container-content-iw8cer:#0f1b2a;
  --color-background-input-default-f4ls0o:#0f1b2a;
  --color-background-layout-main-y57tka:#0f1b2a;
  --color-background-layout-panel-content-libpfx:#0f1b2a;
  --color-border-tabs-underline-688p25:#539fe5;
  --color-text-accent-6sf3a3:#539fe5;
  --color-text-button-inline-icon-default-0qb3op:#539fe5;
  --color-text-button-inline-icon-hover-0p4305:#89bdee;
  --color-text-expandable-section-hover-oo7tqa:#539fe5;
  --color-text-link-default-dkndw9:#539fe5;
  --color-text-link-hover-arwnci:#89bdee;
  --color-text-link-primary-underline-3sp2rc:#539fe5;
}

.awsui-context-flashbar.awsui-dark-mode:not(#\9) {
  --color-background-button-primary-active-lgpa1m:#539fe5;
  --color-background-container-content-iw8cer:#0f1b2a;
  --color-background-input-default-f4ls0o:#0f1b2a;
  --color-background-layout-main-y57tka:#0f1b2a;
  --color-background-layout-panel-content-libpfx:#0f1b2a;
  --color-border-tabs-underline-688p25:#539fe5;
  --color-text-accent-6sf3a3:#539fe5;
  --color-text-button-inline-icon-default-0qb3op:#539fe5;
  --color-text-button-inline-icon-hover-0p4305:#89bdee;
  --color-text-expandable-section-hover-oo7tqa:#539fe5;
  --color-text-link-default-dkndw9:#539fe5;
  --color-text-link-hover-arwnci:#89bdee;
  --color-text-link-primary-underline-3sp2rc:#539fe5;
}

.awsui-dark-mode .awsui-context-alert:not(#\9) {
  --color-background-button-normal-active-9lyks0:rgba(255, 255, 255, 0.15);
  --color-background-button-normal-hover-c3qbgm:rgba(255, 255, 255, 0.1);
  --color-background-button-primary-active-lgpa1m:#539fe5;
  --color-background-button-primary-default-izs2ol:#d1d5db;
  --color-background-button-primary-hover-crhizd:#ffffff;
  --color-background-container-content-iw8cer:#0f1b2a;
  --color-background-input-default-f4ls0o:#0f1b2a;
  --color-background-layout-main-y57tka:#0f1b2a;
  --color-background-layout-panel-content-libpfx:#0f1b2a;
  --color-border-button-normal-active-zbmaft:#ffffff;
  --color-border-button-normal-default-o2n9zb:#d1d5db;
  --color-border-button-normal-hover-hdfhkp:#ffffff;
  --color-border-item-focused-ap3b6s:#fbfbfb;
  --color-border-tabs-underline-688p25:#539fe5;
  --color-text-accent-6sf3a3:#539fe5;
  --color-text-button-inline-icon-default-0qb3op:#539fe5;
  --color-text-button-inline-icon-hover-0p4305:#89bdee;
  --color-text-button-normal-active-87sxdf:#ffffff;
  --color-text-button-normal-default-4k5svn:#d1d5db;
  --color-text-button-normal-hover-7it1vu:#ffffff;
  --color-text-link-button-normal-default-2k67s3:#539fe5;
  --color-text-link-button-normal-hover-at835b:#89bdee;
  --color-text-link-button-normal-active-b25vvz:#ffffff;
  --color-text-expandable-section-hover-oo7tqa:#539fe5;
  --color-text-link-default-dkndw9:#539fe5;
  --color-text-link-hover-arwnci:#89bdee;
  --color-text-link-primary-underline-3sp2rc:#539fe5;
}

.awsui-context-alert.awsui-dark-mode:not(#\9) {
  --color-background-button-normal-active-9lyks0:rgba(255, 255, 255, 0.15);
  --color-background-button-normal-hover-c3qbgm:rgba(255, 255, 255, 0.1);
  --color-background-button-primary-active-lgpa1m:#539fe5;
  --color-background-button-primary-default-izs2ol:#d1d5db;
  --color-background-button-primary-hover-crhizd:#ffffff;
  --color-background-container-content-iw8cer:#0f1b2a;
  --color-background-input-default-f4ls0o:#0f1b2a;
  --color-background-layout-main-y57tka:#0f1b2a;
  --color-background-layout-panel-content-libpfx:#0f1b2a;
  --color-border-button-normal-active-zbmaft:#ffffff;
  --color-border-button-normal-default-o2n9zb:#d1d5db;
  --color-border-button-normal-hover-hdfhkp:#ffffff;
  --color-border-item-focused-ap3b6s:#fbfbfb;
  --color-border-tabs-underline-688p25:#539fe5;
  --color-text-accent-6sf3a3:#539fe5;
  --color-text-button-inline-icon-default-0qb3op:#539fe5;
  --color-text-button-inline-icon-hover-0p4305:#89bdee;
  --color-text-button-normal-active-87sxdf:#ffffff;
  --color-text-button-normal-default-4k5svn:#d1d5db;
  --color-text-button-normal-hover-7it1vu:#ffffff;
  --color-text-link-button-normal-default-2k67s3:#539fe5;
  --color-text-link-button-normal-hover-at835b:#89bdee;
  --color-text-link-button-normal-active-b25vvz:#ffffff;
  --color-text-expandable-section-hover-oo7tqa:#539fe5;
  --color-text-link-default-dkndw9:#539fe5;
  --color-text-link-hover-arwnci:#89bdee;
  --color-text-link-primary-underline-3sp2rc:#539fe5;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
@keyframes awsui-fade-in-35003c {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes awsui-fade-out-35003c {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes awsui-status-icon-error-35003c {
  0% {
    transform: translateX(-5px);
    animation-timing-function: linear;
  }
  50% {
    transform: translateX(5px);
    animation-timing-function: var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes awsui-scale-popup-35003c {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/