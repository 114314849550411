@import "./common/constants.scss";

body {
  margin: 0;
  padding: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#firebaseui-auth-container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -36px;
  margin-left: -118px;
}


.global-spinner {
  position: fixed !important;
  right: 20px;
  bottom: 20px;
}

img.profile {
  border: 1px solid #ccc;
  width: 96px;
  height: 96px;
}

.rmwc-avatar {
  border: 2px solid #ccc;
  margin-bottom: $spacing-small;
}


span[class^="awsui_title"] {
  &:after {
    content: "by Asemio";
    font-size: 14px;
    font-weight: 400;
    margin-left: 6px;
  }
}

a[class^="awsui_header-link"] {
  display: block !important;
}

span[class^="awsui_header-link-text"] {
  display: block;
  margin-top: 16px;
  font-weight: 700 !important;
}
