/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_button_m5h9f_18pqz_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  min-width: 0;
  -ms-word-break: break-all;
  word-break: break-word;
  font-weight: var(--font-button-weight-7rj5fx, 800);
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  white-space: nowrap;
  height: 100%;
  padding-left: var(--space-xs-rsr2qu, 8px);
  padding-right: var(--space-xs-rsr2qu, 8px);
  text-decoration: none;
  cursor: pointer;
  border: transparent;
  background: transparent;
  color: var(--color-text-interactive-default-eg5fsa, #414d5c);
}
.awsui_button_m5h9f_18pqz_93:not(#\9):hover {
  color: var(--color-text-interactive-hover-v3lasm, #000716);
  text-decoration: none;
}
.awsui_button_m5h9f_18pqz_93:not(#\9):active, .awsui_button_m5h9f_18pqz_93.awsui_expanded_m5h9f_18pqz_127:not(#\9) {
  background: transparent;
  color: var(--color-text-interactive-active-vol84d, #000716);
}
.awsui_button_m5h9f_18pqz_93.awsui_expanded_m5h9f_18pqz_127:not(#\9) {
  color: var(--color-text-accent-6sf3a3, #0972D3);
}
.awsui_button_m5h9f_18pqz_93:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_m5h9f_18pqz_93.awsui_offset-right-none_m5h9f_18pqz_138:not(#\9) {
  margin-right: 0;
}
.awsui_button_m5h9f_18pqz_93.awsui_offset-right-l_m5h9f_18pqz_141:not(#\9) {
  margin-right: var(--space-s-hv8c1d, 12px);
}
.awsui_button_m5h9f_18pqz_93.awsui_offset-right-xxl_m5h9f_18pqz_144:not(#\9) {
  margin-right: var(--space-xl-a39hup, 24px);
}
.awsui_button_m5h9f_18pqz_93[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_m5h9f_18pqz_93[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(-1px - 1px);
}
.awsui_button_m5h9f_18pqz_93[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * -1px);
  top: calc(-1 * -1px);
  width: calc(100% + 2 * -1px);
  height: calc(100% + 2 * -1px);
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}

.awsui_text_m5h9f_18pqz_166:not(#\9) {
  margin-right: var(--space-xs-rsr2qu, 8px);
}

.awsui_icon_m5h9f_18pqz_170 + .awsui_text_m5h9f_18pqz_166:not(#\9) {
  margin-left: var(--space-xs-rsr2qu, 8px);
}