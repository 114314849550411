/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_icon_k5dlb_1wffp_93:not(#\9) {
  transition: transform var(--motion-duration-rotate-90-ux18sr, 135ms) var(--motion-easing-rotate-90-eoew89, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_icon_k5dlb_1wffp_93:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_icon_k5dlb_1wffp_93:not(#\9), .awsui-mode-entering .awsui_icon_k5dlb_1wffp_93:not(#\9) {
  animation: none;
  transition: none;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_top-navigation_k5dlb_1wffp_111:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  background: var(--color-background-container-content-iw8cer, #FFFFFF);
}
.awsui_top-navigation_k5dlb_1wffp_111 > .awsui_padding-box_k5dlb_1wffp_124:not(#\9) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: calc(var(--space-xxxl-bo9z7f, 40px) + var(--space-scaled-m-pv0fmt, 16px));
  padding-left: var(--space-xxl-2nvmf1, 32px);
}
.awsui_top-navigation_k5dlb_1wffp_111.awsui_medium_k5dlb_1wffp_134 > .awsui_padding-box_k5dlb_1wffp_124:not(#\9), .awsui_top-navigation_k5dlb_1wffp_111.awsui_narrow_k5dlb_1wffp_134 > .awsui_padding-box_k5dlb_1wffp_124:not(#\9) {
  padding-left: var(--space-l-4vl6xu, 20px);
}
.awsui_top-navigation_k5dlb_1wffp_111.awsui_medium_k5dlb_1wffp_134 > .awsui_padding-box_k5dlb_1wffp_124:not(#\9) {
  height: calc(var(--space-xxxl-bo9z7f, 40px) + var(--space-scaled-xs-6859qs, 8px));
  padding-right: 0;
}
.awsui_top-navigation_k5dlb_1wffp_111.awsui_narrow_k5dlb_1wffp_134 > .awsui_padding-box_k5dlb_1wffp_124:not(#\9) {
  height: var(--space-xxxl-bo9z7f, 40px);
}

.awsui_virtual_k5dlb_1wffp_145:not(#\9) {
  width: 9000px;
}

.awsui_hidden_k5dlb_1wffp_149:not(#\9) {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
  visibility: hidden;
}

/* stylelint-disable-next-line selector-combinator-disallowed-list, selector-max-universal */
.awsui_hidden_k5dlb_1wffp_149 *:not(#\9):not(#awsui_\9 _k5dlb_1wffp_1) {
  visibility: hidden;
}

.awsui_identity_k5dlb_1wffp_161:not(#\9) {
  min-width: 0;
}
.awsui_identity_k5dlb_1wffp_161 > .awsui_identity-link_k5dlb_1wffp_164:not(#\9) {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--color-text-top-navigation-title-oypxe3, #000716);
}
.awsui_identity_k5dlb_1wffp_161 > .awsui_identity-link_k5dlb_1wffp_164:not(#\9):hover {
  color: var(--color-text-accent-6sf3a3, #0972D3);
}
.awsui_identity_k5dlb_1wffp_161 > .awsui_identity-link_k5dlb_1wffp_164[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: thin dotted;
  outline: var(--border-link-focus-ring-outline-k2wccv, 0);
  outline-offset: 2px;
  outline-color: var(--color-border-item-focused-ap3b6s, #0972d3);
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 var(--border-link-focus-ring-shadow-spread-nv4ahb, 2px) var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_identity_k5dlb_1wffp_161.awsui_no-logo_k5dlb_1wffp_181:not(#\9) {
  min-width: 100px;
}

.awsui_logo_k5dlb_1wffp_185:not(#\9) {
  display: block;
  max-height: var(--space-xxl-2nvmf1, 32px);
  margin-right: var(--space-s-hv8c1d, 12px);
  width: auto;
  flex-shrink: 0;
  min-width: 10px;
}
.awsui_logo_k5dlb_1wffp_185.awsui_narrow_k5dlb_1wffp_134:not(#\9) {
  max-height: var(--space-xl-a39hup, 24px);
}

.awsui_title_k5dlb_1wffp_197:not(#\9) {
  font-size: var(--font-heading-m-size-6e3jmg, 20px);
  line-height: var(--font-heading-m-line-height-u3t54f, 24px);
  letter-spacing: var(--font-heading-m-letter-spacing-r00pg3, -0.01em);
  font-weight: var(--font-heading-m-weight-hi5n0j, 800);
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.awsui_no-logo_k5dlb_1wffp_181 > .awsui_identity-link_k5dlb_1wffp_164 > .awsui_title_k5dlb_1wffp_197:not(#\9) {
  font-weight: 700;
}

.awsui_inputs_k5dlb_1wffp_212:not(#\9) {
  display: flex;
  flex: 1;
  padding: 0 var(--space-m-17eucw, 16px);
  justify-content: center;
}

.awsui_search_k5dlb_1wffp_219:not(#\9) {
  width: 100%;
  max-width: 340px;
}
.awsui_search-expanded_k5dlb_1wffp_223:not(#\9) {
  max-width: none;
}

.awsui_utilities_k5dlb_1wffp_227:not(#\9) {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
}
.awsui_medium_k5dlb_1wffp_134 > .awsui_padding-box_k5dlb_1wffp_124 > .awsui_utilities_k5dlb_1wffp_227:not(#\9), .awsui_narrow_k5dlb_1wffp_134 > .awsui_padding-box_k5dlb_1wffp_124 > .awsui_utilities_k5dlb_1wffp_227:not(#\9) {
  padding-left: 0;
}

.awsui_utility-wrapper_k5dlb_1wffp_238:not(#\9) {
  display: flex;
  position: relative;
  flex-shrink: 0;
  align-items: center;
  padding: 0 var(--space-m-17eucw, 16px);
}
.awsui_utility-wrapper_k5dlb_1wffp_238:not(#\9)::after {
  display: block;
  position: absolute;
  content: "";
  width: 1px;
  right: 0;
  top: var(--space-s-hv8c1d, 12px);
  bottom: var(--space-s-hv8c1d, 12px);
  background: var(--color-border-divider-default-7s2wjw, #e9ebed);
}
.awsui_utility-wrapper_k5dlb_1wffp_238:not(#\9):last-of-type::after {
  display: none;
}

.awsui_utility-type-button-link_k5dlb_1wffp_259:not(#\9) {
  padding: 0 var(--space-l-4vl6xu, 20px);
}

.awsui_utility-type-menu-dropdown_k5dlb_1wffp_263:not(#\9) {
  padding: 0 var(--space-s-hv8c1d, 12px);
  align-items: stretch;
}
.awsui_utility-type-menu-dropdown_k5dlb_1wffp_263:not(#\9):not(.awsui_narrow_k5dlb_1wffp_134):last-of-type, .awsui_utility-type-menu-dropdown_k5dlb_1wffp_263:not(#\9):not(.awsui_medium_k5dlb_1wffp_134):last-of-type {
  padding-right: 0;
}

.awsui_utility-type-button-primary-button_k5dlb_1wffp_271:not(#\9) {
  left: -1px;
  border-left: 1px solid var(--color-background-container-content-iw8cer, #FFFFFF);
}
.awsui_utility-type-button-primary-button_k5dlb_1wffp_271:not(#\9)::after {
  display: none;
}

.awsui_utility-link-icon_k5dlb_1wffp_279:not(#\9) {
  margin-left: var(--space-xxs-ynfts5, 4px);
}

.awsui_utility-button-external-icon_k5dlb_1wffp_283:not(#\9) {
  display: inline-block;
}

.awsui_offset-right-none_k5dlb_1wffp_287:not(#\9) {
  margin-right: 0;
}

.awsui_offset-right-l_k5dlb_1wffp_291:not(#\9) {
  margin-right: var(--space-xxs-ynfts5, 4px);
}

.awsui_offset-right-xxl_k5dlb_1wffp_295:not(#\9) {
  margin-right: var(--space-m-17eucw, 16px);
}

/* Overflow Menu */
.awsui_overflow-menu-drawer_k5dlb_1wffp_300:not(#\9) {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1001;
}

.awsui_overflow-menu_k5dlb_1wffp_300:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  background: var(--color-background-container-content-iw8cer, #FFFFFF);
  height: 100%;
}

.awsui_overflow-menu-header_k5dlb_1wffp_322:not(#\9) {
  display: flex;
  align-items: center;
  min-height: var(--font-panel-header-line-height-x2226j, 24px);
  padding: var(--space-scaled-m-pv0fmt, 16px);
  border-bottom: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}
.awsui_overflow-menu-header-text_k5dlb_1wffp_329:not(#\9) {
  font-size: var(--font-panel-header-size-0vdgjc, 20px);
  line-height: var(--font-panel-header-line-height-x2226j, 24px);
  font-weight: var(--font-heading-l-weight-xm7ks9, 800);
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
  color: var(--color-text-heading-default-jopz0f, #000716);
  flex: 1;
  margin: 0;
  text-align: center;
}
.awsui_overflow-menu-header-text--secondary_k5dlb_1wffp_340:not(#\9) {
  font-size: var(--font-header-h2-description-size-pqrrui, 14px);
  line-height: var(--font-header-h2-description-line-height-gjrb86, 22px);
  font-weight: 400;
}

.awsui_overflow-menu-header-text--title_k5dlb_1wffp_346:not(#\9) {
  /* used in test-utils */
}

.awsui_overflow-menu-back-button_k5dlb_1wffp_350:not(#\9) {
  /* used in test-utils */
}

.awsui_overflow-menu-dismiss-button_k5dlb_1wffp_354:not(#\9) {
  /* used in test-utils */
}

.awsui_overflow-menu-control_k5dlb_1wffp_358:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: flex;
  width: 100%;
  padding: 0;
  background: none;
  border: none;
}
.awsui_overflow-menu-list-item-utility_k5dlb_1wffp_375 > .awsui_overflow-menu-control_k5dlb_1wffp_358:not(#\9) {
  padding: var(--space-scaled-m-pv0fmt, 16px);
}
.awsui_overflow-menu-control_k5dlb_1wffp_358:not(#\9):hover {
  color: var(--color-text-accent-6sf3a3, #0972D3);
  cursor: pointer;
}
.awsui_overflow-menu-control-link_k5dlb_1wffp_382:not(#\9) {
  text-decoration: none;
}
.awsui_overflow-menu-control-expandable-menu-trigger_k5dlb_1wffp_385:not(#\9) {
  color: var(--color-text-dropdown-group-label-eaqlcl, #414d5c);
  font-weight: bold;
}
.awsui_overflow-menu-control-expandable-menu-trigger_k5dlb_1wffp_385:not(#\9):hover {
  color: var(--color-text-accent-6sf3a3, #0972D3);
}
.awsui_overflow-menu-control_k5dlb_1wffp_358[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_overflow-menu-control_k5dlb_1wffp_358[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_overflow-menu-control_k5dlb_1wffp_358[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}

.awsui_overflow-menu-list_k5dlb_1wffp_375:not(#\9) {
  list-style: none;
  margin: 0;
  padding: 0;
}
.awsui_overflow-menu-list-submenu_k5dlb_1wffp_416:not(#\9) {
  margin: var(--space-scaled-xxs-95dhkm, 4px) 0;
}

.awsui_overflow-menu-list-item_k5dlb_1wffp_375:not(#\9) {
  box-sizing: border-box;
  letter-spacing: var(--font-button-letter-spacing-8y5poz, 0.005em);
}
.awsui_overflow-menu-list-item-icon_k5dlb_1wffp_424:not(#\9) {
  margin-right: var(--space-xxs-ynfts5, 4px);
}
.awsui_overflow-menu-list-item-text_k5dlb_1wffp_427:not(#\9) {
  flex: 1;
}
.awsui_overflow-menu-list-item-utility_k5dlb_1wffp_375:not(#\9) {
  font-weight: var(--font-button-weight-7rj5fx, 800);
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
  border-bottom: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}
.awsui_overflow-menu-list-item-submenu_k5dlb_1wffp_436:not(#\9) {
  border-top: var(--border-divider-section-width-4wm2it, 2px) solid transparent;
  border-bottom: var(--border-divider-section-width-4wm2it, 2px) solid transparent;
  padding: var(--space-scaled-xxs-95dhkm, 4px) var(--space-scaled-l-t03y3z, 20px);
}
.awsui_overflow-menu-list-item-dropdown-menu_k5dlb_1wffp_441:not(#\9) {
  padding: var(--space-scaled-xxs-95dhkm, 4px) var(--space-scaled-s-cu1hzn, 12px);
}
.awsui_overflow-menu-list-item-expandable_k5dlb_1wffp_444:not(#\9) {
  border-top-color: var(--color-border-divider-default-7s2wjw, #e9ebed);
  border-bottom-color: var(--color-border-divider-default-7s2wjw, #e9ebed);
}

.awsui_icon_k5dlb_1wffp_93:not(#\9) {
  transform: rotate(-180deg);
}
.awsui_icon_k5dlb_1wffp_93.awsui_expanded_k5dlb_1wffp_452:not(#\9) {
  transform: rotate(0deg);
}