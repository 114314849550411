/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_calendar_1ykar_dp693_93:not(#\9) {
  animation: awsui_awsui-motion-fade-in-0_1ykar_dp693_1 var(--motion-duration-show-quick-tyaalm, 135ms) var(--motion-easing-show-quick-1fcgbv, ease-out);
  animation-fill-mode: both;
}
@keyframes awsui_awsui-motion-fade-in-0_1ykar_dp693_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_calendar_1ykar_dp693_93:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_calendar_1ykar_dp693_93:not(#\9), .awsui-mode-entering .awsui_calendar_1ykar_dp693_93:not(#\9) {
  animation: none;
  transition: none;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_1ykar_dp693_124:not(#\9) {
  /* used in test-utils */
}

.awsui_calendar_1ykar_dp693_93:not(#\9) {
  display: block;
  width: var(--size-calendar-grid-width-lxsx0p, 238px);
  overflow: auto;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}
.awsui_calendar-inner_1ykar_dp693_143:not(#\9) {
  margin: var(--space-xs-rsr2qu, 8px);
}
.awsui_calendar-header_1ykar_dp693_146:not(#\9) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.awsui_calendar-header-month_1ykar_dp693_151:not(#\9) {
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  font-weight: 700;
  color: var(--color-text-dropdown-item-default-7tg9p2, #000716);
  margin: 0;
}
.awsui_calendar-next-month-btn_1ykar_dp693_158:not(#\9) {
  /* used for identifying element */
}
.awsui_calendar-prev-month-btn_1ykar_dp693_161:not(#\9) {
  /* used for identifying element */
}
.awsui_calendar-grid_1ykar_dp693_164:not(#\9) {
  width: 100%;
  border-spacing: 0;
}
.awsui_calendar-grid-cell_1ykar_dp693_168:not(#\9) {
  width: 14.2857142857%;
  word-break: break-word;
  text-align: center;
  font-weight: unset;
}
.awsui_calendar-day-header_1ykar_dp693_174:not(#\9) {
  padding: var(--space-s-hv8c1d, 12px) 0 var(--space-xxs-ynfts5, 4px);
  color: var(--color-text-calendar-month-elf7e9, #5f6b7a);
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
}
.awsui_calendar-day_1ykar_dp693_174:not(#\9) {
  border-bottom: 1px solid var(--color-border-calendar-grid-sshunw, transparent);
  border-right: 1px solid var(--color-border-calendar-grid-sshunw, transparent);
  padding: var(--space-xxs-ynfts5, 4px) 0;
  color: var(--color-text-dropdown-item-disabled-varol7, #9ba7b6);
  position: relative;
}
.awsui_calendar-day_1ykar_dp693_174:not(#\9):first-child {
  border-left: 1px solid var(--color-border-calendar-grid-sshunw, transparent);
}
.awsui_calendar-day-enabled_1ykar_dp693_191:not(#\9) {
  cursor: pointer;
  color: var(--color-text-dropdown-item-secondary-0me7js, #5f6b7a);
}
.awsui_calendar-day-enabled_1ykar_dp693_191:not(#\9)::after {
  border-radius: var(--border-radius-item-u2ibpi, 8px);
}
.awsui_calendar-day-enabled_1ykar_dp693_191.awsui_calendar-day-current-month_1ykar_dp693_198:not(#\9) {
  color: var(--color-text-dropdown-item-default-7tg9p2, #000716);
}
.awsui_calendar-day-enabled_1ykar_dp693_191.awsui_calendar-day-current-month_1ykar_dp693_198:not(#\9):hover {
  color: var(--color-text-calendar-day-hover-graajh, #000716);
  background-color: var(--color-background-dropdown-item-hover-mqsxkv, #f4f4f4);
}
.awsui_calendar-day-enabled_1ykar_dp693_191.awsui_calendar-day-current-month_1ykar_dp693_198:not(#\9):hover:not(.awsui_calendar-day-selected_1ykar_dp693_205)::after {
  border: var(--border-item-width-qbbbsa, 2px) solid var(--color-border-dropdown-item-hover-tyzq9m, #7d8998);
}
.awsui_calendar-day-today_1ykar_dp693_208:not(#\9) {
  background-color: var(--color-background-calendar-today-762czq, #f4f4f4);
  border-radius: var(--border-radius-item-u2ibpi, 8px);
  font-weight: 700;
}
.awsui_calendar-day_1ykar_dp693_174:not(#\9)::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: calc(-1 * var(--border-item-width-qbbbsa, 2px));
  left: -1px;
  bottom: -1px;
  right: calc(-1 * var(--border-item-width-qbbbsa, 2px));
  background-color: transparent;
}
.awsui_calendar-day_1ykar_dp693_174 > .awsui_day-inner_1ykar_dp693_223:not(#\9) {
  position: relative;
  z-index: 1;
}
.awsui_calendar-day_1ykar_dp693_174:not(#\9):focus {
  outline: none;
}
.awsui_calendar-day_1ykar_dp693_174:not(#\9):focus[data-awsui-focus-visible=true]:focus {
  position: relative;
}
.awsui_calendar-day_1ykar_dp693_174:not(#\9):focus[data-awsui-focus-visible=true]:focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-calendar-grid-focus-outline-gutter-v3dwvl, -5px) - 1px);
}
.awsui_calendar-day_1ykar_dp693_174:not(#\9):focus[data-awsui-focus-visible=true]:focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-calendar-grid-focus-outline-gutter-v3dwvl, -5px));
  top: calc(-1 * var(--space-calendar-grid-focus-outline-gutter-v3dwvl, -5px));
  width: calc(100% + 2 * var(--space-calendar-grid-focus-outline-gutter-v3dwvl, -5px));
  height: calc(100% + 2 * var(--space-calendar-grid-focus-outline-gutter-v3dwvl, -5px));
  border-radius: var(--border-radius-calendar-day-focus-ring-a8mlqd, 3px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_calendar-day_1ykar_dp693_174:not(#\9):focus[data-awsui-focus-visible=true]:focus::before {
  z-index: 2;
}
.awsui_calendar-day-selected_1ykar_dp693_205:not(#\9) {
  border-color: transparent;
  position: relative;
  z-index: 2;
  font-weight: 700;
}
.awsui_calendar-day-selected_1ykar_dp693_205:not(#\9):focus[data-awsui-focus-visible=true]:focus {
  position: relative;
}
.awsui_calendar-day-selected_1ykar_dp693_205:not(#\9):focus[data-awsui-focus-visible=true]:focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-calendar-grid-focus-outline-gutter-v3dwvl, -5px) - 1px);
}
.awsui_calendar-day-selected_1ykar_dp693_205:not(#\9):focus[data-awsui-focus-visible=true]:focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * var(--space-calendar-grid-focus-outline-gutter-v3dwvl, -5px));
  top: calc(-1 * var(--space-calendar-grid-focus-outline-gutter-v3dwvl, -5px));
  width: calc(100% + 2 * var(--space-calendar-grid-focus-outline-gutter-v3dwvl, -5px));
  height: calc(100% + 2 * var(--space-calendar-grid-focus-outline-gutter-v3dwvl, -5px));
  border-radius: var(--border-radius-calendar-day-focus-ring-a8mlqd, 3px);
  box-shadow: 0 0 0 2px var(--color-border-calendar-grid-selected-focus-ring-cxqa0s, #fbfbfb);
}
.awsui_calendar-day-selected_1ykar_dp693_205:not(#\9):focus[data-awsui-focus-visible=true]:focus::before {
  z-index: 2;
}
.awsui_calendar-day-selected_1ykar_dp693_205:not(#\9)::after {
  background-color: var(--color-background-control-checked-9admlu, #0972d3);
  border: var(--border-item-width-qbbbsa, 2px) solid var(--color-background-control-checked-9admlu, #0972d3);
  border-radius: var(--border-radius-item-u2ibpi, 8px);
}
.awsui_calendar-day-selected_1ykar_dp693_205 > .awsui_day-inner_1ykar_dp693_223:not(#\9) {
  z-index: 2;
  color: var(--color-background-control-default-4pa05r, #ffffff);
  position: relative;
}
.awsui_calendar-week_1ykar_dp693_288:not(#\9):first-child > .awsui_calendar-day_1ykar_dp693_174 {
  border-top: 1px solid var(--color-border-calendar-grid-sshunw, transparent);
}