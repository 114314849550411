/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_fvjdu_1lgkt_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-left: 0;
  margin: 0;
  list-style: none;
}

.awsui_button_fvjdu_1lgkt_113:not(#\9),
.awsui_dots_fvjdu_1lgkt_114:not(#\9) {
  min-width: var(--space-l-4vl6xu, 20px);
  border: var(--border-field-width-idlekx, 2px) solid transparent;
}

.awsui_button_fvjdu_1lgkt_113:not(#\9) {
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  background: transparent;
  line-height: inherit;
  padding: 0;
}
.awsui_button_fvjdu_1lgkt_113:not(#\9):focus {
  outline: none;
}
.awsui_button_fvjdu_1lgkt_113[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-item-focused-ap3b6s, #0972d3);
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 var(--border-control-focus-ring-shadow-spread-srzyiu, 0px) var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button-disabled_fvjdu_1lgkt_136:not(#\9) {
  cursor: default;
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
}

.awsui_arrow_fvjdu_1lgkt_141:not(#\9) {
  color: var(--color-text-interactive-default-eg5fsa, #414d5c);
}
.awsui_arrow_fvjdu_1lgkt_141:not(#\9):not(.awsui_button-disabled_fvjdu_1lgkt_136):hover {
  color: var(--color-text-interactive-hover-v3lasm, #000716);
}
.awsui_arrow_fvjdu_1lgkt_141.awsui_button-disabled_fvjdu_1lgkt_136:not(#\9) {
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
}

.awsui_page-number_fvjdu_1lgkt_151:not(#\9) {
  color: var(--color-text-pagination-page-number-default-z6gnaa, #414d5c);
}
.awsui_page-number_fvjdu_1lgkt_151:not(#\9):not(.awsui_button-disabled_fvjdu_1lgkt_136):hover {
  color: var(--color-text-interactive-hover-v3lasm, #000716);
}
.awsui_page-number_fvjdu_1lgkt_151.awsui_button-current_fvjdu_1lgkt_157:not(#\9) {
  font-weight: var(--font-wayfinding-link-active-weight-l3fid7, 800);
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
  color: var(--color-text-interactive-active-vol84d, #000716);
}
.awsui_page-number_fvjdu_1lgkt_151.awsui_button-current_fvjdu_1lgkt_157.awsui_button-disabled_fvjdu_1lgkt_136:not(#\9) {
  color: var(--color-text-pagination-page-number-active-disabled-sjs1t5, #9ba7b6);
}
.awsui_page-number_fvjdu_1lgkt_151.awsui_button-disabled_fvjdu_1lgkt_136:not(#\9) {
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
}

.awsui_dots_fvjdu_1lgkt_114:not(#\9) {
  color: var(--color-text-interactive-default-eg5fsa, #414d5c);
}

.awsui_page-item_fvjdu_1lgkt_174:not(#\9),
.awsui_dots_fvjdu_1lgkt_114:not(#\9) {
  margin: var(--space-scaled-xxs-95dhkm, 4px) var(--space-xxs-ynfts5, 4px);
  text-align: center;
  box-sizing: border-box;
  padding: 0;
}
.awsui_page-item_fvjdu_1lgkt_174:not(#\9):first-child,
.awsui_dots_fvjdu_1lgkt_114:not(#\9):first-child {
  margin-left: 0;
}
.awsui_page-item_fvjdu_1lgkt_174:not(#\9):last-child,
.awsui_dots_fvjdu_1lgkt_114:not(#\9):last-child {
  margin-right: 0;
}

.awsui_root-disabled_fvjdu_1lgkt_190 > .awsui_dots_fvjdu_1lgkt_114:not(#\9) {
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
}