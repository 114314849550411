/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_14iqq_l23lj_97:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  word-wrap: break-word;
  position: relative;
}
.awsui_root_14iqq_l23lj_97.awsui_fit-height_14iqq_l23lj_111:not(#\9) {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.awsui_root_14iqq_l23lj_97.awsui_variant-default_14iqq_l23lj_116:not(#\9), .awsui_root_14iqq_l23lj_97.awsui_variant-stacked_14iqq_l23lj_116:not(#\9) {
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  box-sizing: border-box;
  background-color: var(--color-background-container-content-iw8cer, #FFFFFF);
}
.awsui_root_14iqq_l23lj_97.awsui_variant-default_14iqq_l23lj_116:not(#\9)::before, .awsui_root_14iqq_l23lj_97.awsui_variant-stacked_14iqq_l23lj_116:not(#\9)::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  border-color: transparent;
  border-top: var(--border-container-top-width-jk6fhm, 0px) solid var(--color-border-container-top-srcvx4, transparent);
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  z-index: 1;
}
.awsui_root_14iqq_l23lj_97.awsui_variant-default_14iqq_l23lj_116:not(#\9)::after, .awsui_root_14iqq_l23lj_97.awsui_variant-stacked_14iqq_l23lj_116:not(#\9)::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  box-shadow: var(--shadow-container-fx563k, 0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12));
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  mix-blend-mode: multiply;
}
@media screen and (-ms-high-contrast: active) {
  .awsui_root_14iqq_l23lj_97.awsui_variant-default_14iqq_l23lj_116:not(#\9), .awsui_root_14iqq_l23lj_97.awsui_variant-stacked_14iqq_l23lj_116:not(#\9) {
    border: var(--border-field-width-idlekx, 2px) solid var(--color-border-container-top-srcvx4, transparent);
  }
}
.awsui_root_14iqq_l23lj_97.awsui_variant-stacked_14iqq_l23lj_116:not(#\9):not(:last-child), .awsui_root_14iqq_l23lj_97.awsui_variant-stacked_14iqq_l23lj_116:not(#\9):not(:last-child)::before, .awsui_root_14iqq_l23lj_97.awsui_variant-stacked_14iqq_l23lj_116:not(#\9):not(:last-child)::after {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.awsui_root_14iqq_l23lj_97.awsui_variant-stacked_14iqq_l23lj_116 + .awsui_root_14iqq_l23lj_97.awsui_variant-stacked_14iqq_l23lj_116:not(#\9), .awsui_root_14iqq_l23lj_97.awsui_variant-stacked_14iqq_l23lj_116 + .awsui_root_14iqq_l23lj_97.awsui_variant-stacked_14iqq_l23lj_116:not(#\9)::before, .awsui_root_14iqq_l23lj_97.awsui_variant-stacked_14iqq_l23lj_116 + .awsui_root_14iqq_l23lj_97.awsui_variant-stacked_14iqq_l23lj_116:not(#\9)::after {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.awsui_root_14iqq_l23lj_97.awsui_variant-stacked_14iqq_l23lj_116 + .awsui_root_14iqq_l23lj_97.awsui_variant-stacked_14iqq_l23lj_116:not(#\9)::before {
  border-top: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}
.awsui_root_14iqq_l23lj_97.awsui_variant-stacked_14iqq_l23lj_116 + .awsui_root_14iqq_l23lj_97.awsui_variant-stacked_14iqq_l23lj_116:not(#\9)::after {
  box-shadow: var(--shadow-container-stacked-jfo2nn, -1px 1px 1px 0px #e9ebed, 1px 1px 1px 0px #e9ebed, 0px 9px 8px -7px rgba(0, 7, 22, 0.12), 8px 0px 8px -7px rgba(0, 7, 22, 0.12), -8px 0px 8px -7px rgba(0, 7, 22, 0.12));
}
.awsui_root_14iqq_l23lj_97.awsui_sticky-enabled_14iqq_l23lj_169:not(#\9)::before {
  top: calc(-1 * var(--border-container-top-width-jk6fhm, 0px));
}
.awsui_root_14iqq_l23lj_97.awsui_sticky-enabled_14iqq_l23lj_169.awsui_variant-stacked_14iqq_l23lj_116:not(#\9)::before {
  top: calc(-1 * var(--border-divider-section-width-4wm2it, 2px));
}

.awsui_header_14iqq_l23lj_176:not(#\9) {
  background-color: var(--color-background-container-header-4flbq5, #ffffff);
  border-top-left-radius: var(--border-radius-container-gh9ysk, 16px);
  border-top-right-radius: var(--border-radius-container-gh9ysk, 16px);
}
.awsui_header-sticky-disabled_14iqq_l23lj_181:not(#\9) {
  position: relative;
  z-index: 1;
}
.awsui_header-sticky-enabled_14iqq_l23lj_185:not(#\9) {
  top: 0;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  position: sticky;
  z-index: 800;
}
.awsui_header-stuck_14iqq_l23lj_191:not(#\9) {
  border-radius: 0;
}
.awsui_header-stuck_14iqq_l23lj_191:not(#\9)::before {
  border: 0;
}
.awsui_header-stuck_14iqq_l23lj_191:not(#\9):not(.awsui_header-variant-cards_14iqq_l23lj_197) {
  box-shadow: var(--shadow-sticky-embedded-qit8ba, 0px 2px 0px 0px #e9ebed, 0px 16px 16px -12px rgba(0, 7, 22, 0.1));
}
.awsui_header-dynamic-height_14iqq_l23lj_200.awsui_header-stuck_14iqq_l23lj_191:not(#\9) {
  margin-bottom: calc(var(--font-heading-xl-line-height-doxfdj, 40px) - var(--font-heading-l-line-height-gev4n1, 30px));
}
.awsui_header_14iqq_l23lj_176:not(#\9):not(:empty) {
  border-bottom: var(--border-container-sticky-width-i7t7xe, 0px) solid var(--color-border-container-divider-4ade7z, transparent);
}
.awsui_header_14iqq_l23lj_176.awsui_with-paddings_14iqq_l23lj_206:not(#\9) {
  padding: var(--space-container-header-vertical-zsjhel, 12px) var(--space-container-horizontal-tlw03i, 20px);
}
.awsui_header_14iqq_l23lj_176.awsui_with-hidden-content_14iqq_l23lj_209:not(#\9) {
  border-bottom-left-radius: var(--border-radius-container-gh9ysk, 16px);
  border-bottom-right-radius: var(--border-radius-container-gh9ysk, 16px);
}
.awsui_header-variant-cards_14iqq_l23lj_197:not(#\9) {
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  box-sizing: border-box;
}
.awsui_header-variant-cards_14iqq_l23lj_197:not(#\9):not(.awsui_header-sticky-enabled_14iqq_l23lj_185) {
  position: relative;
}
.awsui_header-variant-cards_14iqq_l23lj_197:not(#\9)::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  border-color: transparent;
  border-top: var(--border-container-top-width-jk6fhm, 0px) solid var(--color-border-container-top-srcvx4, transparent);
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  z-index: 1;
}
.awsui_header-variant-cards_14iqq_l23lj_197:not(#\9)::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  box-shadow: var(--shadow-container-fx563k, 0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12));
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  mix-blend-mode: multiply;
}
@media screen and (-ms-high-contrast: active) {
  .awsui_header-variant-cards_14iqq_l23lj_197:not(#\9) {
    border: var(--border-field-width-idlekx, 2px) solid var(--color-border-container-top-srcvx4, transparent);
  }
}
.awsui_header-variant-cards_14iqq_l23lj_197.awsui_header-stuck_14iqq_l23lj_191:not(#\9)::after, .awsui_header-variant-cards_14iqq_l23lj_197.awsui_header-stuck_14iqq_l23lj_191:not(#\9)::before {
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.awsui_header-variant-full-page_14iqq_l23lj_259.awsui_header-stuck_14iqq_l23lj_191:not(#\9) {
  box-shadow: none;
}
.awsui_header-variant-full-page_14iqq_l23lj_259.awsui_header-stuck_14iqq_l23lj_191:not(#\9)::after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  box-shadow: var(--shadow-sticky-aijlo6, 0px 4px 20px 1px rgba(0, 7, 22, 0.1));
  clip-path: polygon(-999% 100%, 999% 100%, 999% 999%, -999% 999%);
}

/*
The dynamic height dark header needs a background that will cover
the default white background of the container component.
*/
.awsui_dark-header_14iqq_l23lj_277:not(#\9) {
  background-color: var(--color-background-layout-main-y57tka, #F8F9FA);
  color: var(--color-text-body-default-ajf1h5, #000716);
}

.awsui_fit-height_14iqq_l23lj_111 > .awsui_content_14iqq_l23lj_282:not(#\9) {
  flex: 1;
  overflow: auto;
}
.awsui_with-content-paddings_14iqq_l23lj_286 > .awsui_content_14iqq_l23lj_282:not(#\9) {
  padding: var(--space-scaled-l-t03y3z, 20px) var(--space-container-horizontal-tlw03i, 20px);
}
.awsui_with-content-paddings_14iqq_l23lj_286 > .awsui_header_14iqq_l23lj_176 + .awsui_content_14iqq_l23lj_282:not(#\9) {
  padding-top: var(--space-container-content-top-fsd8nr, 0px);
}

.awsui_fit-height_14iqq_l23lj_111.awsui_with-content-paddings_14iqq_l23lj_286:not(#\9):not(.awsui_with-footer_14iqq_l23lj_293) {
  padding-bottom: var(--space-scaled-l-t03y3z, 20px);
}
.awsui_fit-height_14iqq_l23lj_111.awsui_with-content-paddings_14iqq_l23lj_286:not(#\9):not(.awsui_with-footer_14iqq_l23lj_293) > .awsui_content_14iqq_l23lj_282 {
  padding-bottom: 0;
}

.awsui_footer_14iqq_l23lj_300.awsui_with-paddings_14iqq_l23lj_206:not(#\9) {
  padding: var(--space-scaled-s-cu1hzn, 12px) var(--space-container-horizontal-tlw03i, 20px);
}
.awsui_footer_14iqq_l23lj_300.awsui_with-divider_14iqq_l23lj_303:not(#\9) {
  border-top: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}