/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_content-enter_gwq0h_udygu_97:not(#\9) {
  animation: awsui_awsui-motion-fade-in_gwq0h_udygu_1 var(--motion-duration-show-paced-7wspqf, 180ms) var(--motion-easing-show-paced-wj4ybn, ease-out);
}
@keyframes awsui_awsui-motion-fade-in_gwq0h_udygu_1 {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_content-enter_gwq0h_udygu_97:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_content-enter_gwq0h_udygu_97:not(#\9), .awsui-mode-entering .awsui_content-enter_gwq0h_udygu_97:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_trigger-expanded_gwq0h_udygu_119:not(#\9) {
  transition: border-bottom-color var(--motion-duration-show-paced-7wspqf, 180ms) var(--motion-easing-show-paced-wj4ybn, ease-out);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_trigger-expanded_gwq0h_udygu_119:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_trigger-expanded_gwq0h_udygu_119:not(#\9), .awsui-mode-entering .awsui_trigger-expanded_gwq0h_udygu_119:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_icon_gwq0h_udygu_133:not(#\9) {
  transition: transform var(--motion-duration-rotate-90-ux18sr, 135ms) var(--motion-easing-rotate-90-eoew89, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_icon_gwq0h_udygu_133:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_icon_gwq0h_udygu_133:not(#\9), .awsui-mode-entering .awsui_icon_gwq0h_udygu_133:not(#\9) {
  animation: none;
  transition: none;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_gwq0h_udygu_151:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  min-width: 0;
  -ms-word-break: break-all;
  word-break: break-word;
  display: block;
}

.awsui_icon_gwq0h_udygu_133:not(#\9) {
  transform: rotate(-90deg);
}
.awsui_icon_gwq0h_udygu_133.awsui_expanded_gwq0h_udygu_171:not(#\9) {
  transform: rotate(0deg);
}

.awsui_icon-container_gwq0h_udygu_175:not(#\9) {
  position: relative;
  margin-left: calc((var(--font-body-m-line-height-i7xxvv, 22px) - var(--size-icon-normal-j1z5fn, 16px)) / -2);
  margin-right: calc(var(--space-xxs-ynfts5, 4px) + var(--border-divider-list-width-hacikr, 1px));
}

.awsui_trigger_gwq0h_udygu_119:not(#\9) {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  border: none;
  width: 100%;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  text-align: left;
}
.awsui_trigger-default_gwq0h_udygu_190:not(#\9) {
  padding: var(--space-scaled-xxs-95dhkm, 4px) var(--space-xxs-ynfts5, 4px);
}
.awsui_trigger-footer_gwq0h_udygu_193:not(#\9) {
  padding: var(--space-scaled-xxs-95dhkm, 4px) 0;
}
.awsui_trigger-default_gwq0h_udygu_190:not(#\9), .awsui_trigger-footer_gwq0h_udygu_193:not(#\9) {
  border: var(--border-divider-section-width-4wm2it, 2px) solid transparent;
}
.awsui_trigger-navigation_gwq0h_udygu_199:not(#\9) {
  border-left: var(--border-divider-section-width-4wm2it, 2px) solid transparent;
}
.awsui_trigger-default_gwq0h_udygu_190:not(#\9), .awsui_trigger-navigation_gwq0h_udygu_199:not(#\9), .awsui_trigger-footer_gwq0h_udygu_193:not(#\9) {
  color: var(--color-text-expandable-section-default-k03p1p, #000716);
  font-weight: var(--font-heading-s-weight-k8ys41, 800);
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
  font-size: var(--font-expandable-heading-size-m2wptt, 16px);
  letter-spacing: var(--font-heading-s-letter-spacing-4d69jc, -0.005em);
}
.awsui_trigger-default_gwq0h_udygu_190:not(#\9):hover, .awsui_trigger-navigation_gwq0h_udygu_199:not(#\9):hover, .awsui_trigger-footer_gwq0h_udygu_193:not(#\9):hover {
  color: var(--color-text-expandable-section-hover-oo7tqa, #0972D3);
}
.awsui_trigger-container_gwq0h_udygu_213:not(#\9) {
  padding: var(--space-container-header-vertical-zsjhel, 12px) var(--space-container-horizontal-tlw03i, 20px);
}
.awsui_trigger-container_gwq0h_udygu_213[data-awsui-focus-visible=true]:not(#\9):focus {
  padding: calc(var(--space-scaled-s-cu1hzn, 12px) - var(--border-divider-section-width-4wm2it, 2px)) calc(var(--space-l-4vl6xu, 20px) - var(--border-divider-section-width-4wm2it, 2px));
}
.awsui_trigger-default_gwq0h_udygu_190.awsui_trigger-expanded_gwq0h_udygu_119:not(#\9) {
  border-bottom-color: var(--color-border-divider-default-7s2wjw, #e9ebed);
}

.awsui_header_gwq0h_udygu_223:not(#\9) {
  display: flex;
}
.awsui_header-container_gwq0h_udygu_226:not(#\9) {
  width: 100%;
}
.awsui_header-container_gwq0h_udygu_226 > .awsui_icon-container_gwq0h_udygu_175:not(#\9) {
  margin-top: var(--space-expandable-section-icon-offset-top-svbirl, 4px);
  margin-right: var(--space-xs-rsr2qu, 8px);
}
.awsui_header-container_gwq0h_udygu_226[data-awsui-focus-visible=true]:not(#\9):focus-within {
  outline: none;
  text-decoration: none;
  padding: calc(var(--space-scaled-s-cu1hzn, 12px) - var(--border-divider-section-width-4wm2it, 2px)) calc(var(--space-l-4vl6xu, 20px) - var(--border-divider-section-width-4wm2it, 2px));
  outline: 2px dotted transparent;
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-item-focused-ap3b6s, #0972d3);
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 var(--border-control-focus-ring-shadow-spread-srzyiu, 0px) var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_header-container-button_gwq0h_udygu_242:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_header-container-button_gwq0h_udygu_242 > .awsui_icon-container_gwq0h_udygu_175:not(#\9) {
  margin-top: var(--space-expandable-section-icon-offset-top-svbirl, 4px);
  margin-right: var(--space-xs-rsr2qu, 8px);
}
.awsui_header-navigation_gwq0h_udygu_250 > .awsui_icon-container_gwq0h_udygu_175:not(#\9) {
  display: inline-flex;
  cursor: pointer;
  color: var(--color-text-expandable-section-navigation-icon-default-lb9rm0, #414d5c);
  border: 0;
  padding: 0;
  background: transparent;
  outline: none;
  text-decoration: none;
  flex-direction: column;
}
.awsui_header-navigation_gwq0h_udygu_250 > .awsui_icon-container_gwq0h_udygu_175:not(#\9):hover {
  color: var(--color-text-expandable-section-hover-oo7tqa, #0972D3);
}
.awsui_header-navigation_gwq0h_udygu_250 > .awsui_icon-container_gwq0h_udygu_175[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_header-navigation_gwq0h_udygu_250 > .awsui_icon-container_gwq0h_udygu_175[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(2px - 1px);
}
.awsui_header-navigation_gwq0h_udygu_250 > .awsui_icon-container_gwq0h_udygu_175[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 2px);
  top: calc(-1 * 2px);
  width: calc(100% + 2 * 2px);
  height: calc(100% + 2 * 2px);
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}

.awsui_content_gwq0h_udygu_97:not(#\9) {
  display: none;
}
.awsui_content-default_gwq0h_udygu_286:not(#\9) {
  padding: var(--space-scaled-xs-6859qs, 8px) 0;
}
.awsui_content-footer_gwq0h_udygu_289:not(#\9) {
  padding: var(--space-xs-rsr2qu, 8px) 0;
}
.awsui_content-expanded_gwq0h_udygu_292:not(#\9) {
  display: block;
}

.awsui_focusable_gwq0h_udygu_296:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_focusable_gwq0h_udygu_296[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-item-focused-ap3b6s, #0972d3);
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 var(--border-control-focus-ring-shadow-spread-srzyiu, 0px) var(--color-border-item-focused-ap3b6s, #0972d3);
}