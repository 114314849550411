/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_icon-shake_1cbgc_1gm2w_97:not(#\9) {
  display: inline-block;
  animation: awsui_awsui-motion-shake-horizontally_1cbgc_1gm2w_1 var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@keyframes awsui_awsui-motion-shake-horizontally_1cbgc_1gm2w_1 {
  0% {
    transform: translateX(-5px);
    animation-timing-function: linear;
  }
  50% {
    transform: translateX(5px);
    animation-timing-function: var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
  }
  100% {
    transform: translateX(0px);
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_icon-shake_1cbgc_1gm2w_97:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_icon-shake_1cbgc_1gm2w_97:not(#\9), .awsui-mode-entering .awsui_icon-shake_1cbgc_1gm2w_97:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_container-fade-in_1cbgc_1gm2w_125:not(#\9) {
  animation: awsui_awsui-motion-fade-in-0_1cbgc_1gm2w_1 var(--motion-duration-refresh-only-medium-5flen2, 165ms) var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
}
@keyframes awsui_awsui-motion-fade-in-0_1cbgc_1gm2w_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_container-fade-in_1cbgc_1gm2w_125:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_container-fade-in_1cbgc_1gm2w_125:not(#\9), .awsui-mode-entering .awsui_container-fade-in_1cbgc_1gm2w_125:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_root_1cbgc_1gm2w_147:not(#\9) {
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}
.awsui_root_1cbgc_1gm2w_147.awsui_status-error_1cbgc_1gm2w_156:not(#\9) {
  color: var(--color-text-status-error-5sesl6, #d91515);
}
.awsui_root_1cbgc_1gm2w_147.awsui_status-warning_1cbgc_1gm2w_159:not(#\9) {
  color: var(--color-text-status-error-5sesl6, #d91515);
}
.awsui_root_1cbgc_1gm2w_147.awsui_status-success_1cbgc_1gm2w_162:not(#\9) {
  color: var(--color-text-status-success-0fzhv9, #037f0c);
}
.awsui_root_1cbgc_1gm2w_147.awsui_status-info_1cbgc_1gm2w_165:not(#\9) {
  color: var(--color-text-status-info-utupcz, #0972d3);
}
.awsui_root_1cbgc_1gm2w_147.awsui_status-stopped_1cbgc_1gm2w_168:not(#\9) {
  color: var(--color-text-status-inactive-5ei55p, #5f6b7a);
}
.awsui_root_1cbgc_1gm2w_147.awsui_status-pending_1cbgc_1gm2w_171:not(#\9) {
  color: var(--color-text-status-inactive-5ei55p, #5f6b7a);
}
.awsui_root_1cbgc_1gm2w_147.awsui_status-in-progress_1cbgc_1gm2w_174:not(#\9) {
  color: var(--color-text-status-inactive-5ei55p, #5f6b7a);
}
.awsui_root_1cbgc_1gm2w_147.awsui_status-loading_1cbgc_1gm2w_177:not(#\9) {
  color: var(--color-text-status-inactive-5ei55p, #5f6b7a);
}
.awsui_root_1cbgc_1gm2w_147.awsui_color-override-red_1cbgc_1gm2w_180:not(#\9) {
  color: var(--color-text-status-error-5sesl6, #d91515);
}
.awsui_root_1cbgc_1gm2w_147.awsui_color-override-grey_1cbgc_1gm2w_183:not(#\9) {
  color: var(--color-text-status-inactive-5ei55p, #5f6b7a);
}
.awsui_root_1cbgc_1gm2w_147.awsui_color-override-blue_1cbgc_1gm2w_186:not(#\9) {
  color: var(--color-text-status-info-utupcz, #0972d3);
}
.awsui_root_1cbgc_1gm2w_147.awsui_color-override-green_1cbgc_1gm2w_189:not(#\9) {
  color: var(--color-text-status-success-0fzhv9, #037f0c);
}

.awsui_icon_1cbgc_1gm2w_97:not(#\9) {
  padding-right: var(--space-xxs-ynfts5, 4px);
}

.awsui_container_1cbgc_1gm2w_125:not(#\9) {
  display: inline-block;
  word-break: break-all;
  word-wrap: break-word;
}

.awsui_overflow-ellipsis_1cbgc_1gm2w_203:not(#\9) {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: text-bottom;
}