/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_lm6vo_12tk9_97:not(#\9),
.awsui_layout-wrapper_lm6vo_12tk9_98:not(#\9) {
  display: flex;
  flex-direction: column;
  position: relative;
  color: var(--color-text-body-default-ajf1h5, #000716);
}

.awsui_root-no-scroll_lm6vo_12tk9_105:not(#\9) {
  position: relative;
  z-index: 1;
}

.awsui_layout_lm6vo_12tk9_98:not(#\9) {
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  background-color: var(--color-background-layout-main-y57tka, #F8F9FA);
}
.awsui_layout-no-scroll_lm6vo_12tk9_116:not(#\9) {
  overflow: hidden;
}

.awsui_layout-main_lm6vo_12tk9_120:not(#\9) {
  flex: 1;
  min-width: 0;
  background-color: var(--color-background-layout-main-y57tka, #F8F9FA);
  position: relative;
}
.awsui_layout-main-scrollable_lm6vo_12tk9_126:not(#\9) {
  overflow: auto;
}

/* stylelint-disable-next-line selector-combinator-disallowed-list, selector-max-universal */
.awsui_unfocusable_lm6vo_12tk9_131 *:not(#\9) {
  visibility: hidden;
}

.awsui_navigation-panel_lm6vo_12tk9_135:not(#\9) {
  overflow-anchor: none;
}

.awsui_drawer_lm6vo_12tk9_139:not(#\9) {
  flex-shrink: 0;
  position: relative;
  word-wrap: break-word;
  box-shadow: var(--shadow-panel-gp3bf2, 0px 1px 1px 1px #e9ebed, 0px 6px 36px rgba(0, 7, 22, 0.1));
}
.awsui_drawer_lm6vo_12tk9_139:not(#\9):not(.awsui_drawer-mobile_lm6vo_12tk9_145) {
  z-index: 830;
}
.awsui_drawer-closed_lm6vo_12tk9_148:not(#\9) {
  min-width: 40px;
  cursor: pointer;
}
.awsui_drawer-closed_lm6vo_12tk9_148.awsui_drawer-mobile_lm6vo_12tk9_145:not(#\9) {
  display: none;
}

.awsui_drawer-content_lm6vo_12tk9_156:not(#\9) {
  position: fixed;
  overflow: auto;
  background-color: var(--color-background-layout-panel-content-libpfx, #FFFFFF);
}
.awsui_drawer-mobile_lm6vo_12tk9_145 > .awsui_drawer-content_lm6vo_12tk9_156:not(#\9) {
  z-index: 1001;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.awsui_drawer-closed_lm6vo_12tk9_148 > .awsui_drawer-content_lm6vo_12tk9_156:not(#\9) {
  width: 40px;
}
.awsui_drawer-closed_lm6vo_12tk9_148 > .awsui_drawer-content_lm6vo_12tk9_156:not(#\9):hover {
  background: var(--color-background-layout-panel-hover-0bg3gq, #e9ebed);
}
.awsui_drawer-content_lm6vo_12tk9_156 > [aria-hidden=true]:not(#\9) {
  display: none;
}

.awsui_toggle_lm6vo_12tk9_178:not(#\9) {
  box-sizing: border-box;
  padding: var(--space-xxs-ynfts5, 4px) 7px;
}

.awsui_visual-refresh-toggle_lm6vo_12tk9_183:not(#\9) {
  position: fixed;
  padding: var(--space-scaled-s-cu1hzn, 12px) var(--space-layout-toggle-padding-axl9jr, 20px);
}
.awsui_visual-refresh-toggle-type-tools_lm6vo_12tk9_187:not(#\9) {
  right: 0;
}
.awsui_visual-refresh-toggle-type-navigation_lm6vo_12tk9_190:not(#\9) {
  left: 0;
}
.awsui_visual-refresh-toggle_lm6vo_12tk9_183[aria-hidden=true]:not(#\9) {
  visibility: hidden;
}
.awsui_visual-refresh-toggle_lm6vo_12tk9_183[aria-hidden=false]:not(#\9) {
  visibility: visible;
}

.awsui_breadcrumbs-desktop_lm6vo_12tk9_200:not(#\9) {
  padding-top: var(--space-scaled-m-pv0fmt, 16px);
  padding-bottom: var(--space-scaled-s-cu1hzn, 12px);
}

.awsui_content-header-wrapper_lm6vo_12tk9_205:not(#\9) {
  padding-bottom: var(--space-content-header-padding-bottom-nvqytg, 24px);
}

.awsui_content-wrapper_lm6vo_12tk9_209:not(#\9) {
  padding-bottom: var(--space-layout-content-bottom-kg9l42, 40px);
}

.awsui_content-overlapped_lm6vo_12tk9_213:not(#\9) {
  margin-top: calc(-1 * var(--space-dark-header-overlap-distance-hfejul, 36px));
}

.awsui_content-extra-top-padding_lm6vo_12tk9_217:not(#\9) {
  padding-top: var(--space-scaled-m-pv0fmt, 16px);
}

.awsui_panel-wrapper-outer_lm6vo_12tk9_221:not(#\9) {
  position: fixed;
  height: 100%;
  z-index: 830;
  display: flex;
}
.awsui_panel-wrapper-outer_lm6vo_12tk9_221.awsui_mobile_lm6vo_12tk9_227:not(#\9) {
  left: 0;
  right: 0;
  z-index: -1;
  pointer-events: none;
}
.awsui_panel-wrapper-outer_lm6vo_12tk9_221.awsui_mobile_lm6vo_12tk9_227.awsui_open_lm6vo_12tk9_233:not(#\9) {
  z-index: 1001;
  width: 100vw;
  pointer-events: auto;
}

.awsui_panel-wrapper-inner_lm6vo_12tk9_239:not(#\9) {
  background: var(--color-background-layout-panel-content-libpfx, #FFFFFF);
}