/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_header_16mm3_1ve9d_93:not(#\9) {
  position: relative;
  margin: 0;
  color: var(--color-text-dropdown-group-label-eaqlcl, #414d5c);
  border: var(--border-divider-list-width-hacikr, 1px) solid transparent;
  border-left: 0;
  border-right: 0;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding: calc(
  var(--space-xxs-ynfts5, 4px) + (var(--border-item-width-qbbbsa, 2px) - var(--border-divider-list-width-hacikr, 1px))
) calc(var(--space-l-4vl6xu, 20px) + var(--border-item-width-qbbbsa, 2px));
  z-index: 1;
}
.awsui_header_16mm3_1ve9d_93.awsui_disabled_16mm3_1ve9d_108:not(#\9) {
  color: var(--color-text-dropdown-item-disabled-varol7, #9ba7b6);
  cursor: default;
}
.awsui_header_16mm3_1ve9d_93.awsui_expandable-header_16mm3_1ve9d_112:not(#\9) {
  border-top-color: var(--color-border-dropdown-group-0utpsr, #e9ebed);
  border-bottom-color: var(--color-border-dropdown-group-0utpsr, #e9ebed);
  cursor: pointer;
}
.awsui_header_16mm3_1ve9d_93.awsui_expandable-header_16mm3_1ve9d_112.awsui_disabled_16mm3_1ve9d_108:not(#\9) {
  cursor: default;
}
.awsui_header_16mm3_1ve9d_93.awsui_expandable-header_16mm3_1ve9d_112:not(#\9):focus {
  outline: none;
}
.awsui_header_16mm3_1ve9d_93.awsui_expandable-header_16mm3_1ve9d_112.awsui_rolled-down_16mm3_1ve9d_123:not(#\9) {
  border-bottom-color: transparent;
}
.awsui_header_16mm3_1ve9d_93.awsui_expandable-header_16mm3_1ve9d_112.awsui_highlighted_16mm3_1ve9d_126:not(#\9) {
  background-color: var(--color-background-dropdown-item-hover-mqsxkv, #f4f4f4);
  color: var(--color-text-dropdown-item-highlighted-oaabyk, #000716);
  padding: var(--space-xxs-ynfts5, 4px) var(--space-l-4vl6xu, 20px);
  border: var(--border-item-width-qbbbsa, 2px) solid var(--color-border-dropdown-item-hover-tyzq9m, #7d8998);
  border-radius: var(--border-radius-item-u2ibpi, 8px);
  z-index: 2;
}
.awsui_header_16mm3_1ve9d_93.awsui_expandable-header_16mm3_1ve9d_112.awsui_highlighted_16mm3_1ve9d_126.awsui_disabled_16mm3_1ve9d_108:not(#\9) {
  background-color: var(--color-background-dropdown-item-dimmed-qw48ma, transparent);
  border-color: var(--color-border-dropdown-item-dimmed-hover-6dhy15, #7d8998);
  color: var(--color-text-dropdown-item-dimmed-jyqdrs, #9ba7b6);
}
.awsui_header_16mm3_1ve9d_93.awsui_expandable-header_16mm3_1ve9d_112.awsui_highlighted_16mm3_1ve9d_126.awsui_is-focused_16mm3_1ve9d_139:not(#\9) {
  border-color: var(--color-border-item-focused-ap3b6s, #0972d3);
  box-shadow: inset 0 0 0 var(--border-control-focus-ring-shadow-spread-srzyiu, 0px) var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_header_16mm3_1ve9d_93.awsui_expandable-header_16mm3_1ve9d_112.awsui_variant-navigation_16mm3_1ve9d_143:not(#\9) {
  padding-top: var(--space-xs-rsr2qu, 8px);
  padding-bottom: var(--space-xs-rsr2qu, 8px);
}
.awsui_header_16mm3_1ve9d_93.awsui_expandable-header_16mm3_1ve9d_112.awsui_variant-navigation_16mm3_1ve9d_143.awsui_highlighted_16mm3_1ve9d_126:not(#\9) {
  border-color: transparent;
  border-top-color: var(--color-border-dropdown-group-0utpsr, #e9ebed);
  border-bottom-color: var(--color-border-dropdown-group-0utpsr, #e9ebed);
  background-color: transparent;
  color: var(--color-text-accent-6sf3a3, #0972D3);
}

.awsui_category_16mm3_1ve9d_155:not(#\9) {
  list-style: none;
  margin-top: calc(-1 * var(--border-divider-list-width-hacikr, 1px));
  padding: 0;
}
.awsui_category_16mm3_1ve9d_155:not(#\9):first-child {
  margin-top: 0;
}
.awsui_category_16mm3_1ve9d_155.awsui_expandable_16mm3_1ve9d_112:not(#\9) {
  border-top: 0;
}
.awsui_category_16mm3_1ve9d_155:not(#\9):last-child {
  border-bottom: none;
}
.awsui_category_16mm3_1ve9d_155.awsui_variant-navigation_16mm3_1ve9d_143:not(#\9) {
  padding-top: var(--space-xxs-ynfts5, 4px);
}
.awsui_category_16mm3_1ve9d_155.awsui_variant-navigation_16mm3_1ve9d_143.awsui_expandable_16mm3_1ve9d_112:not(#\9) {
  padding-top: 0;
}

.awsui_expand-icon_16mm3_1ve9d_176:not(#\9) {
  position: relative;
  left: var(--space-s-hv8c1d, 12px);
  width: var(--space-m-17eucw, 16px);
  display: inline-block;
  transition: transform var(--motion-duration-rotate-180-ofa1ir, 135ms) var(--motion-easing-rotate-180-3rbbga, cubic-bezier(0.165, 0.84, 0.44, 1));
}
.awsui_expand-icon-up_16mm3_1ve9d_183:not(#\9) {
  transform: rotate(-180deg);
}
.awsui_expand-icon-right_16mm3_1ve9d_186:not(#\9) {
  transform: rotate(-90deg);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_expand-icon_16mm3_1ve9d_176:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_expand-icon_16mm3_1ve9d_176:not(#\9), .awsui-mode-entering .awsui_expand-icon_16mm3_1ve9d_176:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_items-list-container_16mm3_1ve9d_200:not(#\9) {
  padding: 0;
  margin: -1px 0 0 0;
  overflow-y: auto;
}