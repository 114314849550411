/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_breadcrumb-group_d19fg_1mrtd_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  margin: 0;
  padding: var(--space-xxs-ynfts5, 4px) 0;
}
.awsui_breadcrumb-group_d19fg_1mrtd_93 > .awsui_item_d19fg_1mrtd_107:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: inline;
}
.awsui_breadcrumb-group_d19fg_1mrtd_93 > .awsui_breadcrumb-group-list_d19fg_1mrtd_120:not(#\9) {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
  flex-wrap: wrap;
}
.awsui_breadcrumb-group_d19fg_1mrtd_93 > .awsui_breadcrumb-group-list_d19fg_1mrtd_120 > .awsui_item_d19fg_1mrtd_107:not(#\9),
.awsui_breadcrumb-group_d19fg_1mrtd_93 > .awsui_breadcrumb-group-list_d19fg_1mrtd_120 > .awsui_ellipsis_d19fg_1mrtd_130:not(#\9) {
  display: inline-block;
  padding: 0;
  margin: 0;
}
.awsui_breadcrumb-group_d19fg_1mrtd_93 > .awsui_breadcrumb-group-list_d19fg_1mrtd_120 > .awsui_ellipsis_d19fg_1mrtd_130:not(#\9) {
  display: none;
}
.awsui_breadcrumb-group_d19fg_1mrtd_93 > .awsui_breadcrumb-group-list_d19fg_1mrtd_120 > .awsui_ellipsis_d19fg_1mrtd_130 > .awsui_icon_d19fg_1mrtd_138:not(#\9) {
  margin: 0 10px;
  color: var(--color-text-breadcrumb-icon-mlugp6, #7d8998);
}
.awsui_breadcrumb-group_d19fg_1mrtd_93.awsui_mobile_d19fg_1mrtd_142 > .awsui_breadcrumb-group-list_d19fg_1mrtd_120:not(#\9) {
  flex-wrap: nowrap;
}
.awsui_breadcrumb-group_d19fg_1mrtd_93.awsui_mobile_d19fg_1mrtd_142 > .awsui_breadcrumb-group-list_d19fg_1mrtd_120 > .awsui_ellipsis_d19fg_1mrtd_130:not(#\9) {
  display: flex;
  flex-shrink: 0;
}
.awsui_breadcrumb-group_d19fg_1mrtd_93.awsui_mobile_d19fg_1mrtd_142 > .awsui_breadcrumb-group-list_d19fg_1mrtd_120 > .awsui_item_d19fg_1mrtd_107:not(#\9) {
  min-width: 0;
}
.awsui_breadcrumb-group_d19fg_1mrtd_93.awsui_mobile_d19fg_1mrtd_142 > .awsui_breadcrumb-group-list_d19fg_1mrtd_120 > .awsui_item_d19fg_1mrtd_107:not(#\9):not(:first-child):not(:last-child) {
  display: none;
}