/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_empty-appear_n4qlp_1fj3f_93:not(#\9) {
  animation: awsui_awsui-motion-fade-in_n4qlp_1fj3f_1 var(--motion-duration-transition-show-paced-ai4wlf, 180ms) var(--motion-easing-transition-show-paced-bg1hyg, ease-out);
}
@keyframes awsui_awsui-motion-fade-in_n4qlp_1fj3f_1 {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_empty-appear_n4qlp_1fj3f_93:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_empty-appear_n4qlp_1fj3f_93:not(#\9), .awsui-mode-entering .awsui_empty-appear_n4qlp_1fj3f_93:not(#\9) {
  animation: none;
  transition: none;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_n4qlp_1fj3f_119:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-kl18mf, Open Sans);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: block;
}

.awsui_empty_n4qlp_1fj3f_93:not(#\9) {
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-empty-fjv325, #5f6b7a);
}

.awsui_row_n4qlp_1fj3f_139:not(#\9) {
  /* used in test-utils */
}

.awsui_row-control_n4qlp_1fj3f_143:not(#\9) {
  /* used in test-utils */
}

.awsui_field_n4qlp_1fj3f_147:not(#\9) {
  /* used in test-utils */
}

.awsui_add-button_n4qlp_1fj3f_151:not(#\9) {
  /* used in test-utils */
}

.awsui_remove-button_n4qlp_1fj3f_155:not(#\9) {
  /* used in test-utils */
}

.awsui_button-container-haslabel_n4qlp_1fj3f_159:not(#\9) {
  padding-top: calc(var(--space-xxs-ynfts5, 4px) + var(--font-body-m-line-height-i7xxvv, 22px));
}

.awsui_button-container-nolabel_n4qlp_1fj3f_163:not(#\9) {
  padding-top: var(--space-xxs-ynfts5, 4px);
}

.awsui_divider_n4qlp_1fj3f_167:not(#\9) {
  border-bottom: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}

.awsui_additional-info_n4qlp_1fj3f_171:not(#\9) {
  color: var(--color-text-form-secondary-ih9x7l, #5f6b7a);
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
  display: block;
  word-wrap: break-word;
  margin-top: var(--space-xxs-ynfts5, 4px);
  /* stylelint-disable-next-line selector-max-type */
}
.awsui_additional-info_n4qlp_1fj3f_171 > a:not(#\9) {
  color: var(--color-text-link-default-dkndw9, #0972D3);
  font-weight: var(--font-link-primary-weight-91pybi, "inherit");
  letter-spacing: var(--font-link-primary-letter-spacing-vmsgcx, "inherit");
  -webkit-text-decoration: var(--font-link-primary-decoration-wfgjh2, underline);
          text-decoration: var(--font-link-primary-decoration-wfgjh2, underline);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: var(--color-text-link-primary-underline-3sp2rc, #0972D3);
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_additional-info_n4qlp_1fj3f_171 > a:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_additional-info_n4qlp_1fj3f_171 > a:not(#\9), .awsui-mode-entering .awsui_additional-info_n4qlp_1fj3f_171 > a:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_additional-info_n4qlp_1fj3f_171 > a:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-arwnci, #033160);
}
.awsui_additional-info_n4qlp_1fj3f_171 > a:not(#\9):focus {
  outline: none;
}
.awsui_additional-info_n4qlp_1fj3f_171 > a:not(#\9):active {
  color: var(--color-text-link-hover-arwnci, #033160);
}
.awsui_additional-info_n4qlp_1fj3f_171 > a:not(#\9):active, .awsui_additional-info_n4qlp_1fj3f_171 > a:not(#\9):focus, .awsui_additional-info_n4qlp_1fj3f_171 > a:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  text-decoration-color: currentColor;
}

.awsui_right-align_n4qlp_1fj3f_217:not(#\9) {
  float: right;
}